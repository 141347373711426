import React from 'react';
import dayjs from 'dayjs';

const HoursDifferenceCalculator: React.FC<{ fromTime: dayjs.Dayjs | string | null, toTime: dayjs.Dayjs | string | null }> = ({ fromTime, toTime }) => {
  if (!fromTime || !toTime) {
    return <span>0h 0m</span>; // Return 0 hours and 0 minutes if times are not available
  }
  try { // TODO: look into better way than this.
    // Calculate the difference in milliseconds
    const diffInMillis = dayjs(toTime).diff(dayjs(fromTime)); // Difference in milliseconds
    const diffInMinutes = Math.floor(diffInMillis / 60000); // Convert to minutes

    const hours = Math.floor(diffInMinutes / 60); // Get hours
    const minutes = diffInMinutes % 60; // Get remaining minutes

    return <span>{`${hours}h ${minutes}m`}</span>; // Display hours and minutes
  }
  catch {
    return <span>0h 0m</span>;
  }
};

export default HoursDifferenceCalculator;