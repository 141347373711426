


import React, { useEffect } from "react";
import { Api } from "../../api";
import getInstanceID from "../../functions/getInstanceID";
import CustomBarChart from "./CustomBarChart";

const ShiftStatusWeekByWeekWidget: React.FC = () => {
  let instanceID = getInstanceID();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [data, setData] = React.useState<any[]>([]);
  const [labels, setLabels] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState<string>('0');
  const fetchData = async () => {
    setData([]);
    setLabels([]);
    setTotal("0");
    setLoading(true);
    setErrorMessage(null);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${instanceID}/~GetAllPastSevenWeekShiftStatuses`);

      const allLabelsAsTimerange: string[] = data.map((x: { TimeRange: any; }) => x.TimeRange);
      //TODO: FIX THE DATA FROM SERVER SO IT ACTUALY MAKES SENSE CAUSE THIS IS REALLY BAD THAT I HAVE TO DO THIS!!!!
      // Ensure unique labels and include color if available
      const allStatusTypeLabels = [
        ...data
          .flatMap((item: { Statuses: any[] }) =>
            item.Statuses.map((status: { label: string; colour?: string }) => ({
              label: status.label,
              colour: status.colour || null, // Include color or default to null
            }))
          )
          .reduce((map: { has: (arg0: any) => any; set: (arg0: any, arg1: any) => void; }, obj: { label: any; }) => {
            if (!map.has(obj.label)) {
              map.set(obj.label, obj); // Use a map to ensure unique labels
            }
            return map;
          }, new Map())
          .values(),
      ];
      console.log(allStatusTypeLabels);
      // Transform raw data into the desired format
      const transformedData = allStatusTypeLabels.map((labelItem, index) => {
        const baseData: any = {
          id: labelItem.label.toLowerCase().replace(/\s+/g, '-') + index, // Generate a valid ID
          data: data.map((item: { Statuses: any[] }) =>
            item.Statuses.find((status: { label: string }) => status.label === labelItem.label)?.value || 0 // Map status values or default to 0
          ),
          label: labelItem.label,
          stack: 'A', // Static stack value
        };

        // Add color property conditionally
        if (labelItem.colour) {
          baseData.color = labelItem.colour;
        }

        return baseData;
      });

      console.log(transformedData);
      // Calculate the total by summing all nested numbers
      const total = transformedData.reduce(
        (sum, current) => {
          // Sum the current object's data array
          const innerSum = current.data.reduce(
            (innerSum: any, value: any) => innerSum + value, // Add each number in the data array
            0 // Start the summation at 0
          );
          return sum + innerSum; // Add the inner sum to the total sum
        },
        0 // Start the total sum at 0
      );
      setTotal(total.toString());
      setLabels(allLabelsAsTimerange);
      setData(transformedData);
    } catch (e) {
      setErrorMessage("Failed to fetch data");
      setTotal("0");
      setData([]);
      setLabels([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (


    <CustomBarChart
      loading={loading}
      title="Shift Statuses Week On Week"
      description="Shifts done week on week for the past 7 weeks"
      totalField={total + " Shifts"}
      data={data}
      errorMessage={errorMessage}
      xAxis={[
        {
          scaleType: 'band',
          id: 'dates',
          data: labels
        }]
      }
    />
  );
};
export default ShiftStatusWeekByWeekWidget;