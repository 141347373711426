import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs
} from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import { Api, handleErrorResponse } from "../api";
import getInstanceID from "../functions/getInstanceID";
import { AlertService } from "../services/AlertService";
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";

const Bits: React.FunctionComponent = () => {
  const plodID = getInstanceID();
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState<string>("BitSizes");
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});
  const [manufacturerSelectorID, setManufacturerSelectorID] = React.useState(null);
  const [manufacturerOptions, setManufacturerOptions] = React.useState([]);
  const [bitTypeOptions, setBitTypeOptions] = React.useState([]);
  const [bitSizeOptions, setBitSizeOptions] = React.useState([]);

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows,
    dataRowModesModel,
    setDataRowModesModel,
    setDataRows,
  });

  useEffect(() => {
    if (plodID) fetchData(tabValue);
  }, [plodID]);

  //Due to bits needing its own URL this gets it.
  const getRequestURL = (Tab: string, manufacturerID?: any): string => {
    let URLend = Tab != "MBits" ? Tab : `Manufacturers/ID_${manufacturerID}/MBits`;
    return `/api/Plods/ID_${plodID}/${URLend}`;
  };

  //A big query for all the values it will need.
  const fetchDropDownData = async () => {
    setManufacturerOptions([]);
    setBitTypeOptions([]);
    setBitSizeOptions([]);
    try {
      const body = {
        query: `{
          Plods(ID_AP_Plod: ${plodID}) {
            BitSizes {
              label:Size
              value:ID_EA_BitSize
            }
            BitTypes {
              label:BitType
              value:ID_EA_BitType
            }
            Manufacturers {
              ManufacturerName
              ID_EA_Manufacturer
            }
          }
        }`
      };
      const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
      const { BitSizes, BitTypes, Manufacturers } = data.data.Plods[0];
      setManufacturerOptions(Manufacturers);
      setBitTypeOptions(BitTypes);
      setBitSizeOptions(BitSizes);
    } catch (e) {
      setManufacturerOptions([]);
      setBitTypeOptions([]);
      setBitSizeOptions([]);
      // probably could put somewhere else tbh
      setDataGridError(`An error occured while loading Bit Selectors, Please try again later.`);
    }
  };

  const fetchData = async (Tab: string, manufacturerID?: any) => {
    setDataRows([]);
    setLoading(true);
    setDataGridError(null);
    if (Tab == "MBits" && manufacturerID == null) {
      fetchDropDownData();
    }
    else {
      try {
        const { data } = await Api.post(getRequestURL(Tab, manufacturerID));
        setDataRows(data);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading ${renderTabContent()}, Please try again later.`);
      }
    }
    setLoading(false);
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentItemName: string = await dataRows.filter((row) => row.ID === ID)[0][getTabNameField()];
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the ${renderTabContent()} "${currentItemName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `${getRequestURL(tabValue, manufacturerSelectorID)}/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted ${renderTabContent()} "${currentItemName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting ${renderTabContent()} "${currentItemName}".`);
      }
    }
  };

  const validateFields = (newRow: GridRowModel) => {
    const validationErrors = [];
    if (tabValue === "BitSizes") {
      if (!newRow.Size?.trim()) validationErrors.push("Size cannot be empty.");
    }
    else if (tabValue === "BitTypes") {
      if (!newRow.BitType?.trim()) validationErrors.push("Bits Type cannot be empty.");
    }
    else if (tabValue === "Manufacturers") {
      if (!newRow.ManufacturerName?.trim()) validationErrors.push("Manufacturer Name cannot be empty.");
    }
    else {
      if (!newRow.BitName?.trim()) validationErrors.push("Bit Name cannot be empty.");
      if (newRow.ID_EA_BitSize == null) validationErrors.push("Bit Size cannot be empty."); // TODO: NEed to fix
      if (newRow.ID_EA_BitType == null) validationErrors.push("Bit Type cannot be empty."); //TODO : Need to fix
    }
    return validationErrors;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`${renderTabContent()} row has empty required fields.`, 'criticalerror', errors.join("\n"));
      return;
    }
    try {
      const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
      const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
      const { data } = await Api.post(
        `${getRequestURL(tabValue, manufacturerSelectorID)}/${endpoint}`,
        rawData
      );
      const updatedRow = {
        ...newRow,
        ID: data.ID ?? newRow.ID,
        isNew: false,
      };
      setDataRows((prev) =>
        prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
      );
      await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} ${renderTabContent()} "${newRow[getTabNameField()]}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} ${renderTabContent()} "${newRow[getTabNameField()]}".`);
    }
    return newRow;
  };

  //#region Toolbar header thing 
  //TODO: This needs to have it improved so it actually passes the values in.
  function handleManufacturerChange(event: SelectChangeEvent<any>, child: ReactNode): void {
    setManufacturerSelectorID(event.target.value);
    fetchData(tabValue, event.target.value);
  }

  function EditDataToolbar({
    setDataRows,
    setDataRowModesModel
  }: {
    setDataRows: React.Dispatch<React.SetStateAction<any[]>>;
    setDataRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  }) {
    const handleDataClick = () => {
      const ID = randomId();
      setDataRows((prev) => [
        ...prev,
        {
          ID,
          isNew: true,
          tabNameField: getTabNameField(),
        },
      ]);
      setDataRowModesModel((prev) => ({
        ...prev,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: getTabNameField() },
      }));
    };

    return (
      <>
        <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {tabValue === "MBits" && (
            <FormControl variant="standard" sx={{ marginLeft: 1 }}>
              <InputLabel id="manufacturer-selector-label" sx={{ fontSize: 15 }}>
                {manufacturerOptions.length == 0 ? "No Manufacturers " : "Select a Manufacturers"}
              </InputLabel>
              <Select
                labelId="manufacturer-selector-label"
                id="ManufacturerSelector"
                value={manufacturerSelectorID}
                onChange={handleManufacturerChange}
                disabled={manufacturerOptions.length == 0}
                sx={{ width: 300, fontSize: 15 }}
              >
                {manufacturerOptions.map((data: any) => (
                  <MenuItem value={data.ID_EA_Manufacturer} key={data.ID_EA_Manufacturer}>
                    {data.ManufacturerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button
            style={{ marginLeft: "auto" }}
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleDataClick}
            disabled={dataGridError != null || (tabValue == "MBits" && manufacturerSelectorID == null)}
          >
            Add {renderTabContent()}
          </Button>
        </GridToolbarContainer >
      </>
    );
  }
  //#endregion

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTabValue(newTab);
    fetchData(newTab);
    if (newTab != "MBits") {
      setManufacturerSelectorID(null);
    }
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 'BitSizes':
        return "Bit Size";
      case 'BitTypes':
        return "Bit Type";
      case 'Manufacturers':
        return "Manufacturer";
      case 'MBits':
        return "Bit";
      default:
        return "Error";
    }
  };
  const getTabNameField = () => {
    switch (tabValue) {
      case 'BitSizes':
        return "Size";
      case 'BitTypes':
        return "BitType";
      case 'Manufacturers':
        return "ManufacturerName";
      default:
        return "BitName";
    }
  };

  //#region Columns
  const columns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: tabValue === "BitSizes" ? "Size" : tabValue === "BitTypes" ? "BitType" : "ManufacturerName",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];

  const bitColumns: GridColDef<any>[] = [
    {
      field: "BitName",
      headerName: "Bit Name",
      editable: true,
      flex: 0.3,
    },
    {
      field: "ID_EA_BitSize",
      headerName: "Bit Size",
      editable: true,
      flex: 0.3,
      type: "singleSelect",
      valueOptions: bitSizeOptions,
    },
    {
      field: "ID_EA_BitType",
      headerName: "Bit Type",
      editable: true,
      flex: 0.3,
      type: "singleSelect",
      valueOptions: bitTypeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode =
          dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      }
    }
  ];
  //#endregion

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          style={{ marginLeft: 20, marginRight: 20 }}
          variant="fullWidth"
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="Field Selector"
        >
          <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value="BitSizes" label="Bits Sizes" />
          <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value="BitTypes" label="Bits Types" />
          <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value="Manufacturers" label="Manufacturers" />
          <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value="MBits" label="Bits" />
        </Tabs>
      </Box>
      <Box sx={{ height: "90vh", margin: "10px" }}>
        <DataGridPremium
          columns={tabValue == "MBits" ? bitColumns : columns}
          rows={dataRows}
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          editMode="row"
          disableRowSelectionOnClick
          loading={loading}
          getRowId={(row) => row.ID} // Gets the id from database, not the local style one
          slots={{
            toolbar: () => (<EditDataToolbar setDataRows={setDataRows} setDataRowModesModel={setDataRowModesModel} />),
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                message={dataGridError ? dataGridError : `No ${renderTabContent()} Data`}
                onRetry={dataGridError ? () => fetchData(tabValue) : undefined}
              />
            ),
          }}
        />
      </Box>
    </>
  );
};

export default Bits;
