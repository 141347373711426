import React, { useState } from 'react';
import { GridRowId, GridRowModes, GridActionsCellItem } from '@mui/x-data-grid-premium';
import { IconButton, Menu, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type RowModel = {
  ID: GridRowId; // Using ID as the unique identifier
  isNew?: boolean;
  // Add other row properties here if needed
};

type RowModesState = Record<GridRowId, { mode: GridRowModes; ignoreModifications?: boolean }>;

// The row handler props are the same, but now we have additional action controls
type GenericHandlersProps<T extends RowModel> = {
  rowModesModel: RowModesState;
  setRowModesModel: React.Dispatch<React.SetStateAction<RowModesState>>;
  rows: T[];
  setRows: React.Dispatch<React.SetStateAction<T[]>>;
  actions?: {
    edit?: boolean;
    save?: boolean;
    cancel?: boolean;
    delete?: boolean;
  };
};

export function useEditableRowHandlers<T extends RowModel>({
  rowModesModel,
  setRowModesModel,
  rows,
  setRows,
  actions = { edit: true, save: true, cancel: true, delete: true },
}: GenericHandlersProps<T>) {
  const handleEditClick = (ID: GridRowId) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [ID]: { mode: GridRowModes.Edit },
    }));
  };

  const handleSaveClick = (ID: GridRowId) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [ID]: { mode: GridRowModes.View },
    }));
  };

  const handleDeleteClick = (ID: GridRowId) => () => {
    setRows(rows.filter((row) => row.ID !== ID)); // Using ID to filter
  };

  const handleCancelClick = (ID: GridRowId) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find((row) => row.ID === ID); // Using ID to find row
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.ID !== ID)); // Using ID to filter
    }
  };

  return {
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
  };
}

type Handlers = {
  handleEditClick: (ID: GridRowId) => () => void;
  handleSaveClick: (ID: GridRowId) => () => void;
  handleCancelClick: (ID: GridRowId) => () => void;
  handleDeleteClick: (ID: GridRowId) => () => void;
  handleArchiveClick?: (ID: GridRowId) => () => void;
};

type GetActionsForRowProps = {
  ID: GridRowId; // Ensure we're using ID here
  rowModesModel: RowModesState;
  handlers: Handlers;
  actions: {
    edit?: boolean;
    save?: boolean;
    cancel?: boolean;
    delete?: boolean;
    archive?: boolean;
  };
};

export const getActionsForRow = ({
  ID,
  rowModesModel,
  handlers,
  actions,
}: GetActionsForRowProps) => {
  const isInEditMode = rowModesModel[ID]?.mode === GridRowModes.Edit;

  const actionItems: JSX.Element[] = [];

  if (isInEditMode) {
    if (actions.save) {
      actionItems.push(
        <React.Fragment>
          <Tooltip title="Save" key={`save-${ID}`}>
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              className="textPrimary"
              onClick={handlers.handleSaveClick(ID)}
              color="inherit"
              key={`save-${ID}`}
            />
          </Tooltip>
        </React.Fragment>
      );
    }
    if (actions.cancel) {
      actionItems.push(
        <React.Fragment>
          <Tooltip title="Cancel" key={`cancel-${ID}`}>
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handlers.handleCancelClick(ID)}
              color="inherit"
              key={`cancel-${ID}`}
            />
          </Tooltip>
        </React.Fragment>
      );
    }
  } else {
    if (actions.edit) {
      actionItems.push(
        <React.Fragment>
          <Tooltip title="Edit" key={`edit-${ID}`}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handlers.handleEditClick(ID)}
              color="inherit"
              key={`edit-${ID}`}
            />
          </Tooltip>
        </React.Fragment>
      );
    }

    if (actions.delete && actions.archive) {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };

      const handleMenuClose = () => {
        setAnchorEl(null);
      };
      actionItems.push(
        <Tooltip title="More" key={`more-${ID}`}>
          <IconButton onClick={handleMenuOpen} color="inherit">
            <MoreVertIcon />
          </IconButton>
        </Tooltip>,
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={() => handleMenuClose()}
        >
          <React.Fragment>
            <Tooltip title="Delete" key={`delete-${ID}`}>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                className="textPrimary"
                onClick={handlers.handleDeleteClick(ID)}
                color="inherit"
                key={`delete-${ID}`}
                showInMenu={true}
              />
            </Tooltip>
          </React.Fragment>
          <React.Fragment>
            <Tooltip title="Archive" key={`archive-${ID}`}>
              <GridActionsCellItem
                icon={<ArchiveIcon />}
                label="archive"
                className="textPrimary"
                onClick={handlers.handleArchiveClick ? handlers.handleArchiveClick(ID) : undefined}
                color="inherit"
                key={`archive-${ID}`}
                showInMenu={true}
              />
            </Tooltip>
          </React.Fragment>
        </Menu>,
      );
    }
    else if (actions.delete) {
      actionItems.push(
        <React.Fragment>
          <Tooltip title="Delete" key={`delete-${ID}`}>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              onClick={handlers.handleDeleteClick(ID)}
              color="inherit"
              key={`delete-${ID}`}
            />
          </Tooltip>
        </React.Fragment>
      );
    }
    else if (actions.archive) {
      actionItems.push(
        <React.Fragment>
          <Tooltip title="Archive" key={`archive-${ID}`}>
            <GridActionsCellItem
              icon={<ArchiveIcon />}
              label="archive"
              className="textPrimary"
              onClick={handlers.handleArchiveClick ? handlers.handleArchiveClick(ID) : undefined}
              color="inherit"
              key={`archive-${ID}`}
            />
          </Tooltip>
        </React.Fragment>
      );
    }
  }

  return actionItems;
};
