import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormHelperText, Stack, SelectChangeEvent } from "@mui/material";
import { Api, handleErrorResponse } from "../api";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";

interface MiningCompanySelectProps {
  plodID: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string | null;
  disabled?: boolean;
  width?: string;
  miningCompanyOptions: IMiningCompany[];
  setMiningCompanyOptions: React.Dispatch<React.SetStateAction<IMiningCompany[]>>;
}

const MiningCompanySelect: React.FC<MiningCompanySelectProps> = ({
  plodID,
  value,
  onChange,
  errorMessage,
  disabled,
  width = "250px",
  miningCompanyOptions,
  setMiningCompanyOptions,
}) => {
  const fetchMiningCompanyData = async () => {
    Api.post(`/api/Plods/ID_${plodID}/MiningCompanys/`)
      .then(({ data }) => {
        setMiningCompanyOptions(data);
      })
      .catch((e) => {
        console.error("Error fetching Clients", e);
      });
  };

  useEffect(() => {
    fetchMiningCompanyData();
  }, [plodID]);

  return (
    <Stack spacing={0} padding="5px" paddingRight='10px'>
      <FormHelperText>Client</FormHelperText>
      <Select
        value={value}
        onChange={(event: SelectChangeEvent<string>) => onChange(event.target.value)}
        disabled={disabled || miningCompanyOptions.length === 0 || errorMessage != null}
        style={{ width }}
        displayEmpty
      >
        <MenuItem value="" key="Unselected" disabled>
          {miningCompanyOptions.length === 0 ? "No Clientss" : "Select a Client"}
        </MenuItem>
        {miningCompanyOptions.map((data: any) => (
          <MenuItem key={data["ID_EA_MiningCompany"]} value={data["ID_EA_MiningCompany"]}>
            {data["MiningCompanyName"]}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default MiningCompanySelect;
