import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import CustomCardWrapper from './CustomCardWrapper';
import CustomCardHeader from './CustomCardHeader';
import { TColour } from '../../types/Colour.type';
import { IChipLabel } from './ChipLabel.interface';
import { AxisConfig, BarSeriesType, ChartsXAxisProps, ChartsYAxisProps, ScaleName } from '@mui/x-charts';
import { AxisScaleConfig } from '@mui/x-charts/internals';
// Generate a series of lighter blue colors
export const generateLighterBlues = (count: number) => {
  const baseHSL = { h: 210, s: 50, l: 40 }; // Starting with medium dark blue (hue: 210)
  const colorArray: string[] = [];

  for (let i = 0; i < count; i++) {
    const lightness = Math.min(baseHSL.l + i * 5, 90); // Increment lightness by 5, max at 90
    colorArray.push(`hsl(${baseHSL.h}, ${baseHSL.s}%, ${lightness}%)`);
  }

  return colorArray;
};

interface IBarChartDynamic extends IChipLabel {
  title: string;
  description: string;
  totalField: string;
  data: BarSeriesType[];
  loading?: boolean;
  errorMessage?: string | null;
  xAxis: AxisConfig<ScaleName, any, ChartsXAxisProps>[];
  yAxis?: AxisConfig<ScaleName, any, ChartsYAxisProps>[];
  rightAxis?: string;
}

const CustomBarChart: React.FC<IBarChartDynamic> = ({ title, description, totalField, data, xAxis, chip, loading, errorMessage, yAxis, rightAxis }) => {
  return (
    <CustomCardWrapper title={title} sx={{ height: 400 }}>
      <CustomCardHeader
        totalField={totalField}
        description={description}
        chip={chip}
      />
      <BarChart
        loading={loading}
        borderRadius={8}
        xAxis={xAxis}
        yAxis={yAxis}
        series={data}
        height={250}
        margin={{ left: 50, right: 50, top: 20, bottom: 20 }}
        grid={{ horizontal: true }}
        slotProps={{
          legend: {
            hidden: true,
          },
          noDataOverlay: {
            message: errorMessage ?? `No data available.`
          },
        }}
        rightAxis={rightAxis}
      />
    </CustomCardWrapper>
  );
};

export default CustomBarChart;