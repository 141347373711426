import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import {
  DataGridPremium,
  GridEditSingleSelectCellProps,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import getInstanceID from "../functions/getInstanceID";
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';
import { AlertService } from '../services/AlertService';
import { Api, handleErrorResponse } from "../api";
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";
import CustomToolBarWithDropDownToolbar from "../components/CustomToolbarWithDropdown";
import { getActionsForRow } from "../components/EditableRowHandlers";


const Rigs: React.FC = () => {
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const plodID = getInstanceID();
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });

  const fetchData = async () => {
    setDataRows([]);
    setDataGridError(null);
    setLoading(true);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${plodID}/Rigs`);
      setDataRows(data);
    } catch (e) {
      setDataRows([]);
      setDataGridError("An error occured while loading Rigs, Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (plodID) fetchData();
  }, [plodID]);

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentRigName: string = await dataRows.filter((row) => row.ID === ID)[0].RigName;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Rig "${currentRigName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/Rigs/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Rig "${currentRigName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Rig "${currentRigName}".`);
      }
    }
  };
  const validateFields = (newRow: GridRowModel) => {
    const validationErrors = [];
    if (!newRow.RigName?.trim()) validationErrors.push("Rig Name cannot be empty.");
    if (newRow.Latitude > 90 || newRow.Latitude < -90) validationErrors.push("Latitude must be between -90 and 90");
    if (newRow.Longitude > 180 || newRow.Longitude < -180) validationErrors.push("Longitude must be between -180 and 180");
    return validationErrors;
  };


  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`Rig "${newRow.RigName}" row has fields causing errors.`, 'criticalerror', errors.join("\n"));
      return;
    }
    else {
      try {
        const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
        const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/Rigs/${endpoint}`,
          rawData
        );
        const updatedRow = {
          ...newRow,
          ID: newRow.ID,
          isNew: false,
        };
        setDataRows((prev) =>
          prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
        );
        await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Rig "${newRow.RigName}".`, "success");
        return updatedRow;
      } catch (e) {
        handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Rig "${newRow.RigName}".`);
      }
    }
    return newRow;
  };

  function CustomLocationEditComponent(props: GridEditSingleSelectCellProps) {
    const { id, value, field, api, colDef, row } = props;
    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;
      const regex = /^-?\d{0,3}(\.\d{0,6})?$/;
      if (regex.test(inputValue) || inputValue === '') {
        await api.setEditCellValue({ id: id, field: field, value: inputValue });
      }
    };
    return (
      <TextField
        type="text"
        inputMode="numeric"
        id="inputField"
        value={value}
        onChange={handleInputChange}
        fullWidth
      />
    );
  }

  return (
    <Box sx={{ height: "90vh", margin: "10px" }}>
      {/*// make these into a component so can just use easily across all, or maybe as parent idk*/}
      <DataGridPremium
        columns={[
          {
            field: 'RigName',
            headerName: "Rig Name",
            editable: true,
            flex: 1
          },
          {
            field: 'Latitude',
            headerName: 'Latitude',
            flex: 1,
            editable: true,
            renderEditCell: (params: GridEditSingleSelectCellProps) => {
              return (
                <CustomLocationEditComponent
                  {...params}
                />
              );
            },
          },
          {
            field: 'Longitude',
            headerName: 'Longitude',
            flex: 1,
            editable: true,
            renderEditCell: (params: GridEditSingleSelectCellProps) => {
              return (
                <CustomLocationEditComponent
                  {...params}
                />
              );
            },
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id: ID }) =>
              getActionsForRow({
                ID,
                rowModesModel: dataRowModesModel,
                handlers: { handleDeleteClick, handleCancelClick, handleEditClick, handleSaveClick },
                actions: { cancel: true, edit: true, save: true, delete: true },
              }),
          },
        ]}
        rows={dataRows}
        rowModesModel={dataRowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        editMode="row"
        disableRowSelectionOnClick
        loading={loading}
        getRowId={(row) => row.ID} // Gets the id from database, not the local style one
        slots={{
          toolbar: () => (<CustomToolBarWithDropDownToolbar
            setDataRows={setDataRows} // where setRigs updates the rig rows
            setDataRowModesModel={setDataRowModesModel} // for setting row modes
            rowTemplate={{ RigName: "", Latitude: '', Longitude: '' }} // define initial fields for rigs
            fieldName="RigName" // focus on RigName field
            itemName="Rig"
          />),
          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              message={dataGridError ? dataGridError : "No Rig Data"}
              onRetry={dataGridError ? fetchData : undefined}
            />
          ),
        }}
      />
    </Box>
  );
};

export default Rigs;