import { createGlobalStyle } from "styled-components";
import styled from 'styled-components';
export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
    }
`;
export const RowAlign = styled.div`
    display:flex,
    margin:25,
    justify-content:space-around

`;