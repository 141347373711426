import React, { useEffect } from "react";
import getInstanceID from "../functions/getInstanceID";
import { Box, Card, CardContent, Chip, Stack, Typography, useMediaQuery, useTheme, TooltipProps, Divider, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BarChart } from '@mui/x-charts/BarChart';
import { ChartContainer, ChartsItemTooltipContent, ChartsLegend, getSeriesToDisplay, LineChart, PieChart, PieChartProps, PiePlot } from '@mui/x-charts';
import RigLocationWidget from "../components/widgets/RigLocationsWidget";
import ShiftStatusWeekByWeekWidget from "../components/widgets/ShiftStatusWeekByWeekWidget";
import DrillingDepthAndHoursWidget from "../components/widgets/DrillingDepthAndHoursWidget";
import CustomCardWrapper from "../components/widgets/CustomCardWrapper";
import CustomCardHeader from "../components/widgets/CustomCardHeader";
import { FormattedSeries } from "@mui/x-charts/internals";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { PieSeriesType } from '@mui/x-charts';
import { color, styled } from "@mui/system";
import { Api } from "../api";
function PageViewsBarChart() {
  const theme = useTheme();
  const colorPalette = [
    theme.palette.primary.dark,
    theme.palette.primary.main,
    theme.palette.primary.light,
  ];
  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Shift Statuses
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              5
            </Typography>

          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Shifts done week on week
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={
            [
              {
                scaleType: 'band',
                categoryGapRatio: 0.5,
                data: ["24 Nov - 30 Nov", "17 Nov - 23 Nov", "10 Nov - 16 Nov", "03 Nov - 09 Nov", "27 Oct - 02 Nov", "20 Oct - 26 Oct", "13 Oct - 19 Oct"],
              },
            ] as any
          }
          series={[{
            "id": "waiting-for-client",
            "label": "Waiting For Client",
            "data": [
              0,
              0,
              1,
              1,
              0,
              0,
              0
            ],
            "stack": "A"
          }, {
            "id": "unknown",
            "label": "Unknown",
            "data": [
              0,
              0,
              0,
              1,
              0,
              0,
              0
            ],
            "stack": "A"
          }, {
            "id": "none",
            "label": "None",
            "data": [
              0,
              0,
              0,
              2,
              0,
              0,
              0
            ],
            "stack": "A"
          }]}
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

function getDaysInMonth(month: number, year: number) {
  const date = new Date(year, month, 0);
  const monthName = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const daysInMonth = date.getDate();
  const days = [];
  let i = 1;
  while (days.length < daysInMonth) {
    days.push(`${monthName} ${i}`);
    i += 1;
  }
  return days;
}

function SessionsChart() {
  const theme = useTheme();
  const data = getDaysInMonth(4, 2024);

  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Sessions
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              13,277
            </Typography>
            <Chip size="small" color="success" label="+35%" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Sessions per day for the last 30 days
          </Typography>
        </Stack>
        <LineChart
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'point',
              data,
              tickInterval: (index: any, i: number) => (i + 1) % 5 === 0,
            },
          ]}
          series={[
            {
              id: 'direct',
              label: 'Direct',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                300, 900, 600, 1200, 1500, 1800, 2400, 2100, 2700, 3000, 1800, 3300,
                3600, 3900, 4200, 4500, 3900, 4800, 5100, 5400, 4800, 5700, 6000,
                6300, 6600, 6900, 7200, 7500, 7800, 8100,
              ],
            },
            {
              id: 'referral',
              label: 'Referral',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                500, 900, 700, 1400, 1100, 1700, 2300, 2000, 2600, 2900, 2300, 3200,
                3500, 3800, 4100, 4400, 2900, 4700, 5000, 5300, 5600, 5900, 6200,
                6500, 5600, 6800, 7100, 7400, 7700, 8000,
              ],
            },
            {
              id: 'organic',
              label: 'Organic',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              stackOrder: 'ascending',
              data: [
                1000, 1500, 1200, 1700, 1300, 2000, 2400, 2200, 2600, 2800, 2500,
                3000, 3400, 3700, 3200, 3900, 4100, 3500, 4300, 4500, 4000, 4700,
                5000, 5200, 4800, 5400, 5600, 5900, 6100, 6300,
              ],
              area: true,
            },
          ]}
          height={250}
          margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          sx={{
            '& .MuiAreaElement-series-organic': {
              fill: "url('#organic')",
            },
            '& .MuiAreaElement-series-referral': {
              fill: "url('#referral')",
            },
            '& .MuiAreaElement-series-direct': {
              fill: "url('#direct')",
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color={theme.palette.primary.dark} id="organic" />
          <AreaGradient color={theme.palette.primary.main} id="referral" />
          <AreaGradient color={theme.palette.primary.light} id="direct" />
        </LineChart>
      </CardContent>
    </Card>
  );
}

function TasksPastThirtyDaysPieChart() {
  let instanceID = getInstanceID();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [mydata, setMyData] = React.useState<any[]>([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm') || theme.breakpoints.down('md'));

  const fetchData = async () => {
    setMyData([]);
    setLoading(true);
    setErrorMessage(null);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${instanceID}/~GetAllDrillingTaskTypesPastThirtyDays`);

      const uniqueColors = [
        'red', 'blue', 'green', 'orange', 'purple', 'yellow', 'pink'
      ];
      // Sort the data by value in descending order
      const sortedData = data.sort((a: { value: number; }, b: { value: number; }) => b.value - a.value);
      // Process the sorted data
      const processedData: any[] = sortedData.map((item: { [x: string]: any; label: any; }, index: number) => {
        let { label, ...rest } = item; // Destructure to access label and other fields
        const base = { ...rest, customLabel: label }; // Add customLabel

        if (index < 7) {
          // Add color and retain the label field for the top 7

          return { ...base, label, color: uniqueColors[index] || 'grey' };
        } else {
          // Add color and remove the label field for the rest
          return { ...base, color: 'grey' };
        }
      });
      if (processedData.length > 7) {
        const newRow = { label: 'other', value: 0, color: 'grey' };
        processedData.push(newRow);
      }
      setMyData(processedData);
    } catch (e) {
      setErrorMessage("Failed to fetch data");

      setMyData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  function CustomItemTooltip({ itemData, series }: any) {
    const tooltipData = series.data[itemData.dataIndex];

    return (
      <Box
        sx={{
          p: 1.5,
          backgroundColor: "background.paper",
          borderRadius: "4px",
          boxShadow: 3,
          color: "text.primary",
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          minWidth: "120px",
        }}
      >
        {/* Display Label */}
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {tooltipData?.customLabel || "Unknown Label"}
        </Typography>

        {/* Display Value */}
        <Typography variant="body2">
          <strong>Value:</strong> {tooltipData?.value ?? "N/A"}
        </Typography>
      </Box>
    );
  }
  // var x :PieChartProps = 
  return (
    <CustomCardWrapper title={"Total Tasks"} sx={{ height: isSmallScreen ? 250 : 400 }} loading={loading} errorMessage={errorMessage}>
      <CustomCardHeader
        description={"Tasks used in shifts for the past 30 days"}
      />
      {/* Pie Chart from MUI X Charts */}


      <PieChart

        series={[
          {
            data: mydata,
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          },
        ]}
        height={isSmallScreen ? 200 : 275}
        width={isSmallScreen ? undefined : 700}
        margin={{ top: 20, bottom: 20, left: 20, right: 130 }}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: 0,
            labelStyle: {
              fontSize: isSmallScreen ? 10 : 12, // Adjust font size for small screens
              fill: 'currentColor',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: isSmallScreen ? 80 : 200, // Adjust max width for small screens
            },
          },
        }}
        tooltip={{ trigger: 'item' }}
        slots={{
          itemContent: CustomItemTooltip,
        }}
      />
    </CustomCardWrapper>
  );
}


const Home: React.FunctionComponent = () => {
  let instanceID = getInstanceID();

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, }}>
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mx: 3,
            pb: 5,
            mb: 8,
          }}
        >
          <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            {/* cards */}
            <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
              Overview
            </Typography>
            <Grid
              container
              spacing={2}
              columns={12}
              sx={{ mb: (theme) => theme.spacing(2) }}
            >
              <Grid size={{ xs: 12, md: 12, lg: 12, xl: 6 }} sx={{ minWidth: 500 }}>
                <ShiftStatusWeekByWeekWidget />
              </Grid>

              <Grid size={{ xs: 12, md: 12, lg: 12, xl: 6 }} sx={{ minWidth: 500 }}>
                <   TasksPastThirtyDaysPieChart />
              </Grid>

              <Grid size={{ xs: 12, md: 12, lg: 12 }} sx={{ minWidth: 500 }}>
                <DrillingDepthAndHoursWidget />
              </Grid>

              <Grid size={{ xs: 24, md: 12 }} height={500} sx={{ minWidth: 500 }}>
                <RigLocationWidget />
              </Grid>



              {/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <CustomStatCard
                title={"Total Drilling Depth"}
                totalField={"0"}
                description={"Total Drilling Depth Day on Day for this current month."}
                trendValue={"0"}
                trend={"neutral"}
                data={[0, 0, 0, 1435, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
                labels={["Nov 01", "Nov 02", "Nov 03", "Nov 04", "Nov 05", "Nov 06", "Nov 07", "Nov 08", "Nov 09", "Nov 10", "Nov 11", "Nov 12", "Nov 13", "Nov 14", "Nov 15"]}
                loading={true}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <CustomStatCard
                title={"Total Drilling Hours"}
                totalField={"0"}
                description={"Total Drilling Hours Day on Day for this current month."}
                trendValue={"0"}
                trend={"neutral"}
                data={[0, 0, 0, 1435, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
                labels={["Nov 01", "Nov 02", "Nov 03", "Nov 04", "Nov 05", "Nov 06", "Nov 07", "Nov 08", "Nov 09", "Nov 10", "Nov 11", "Nov 12", "Nov 13", "Nov 14", "Nov 15"]}
                loading={false}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <CustomStatCard
                title={"Total Task Hours"}
                totalField={"0"}
                description={"Total Task Hours for this current month day on day."}
                trendValue={"0"}
                trend={"neutral"}
                data={[0, 0, 0, 1435, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
                labels={["Nov 01", "Nov 02", "Nov 03", "Nov 04", "Nov 05", "Nov 06", "Nov 07", "Nov 08", "Nov 09", "Nov 10", "Nov 11", "Nov 12", "Nov 13", "Nov 14", "Nov 15"]}
                loading={false}
                errorMessage={"An error occured"}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              {/* //need to put something here 
              <Box />

            </Grid> */}

              {/* <Grid size={{ xs: 12, md: 6 }}>
              <SessionsChart />
            </Grid> */}

            </Grid>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Home;
