import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  FormControl,
  TextField,
  Typography,
  Grid,
  Stack,
  AccordionActions
} from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
} from "@mui/x-data-grid-premium";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import { Api, handleErrorResponse } from "../api";
import getInstanceID from "../functions/getInstanceID";

import CustomToolBarWithDropDownToolbar from "../components/CustomToolbarWithDropdown";
import { AlertService } from "../services/AlertService";
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MiningCompanySelect from "../components/MiningCompanySelect";
import ContractSelect from "../components/ContractSelect";
import DrillingProgramSelect from "../components/DrillingProgramSelect";
import { IContract } from "../Interfaces/Contract.interface";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";

const ContractsSetup: React.FunctionComponent = () => {
  const plodID = getInstanceID();
  const search: string = useLocation().search;
  const searchParams: URLSearchParams = new URLSearchParams(search);
  let URLMiningCompanyID: string = searchParams.get("MiningCompanyID") || "";
  let URLContractID: string = searchParams.get("ContractID") || "";
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [bitSizeId, setBitSizeId] = React.useState<string | null>(null);
  const [bitSizeData, setBitSizeData] = React.useState<any>([]);
  const [dataRowModesModel, setDataRowModesModel] = React.useState<GridRowModesModel>({});
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows,
    dataRowModesModel,
    setDataRowModesModel,
    setDataRows,
  });

  //#region Contract Settings Tab
  const handleContractSettingsSubmit = async () => {
    try {
      const rawData: string = JSON.stringify(selectedContractData);
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/~UpdateItem`,
        rawData
      );
      setContractOptions((prev: any) =>
        prev.map((row: IContract) => (row.ID === selectedContractData.ID_EC_Contract ? selectedContractData : row))
      );
      await AlertService.showAlert(`Successfully Updated Contract "${selectedContractData.ContractName}".`, "success");
    }
    catch (e) {
      handleErrorResponse(e, `Error Updating Contract "${selectedContractData.ContractName}".`);
    }
  };

  //#endregion



  //#region DropDown selectors


  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");

  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  useEffect(() => {
    const filteredOption = (miningCompanyOptions.find((prev: any) => prev.ID_EA_MiningCompany.toString() === URLMiningCompanyID))?.ID_EA_MiningCompany.toString() ?? '';
    if (filteredOption) {
      setMiningCompanySelectorID(filteredOption);
      URLMiningCompanyID = '';
    }
  }, [miningCompanyOptions]);

  useEffect(() => {
    const filteredOption = (contractOptions.find((prev: any) => prev.ID_EC_Contract.toString() === URLContractID))?.ID_EC_Contract.toString() ?? '';
    if (filteredOption) {
      setContractSelectorID(filteredOption);
      URLContractID = '';
    }
  }, [contractOptions]);


  const handleMiningCompanyChange = (value: string) => {
    setDataRows([]);
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID(""); // Reset drilling program selections
  };

  const handleContractChange = (value: string) => {
    setDataRows([]);
    setContractSelectorID(value);
    let f = contractOptions.find((prev: IContract) => prev.ID_EC_Contract.toString() == value) || {};
    setSelectedContractData(f);
    setDrillingProgramSelectorID(""); // Reset drilling program selection
  };

  const handleDrillingProgramChange = (value: string) => {
    setDataRows([]);
    setDrillingProgramSelectorID(value);
  };

  const [selectedContractData, setSelectedContractData] = React.useState<Record<string, any>>({});

  const [rigOptions, setRigOptions] = React.useState([]);
  const [consumableOptions, setConsumableOptions] = React.useState([]);
  const [siteOptions, setSiteOptions] = React.useState([]);
  const [taskOptions, setTaskOptions] = React.useState([]);
  const [personnelOptions, setPersonnelOptions] = React.useState([]);
  const [personnelTypeOptions, setPersonnelTypeOptions] = React.useState([]);
  const [chargeTypeOptions, setChargeTypeOptions] = React.useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = React.useState([]);
  const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = React.useState([]);
  const [bitSizeOptions, setBitSizeOptions] = React.useState<any[]>([]);
  const [workflowOptions, setWorkflowOptions] = React.useState<any[]>([]);
  const [objectiveOptions, setObjectiveOptions] = React.useState<any[]>([]);


  useEffect(() => {
    fetchOptionsData(); //TODO: Surely there has to be a better way to do this
  }, []);

  const fetchOptionsData = async () => { //TODO: Make this into a custom request.
    try {
      const { data } = await Api.post(`/api/Plods/ID_${plodID}/~GetAllValuesForContractSetup`);
      const { Rigs, Consumables, Sites, Tasks, Personnels, PersonnelTypes, BitSizes, UnitOfMeasurements, TaskMetrics, Workflows, Objectives } = data;
      setRigOptions(Rigs);
      setConsumableOptions(Consumables);
      setSiteOptions(Sites);
      setTaskOptions(Tasks);
      setPersonnelOptions(Personnels);
      setPersonnelTypeOptions(PersonnelTypes);
      setBitSizeOptions(BitSizes);
      setUnitOfMeasurementOptions(UnitOfMeasurements);
      setTaskTypeOptions(TaskMetrics);
      setWorkflowOptions(Workflows);
      setObjectiveOptions(Objectives);
    } catch (e) {
      setRigOptions([]);
      setConsumableOptions([]);
      setSiteOptions([]);
      setTaskOptions([]);
      setPersonnelOptions([]);
      setPersonnelTypeOptions([]);
      setBitSizeOptions([]);
      setUnitOfMeasurementOptions([]);
      setTaskTypeOptions([]);
      setWorkflowOptions([]);
      // handleErrorResponse(e, "Error while loading selectors"); //TODO: need a better way for this. that actually stops the page.
      setDataGridError(`An error occured while loading Selectors, Please try again later.`);
    }
  };


  useEffect(() => {
    if (drillingProgramSelectorID) fetchData(optionsTabValue, null); //TODO: Surely there has to be a better way to do this
  }, [drillingProgramSelectorID]);


  //#endregion

  const handleFieldChange = (field: string, value: any) => {
    if (field == "StartDate" || field == "EndDate") { // THIS IS DUMB SHIT TO GET IT NOT TO LOCALISE ahfsdkh
      try {
        let InDateFormat = dayjs(value);
        let convertedToString = InDateFormat.format('MM/DD/YYYY');
        if (convertedToString != "Invalid Date") {
          value = convertedToString;
        }
        else {
          value = null;
        }
      }
      catch (e) {
        value = null; // maybe show an error message but idk it works.
      }
    }
    setSelectedContractData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };


  //#region data fetching

  // Maybe look at doing something with this differently idk.
  const fetchChargeTypeOptions = async () => {
    try {
      const body = `
      query Plods {
        Plods(ID_AP_Plod: ${plodID}) {
          MiningCompanys(ID_EA_MiningCompany: ${miningCompanySelectorID}) {
            Contracts(ID_EC_Contract: ${contractSelectorID}) {
              DrillingPrograms(ID_EDP_DrillingProgram: ${drillingProgramSelectorID}) {
                TaskChargeTypes {
                  ID
                  value: ID_EDP_Task_ChargeType
                  ID_AP_Plod
                  ID_EDP_DrillingProgram
                  Rate
                  ChargeType
                  ID_EA_UnitOfMeasurement
                  UnitOfMeasurement {
                    Name
                  }
                }
              }
            }
          }
        }
      }`;
      const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
      const MyData = data?.data.Plods[0].MiningCompanys[0].Contracts[0].DrillingPrograms[0].TaskChargeTypes;
      MyData.forEach(
        (obj: any) =>
        (obj.label =
          `${obj.ChargeType} (${obj.UnitOfMeasurement[0].Name}) $${obj.Rate.toFixed(2)}`
        )
      );
      setChargeTypeOptions(MyData);
    } catch (e) {
      setChargeTypeOptions([]);
      handleErrorResponse(e, "Error while loading task selectors"); //TODO: need a better way for this. that actually stops the page.
      // setDataGridError(`An error occured while loading Options Selectors, Please try again later.`);
    }
  };

  const handleBitSizeSelectorChange = (event: SelectChangeEvent<string>) => {
    setBitSizeId(event.target.value);
    fetchData(optionsTabValue, event.target.value);
  };

  const fetchBitSize = async (drillingProgramID: string) => {
    try {
      const body = `{
        DPBitSizes(){
            value:ID
            BitSize{   
              label:Size
            }
          }
        }`;
      const { data } = await Api.post(`/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/ID_${drillingProgramID}/DPBitSizes/~GraphQL`, body);
      const bitData = data?.data?.DPBitSizes?.map((i: any) => {
        return {
          value: i.value,
          label: i?.BitSize[0]?.label || "",
        };
      });
      setBitSizeData(bitData);
    } catch (e) {
      setBitSizeData([]);
      // probably could put somewhere else tbh
      setDataGridError(`An error occured while loading Bit Selectors, Please try again later.`);
    }
  };

  //#region Tabs
  const [modeTabValue, setModeTabValue] = React.useState<string>("DrillingProgramSetup");
  const [optionsTabValue, setOptionsTabValue] = React.useState<string>("DPRigs");
  const [drillingProgramCostTabValue, setDrillingProgramCostTabValue] = React.useState<string>("TaskChargeTypes");
  const [drillingProgramSetupTabValue, setDrillingProgramSetupTabValue] = React.useState<string>("DPRigs");

  const handleTabDataChange = (tabValue: string) => {
    setDataRows([]);
    if (tabValue == "DPTasks") {
      fetchChargeTypeOptions();
    }
    if (tabValue == "PerMRates") {
      setBitSizeId(null);
    }
    fetchData(tabValue, null);
  };

  const handleModeTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setDataRows([]);
    if (newTab == "DrillingProgramSetup") {
      setOptionsTabValue(drillingProgramSetupTabValue);
      handleTabDataChange(drillingProgramSetupTabValue);
    } else if (newTab == "DrillingProgramCost") {
      setOptionsTabValue(drillingProgramCostTabValue);
      handleTabDataChange(drillingProgramCostTabValue);
    }
    setModeTabValue(newTab);
  };
  const handleDrillingProgramCostTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setDrillingProgramCostTabValue(newTab);
    setOptionsTabValue(newTab);
    handleTabDataChange(newTab);
  };
  const handleDrillingProgramSetupTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setDrillingProgramSetupTabValue(newTab);
    setOptionsTabValue(newTab);
    handleTabDataChange(newTab);
  };
  const renderTabContent = () => {
    switch (optionsTabValue) {
      case 'DPRigs':
        return 'Rig';
      case 'DPConsumables':
        return 'Consumable';
      case 'DPSites':
        return 'Site';
      case 'DPPersonnels':
        return 'Operator';
      case 'DPTasks':
        return 'Task';
      case 'DPBitSizes':
        return 'Bit Size';
      case 'TaskChargeTypes':
        return 'Task Charge Rate';
      case 'MonthlyCharges':
        return 'Monthly Charge';
      case 'OneOffCharges':
        return 'One Off Charge';
      case 'ShiftCharges':
        return 'Shift Charge';
      case 'PerMRates':
        return 'Per M Rates';
      case 'DPObjectives':
        return 'Objective';
      default:
        return 'Error'; // Default fallback if no tab matches
    }
  };
  //#endregion





  //#region Processing update
  const getRequestURL = (Tab: string, bitSelectorID?: any): string => {
    let URLend = Tab != "PerMRates" ? Tab : `DPBitSizes/ID_${bitSelectorID}/DrillingCharges`;
    return `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/ID_${drillingProgramSelectorID}/${URLend}/`;
  };

  const validateFields = (newRow: GridRowModel) => {
    const validationErrors = [];
    if (optionsTabValue === "DPRigs") {
      if (newRow.ID_EA_Rig == null || newRow.ID_EA_Rig < 1) validationErrors.push("Rig cannot be empty.");
    }
    if (optionsTabValue === "DPConsumables") {
      if (newRow.ID_EA_Consumable == null || newRow.ID_EA_Consumable < 1) validationErrors.push("Consumable cannot be empty.");
      if (newRow.ID_EA_UnitOfMeasurement == null || newRow.ID_EA_UnitOfMeasurement < 1) validationErrors.push("Unit of Measurement cannot be empty.");
      if (newRow.Charge == null) validationErrors.push("Charge cannot be empty.");
    }
    if (optionsTabValue === "DPSites") {
      if (newRow.ID_EA_Site == null || newRow.ID_EA_Site < 1) validationErrors.push("Site cannot be empty.");
    }
    if (optionsTabValue === "DPPersonnels") {
      if (newRow.ID_EA_Personnel == null || newRow.ID_EA_Personnel < 1) validationErrors.push("Personnel cannot be empty.");
      if (newRow.ID_EA_PersonnelType == null || newRow.ID_EA_PersonnelType < 1) validationErrors.push("Personnel Type cannot be empty.");
    }
    if (optionsTabValue === "DPTasks") {
      if (newRow.ID_EA_Task == null || newRow.ID_EA_Task < 1) validationErrors.push("Task cannot be empty.");
      if (newRow.ID_EDP_Task_ChargeType == null || newRow.ID_EDP_Task_ChargeType < 1) validationErrors.push("Charge Rate cannot be empty.");
    }
    if (optionsTabValue === "DPSites") {
      if (newRow.ID_EA_Site == null || newRow.ID_EA_Site < 1) validationErrors.push("Site cannot be empty.");
    }
    if (optionsTabValue === "DPBitSizes") {
      if (newRow.ID_EA_BitSize == null || newRow.ID_EA_BitSize < 1) validationErrors.push("Bit Size cannot be empty.");
    }
    if (optionsTabValue === "TaskChargeTypes") {
      if (!newRow.ChargeType?.trim()) validationErrors.push("Charge Type cannot be empty.");
      if (newRow.Rate == null) validationErrors.push("Rate cannot be empty.");
      if (newRow.ID_EA_UnitOfMeasurement == null || newRow.ID_EA_UnitOfMeasurement < 1) validationErrors.push("Unit of Measurement cannot be empty.");
    }
    if (optionsTabValue === "MonthlyCharges") {
      if (!newRow.Monthly_Charge_Name?.trim()) validationErrors.push("Monthly Charge Name cannot be empty.");
      if (newRow.Monthly_Charge == null) validationErrors.push("Monthly Charge cannot be empty.");
    }
    if (optionsTabValue === "OneOffCharges") {
      if (!newRow.One_Off_Charge_Name?.trim()) validationErrors.push("One Of Charge Name cannot be empty.");
      if (newRow.One_Off_Charge == null) validationErrors.push("One Of Charge cannot be empty.");
    }
    if (optionsTabValue === "ShiftCharges") {
      if (!newRow.Shift_Charge_Name?.trim()) validationErrors.push("Shift Charge Name cannot be empty.");
      if (newRow.Shift_Charge == null) validationErrors.push("Shift Charge cannot be empty.");
    }
    if (optionsTabValue === "PerMRates") {
      if (newRow.From_Meter == null) validationErrors.push("From cannot be empty.");
      if (newRow.To_Meter == null) validationErrors.push("To cannot be empty.");
      if (newRow.Charge_Per_Meter == null) validationErrors.push("Charge Per Meter cannot be empty.");
    }
    if (optionsTabValue === "DPObjectives") {
      if (newRow.ID_EA_Objective == null || newRow.ID_EA_Objective < 1) validationErrors.push("Objective cannot be empty.");
    }
    return validationErrors;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`${renderTabContent()} row has empty required fields.`, 'criticalerror', errors.join("\n"));
    }
    else {
      try {
        const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
        const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
        const { data } = await Api.post(
          `${getRequestURL(optionsTabValue, bitSizeId)}${endpoint}`,
          rawData
        );
        const updatedRow = {
          ...newRow,
          ID: data.ID ?? newRow.ID,
          isNew: false,
        };
        setDataRows((prev: any[]) =>
          prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
        );
        await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} ${renderTabContent()}.`, "success");
        return updatedRow;
      } catch (e) {
        handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} ${renderTabContent()}.`);
      }
    }
    return;
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the ${renderTabContent()}.`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `${getRequestURL(optionsTabValue, bitSizeId)}/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted ${renderTabContent()}.`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting ${renderTabContent()}.`);
      }
    };
  };

  //#endregion


  const fetchData = async (Tab: string, bitSizeID: string | null) => {
    setDataRows([]);
    setLoading(true);
    setDataGridError(null);
    if (Tab == "PerMRates" && bitSizeID == null) {
      fetchBitSize(drillingProgramSelectorID);
    }
    else {
      try {
        const { data } = await Api.post(getRequestURL(Tab, bitSizeID));
        setDataRows(data);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading ${renderTabContent()}, Please try again later.`);
      }
    }
    setLoading(false);
  };



  //#region COLUMNS FOR DATAGRID:

  let contractSetupColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field:
        optionsTabValue == "DPRigs"
          ? "ID_EA_Rig"
          : optionsTabValue == "DPSites"
            ? "ID_EA_Site"
            : optionsTabValue == "DPObjectives"
              ? "ID_EA_Objective"
              : "ID_EA_BitSize",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions:
        optionsTabValue == "DPRigs"
          ? rigOptions
          : optionsTabValue == "DPSites"
            ? siteOptions
            : optionsTabValue == "DPObjectives"
              ? objectiveOptions
              : bitSizeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  let contractCostColumns: GridColDef<any>[] = [
    {
      headerName:
        optionsTabValue == "TaskChargeTypes"
          ? "Charge Type"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly Charge Name" :
            optionsTabValue == "OneOffCharges"
              ? " One Off Charge Name"
              : "Shift Charge Name",
      field:
        optionsTabValue == "TaskChargeTypes"
          ? "ChargeType"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly_Charge_Name" :
            optionsTabValue == "OneOffCharges"
              ? "One_Off_Charge_Name"
              : "Shift_Charge_Name",
      flex: 1,
      editable: true,
    },
    {
      headerName:
        optionsTabValue == "TaskChargeTypes"
          ? "Rate"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly Charge" :
            optionsTabValue == "OneOffCharges"
              ? " One Off Charge"
              : "Shift Charge",
      field:
        optionsTabValue == "TaskChargeTypes"
          ? "Rate"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly_Charge" :
            optionsTabValue == "OneOffCharges"
              ? "One_Off_Charge"
              : "Shift_Charge",
      flex: 1,
      editable: true,
      type: "number",
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      }, // actually could maybe use for plod components
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  let shiftChargeColumns: GridColDef<any>[] = [
    {
      headerName: "Shift Charge Name",
      field: "Shift_Charge_Name",
      flex: 1,
      editable: true,
    },
    {
      headerName: "Shift Charge",
      field: "Shift_Charge",
      flex: 1,
      editable: true,
      type: "number",
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      },
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Day",
      field: "Day",
      flex: 1,
      editable: true,
      type: "boolean",
    },
    {
      headerName: "Night",
      field: "Night",
      flex: 1,
      editable: true,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  let PerMRateColumns: GridColDef<any>[] = [
    {
      headerName: "From",
      field: "From_Meter",
      flex: 1,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "To",
      field: "To_Meter",
      flex: 1,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Charge Per Meter",
      field: "Charge_Per_Meter",
      flex: 1,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  let personnelColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: "ID_EA_Personnel",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelOptions,
    },
    {
      headerName: "Type",
      field: "ID_EA_PersonnelType",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelTypeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  let consumableColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: "ID_EA_Consumable",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: consumableOptions,
    },
    {
      headerName: "Charge",
      field: "Charge",
      flex: 1,
      editable: true,
      type: "number",
      // Use valueFormatter if you want to format for other cases (e.g., export)
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      },
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Unit Of Measurement",
      field: "ID_EA_UnitOfMeasurement",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: unitOfMeasurementOptions,
    },
    {
      headerName: "Default Charge",
      field: "Default_Charge",
      flex: 1,
      editable: true,
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  let taskColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: "ID_EA_Task",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: taskOptions,
    },
    {
      headerName: "Charge Rate",
      field: "ID_EDP_Task_ChargeType",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: chargeTypeOptions,
    },
    {
      headerName: "Charge Rate Locked",
      field: "ChargeType_Locked",
      flex: 1,
      editable: true,
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Task Metric",
      field: "Task_Metric",
      flex: 1,
      editable: true,
      headerAlign: "left",
      align: "left",
      type: "singleSelect",
      valueOptions: taskTypeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  let taskChargeRateColumns: GridColDef<any>[] = [
    {
      headerName: "Charge Type",
      field: "ChargeType",
      flex: 1,
      editable: true,
    },
    {
      headerName: "Rate",
      field: "Rate",
      flex: 1,
      editable: true,
      type: "number",
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      }, // actually could maybe use for plod components
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Unit Of Measurement",
      field: "ID_EA_UnitOfMeasurement",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: unitOfMeasurementOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];

  //#endregion

  //#region For adding and updating drilling programs
  const [drillingProgramDialogOpen, setDrillingProgramDialogOpen] = React.useState(false);
  const [drillingProgramDialogName, setDrillingProgramDialogName] = React.useState('');
  const [drillingProgramDialogAdding, setDrillingProgramDialogAdding] = React.useState(false);

  const handleDrillingProgramDialogSubmit = async () => {
    setDrillingProgramDialogOpen(false);
    let url = `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/`;
    var raw: string = JSON.stringify({
      ID: drillingProgramSelectorID,
      DrillingProgramName: drillingProgramDialogName,
      TotalMeters: 0,
      ReviewMeters: 0,
    });
    if (drillingProgramDialogAdding) { // they are adding.
      url += "~AddItem";
    }
    else { // they are updating.
      url += "~UpdateItem";
    }
    await Api.post(url, raw)
      .then(({ data }) => {
        if (data.ID) {
          let updatedRow = { DrillingProgramName: drillingProgramDialogName, ID_EDP_DrillingProgram: data.ID };
          setDrillingProgramOptions((prevData: any) => {
            // Check if there's an existing row with the matching ID
            const existingRowIndex = prevData.findIndex((row: any) => row.ID_EDP_DrillingProgram === data.ID);
            if (existingRowIndex !== -1) {
              // If a match is found, update the existing row
              return prevData.map((row: any, index: number) =>
                index === existingRowIndex ? updatedRow : row
              );
            } else {
              // If no match, add updatedRow to the data
              return [...prevData, updatedRow];
            }
          });
          setDrillingProgramDialogOpen(false);
        } else {
          swal({
            title: "Error",
            text: "Error adding, please try again.",
            icon: "error",
            dangerMode: true,
          });
        }
      })
      .catch((e) => {
        swal({
          title: "Error",
          text: "Error adding, please try again.",
          icon: "error",
          dangerMode: true,
        });
      });
  };

  const handleDrillingProgramButtonClick = (ButtonAction: string) => {
    if (ButtonAction == "Update") {
      const ActiveDrillingProgram = drillingProgramOptions.find(
        (row: any) => row.ID_EDP_DrillingProgram.toString() == drillingProgramSelectorID
      );
      setDrillingProgramDialogName(ActiveDrillingProgram!.DrillingProgramName); // could probably do this more compact.
      setDrillingProgramDialogAdding(false);
    }
    else {
      setDrillingProgramDialogName(''); // could probably do this more compact.
      setDrillingProgramDialogAdding(true);
    }
    setDrillingProgramDialogOpen(true);
  };
  //#endregion


  return (
    <>
      {/* The Selectors */}
      <>
        <Grid item style={{ marginLeft: 20 }}>
          <MiningCompanySelect
            plodID={plodID}
            value={miningCompanySelectorID}
            onChange={handleMiningCompanyChange}
            miningCompanyOptions={miningCompanyOptions}
            setMiningCompanyOptions={setMiningCompanyOptions}
          />
        </Grid>
        <Grid container style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <Grid item style={{ marginLeft: 20 }}>
            <ContractSelect
              plodID={plodID}
              miningCompanySelectorID={miningCompanySelectorID}
              value={contractSelectorID}
              onChange={handleContractChange}
              contractOptions={contractOptions}
              setContractOptions={setContractOptions}
            />
          </Grid>
          <Grid item style={{ marginLeft: 20 }}>
            <DrillingProgramSelect
              plodID={plodID}
              miningCompanySelectorID={miningCompanySelectorID}
              contractSelectorID={contractSelectorID}
              value={drillingProgramSelectorID}
              onChange={handleDrillingProgramChange}
              drillingProgramOptions={drillingProgramOptions}
              setDrillingProgramOptions={setDrillingProgramOptions}
            />
          </Grid>
          <Grid item
            style={{ marginLeft: 5 }}
          >
            <Stack spacing={0} padding="5px">
              <FormHelperText>&nbsp;</FormHelperText>   {/* This is so dumb - but it atleast keeps the height the same */}
              <IconButton
                sx={{
                  border: '1px solid',
                  borderColor: '#cbcbcb',
                  height: "56px",
                  width: "56px",
                  borderRadius: 1,
                  display: 'flex', // Aligns icon vertically
                  alignItems: 'center', // Centers the icon vertically
                  justifyContent: 'center', // Centers the icon horizontally
                }}
                disabled={drillingProgramSelectorID === ''}
                color="primary"
                onClick={() => handleDrillingProgramButtonClick("Update")}
              >
                <EditIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item
            style={{ marginLeft: 5 }}
          >
            <Stack spacing={0} padding="5px">
              <FormHelperText>&nbsp;</FormHelperText>   {/* This is so dumb - but it atleast keeps the height the same */}
              <IconButton
                sx={{
                  border: '1px solid',
                  borderColor: '#cbcbcb',
                  height: "56px",
                  width: "56px",
                  borderRadius: 1,
                  display: 'flex', // Aligns icon vertically
                  alignItems: 'center', // Centers the icon vertically
                  justifyContent: 'center', // Centers the icon horizontally
                }}
                disabled={contractSelectorID === ""}
                color="primary"
                onClick={() => handleDrillingProgramButtonClick("Add")}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid >
      </>
      <Accordion style={{ marginLeft: 21, marginTop: 10, width: "640px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Contract Settings
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* First Row: Invoice Workflow and Plod Workflow */}
            <Grid item xs={6}>
              <Stack spacing={0}>
                <FormHelperText>Invoice Workflow</FormHelperText>
                <Select
                  id="InvoiceWorkflowSelector"
                  value={selectedContractData.ID_WF_Workflow_Invoice ?? ''}
                  onChange={(event) => handleFieldChange("ID_WF_Workflow_Invoice", event.target.value)}
                  disabled={contractSelectorID === '' || workflowOptions.length == 0}
                  style={{ width: '100%' }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {workflowOptions.length == 0 ? "No Workflows " : "Select a WorkFlow"}
                  </MenuItem>
                  {workflowOptions.map((data: any): JSX.Element => (
                    <MenuItem key={data["ID_WF_Workflow"]} value={data["ID_WF_Workflow"]}>
                      {data["WorkflowName"]}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={0}>
                <FormHelperText>Plod Workflow</FormHelperText>
                <Select
                  id="ClientSelector"
                  value={selectedContractData.ID_WF_Workflow_Plod ?? ''}
                  onChange={(event) => handleFieldChange("ID_WF_Workflow_Plod", event.target.value)}
                  disabled={contractSelectorID === '' || workflowOptions.length == 0}
                  style={{ width: '100%' }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {workflowOptions.length == 0 ? "No Workflows " : "Select a WorkFlow"}
                  </MenuItem>
                  {workflowOptions.map((data: any): JSX.Element => (
                    <MenuItem key={data["ID_WF_Workflow"]} value={data["ID_WF_Workflow"]}>
                      {data["WorkflowName"]}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>

            {/* Second Row: Start Date and End Date */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <Stack spacing={0}>
                  <FormHelperText>Start Date</FormHelperText>
                  <DatePicker
                    sx={{ width: '100%' }}
                    label=""
                    format="DD/MM/YYYY"
                    defaultValue={null}
                    disabled={contractSelectorID == ''}
                    value={selectedContractData.StartDate != null ? dayjs(selectedContractData.StartDate) : null}
                    onChange={(newValue: any) => handleFieldChange("StartDate", newValue)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={0}>
                  <FormHelperText>End Date</FormHelperText>
                  <DatePicker
                    sx={{ width: '100%' }}
                    label=""
                    format="DD/MM/YYYY"
                    defaultValue={null}
                    disabled={contractSelectorID === ''}
                    value={selectedContractData.EndDate != null ? dayjs(selectedContractData.EndDate) : null}
                    onChange={(newValue: any) => handleFieldChange("EndDate", newValue)}
                  />
                </Stack>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Button
            sx={{
              height: "28px",
              width: "250px",
            }}
            variant="outlined"
            disabled={contractSelectorID === ''}
            onClick={handleContractSettingsSubmit}
          >
            Save Changes
          </Button>
        </AccordionActions>
      </Accordion>
      <div style={{ marginLeft: 20, marginTop: 5 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={modeTabValue} onChange={handleModeTabChange}>
            <Tab disabled={drillingProgramSelectorID === ''} value="DrillingProgramSetup" label="Drilling Program Setup" />
            <Tab disabled={drillingProgramSelectorID === ''} value="DrillingProgramCost" label="Drilling Program Cost" />
          </Tabs>
        </Box>
      </div>

      <Box
        sx={{
          height: "60vh",
          margin: "10px",
        }}
      >
        {/* Option Selectors */}
        <Box sx={{ borderBottom: 1, marginTop: 2, borderColor: "divider" }}>
          {modeTabValue === "DrillingProgramSetup" ? (
            <Tabs
              style={{ marginLeft: 10, height: 20 }}
              value={drillingProgramSetupTabValue}
              onChange={handleDrillingProgramSetupTabChange}
            >
              <Tab disabled={drillingProgramSelectorID === ''} value="DPRigs" label="Rigs" />
              <Tab disabled={drillingProgramSelectorID === ''} value="DPConsumables" label="Consumables" />
              <Tab disabled={drillingProgramSelectorID === ''} value="DPSites" label="Sites" />
              <Tab disabled={drillingProgramSelectorID === ''} value="DPPersonnels" label="Operators" />
              <Tab disabled={drillingProgramSelectorID === ''} value="DPTasks" label="Tasks" />
              <Tab disabled={drillingProgramSelectorID === ''} value="DPBitSizes" label="Bit Sizes" />
              <Tab disabled={drillingProgramSelectorID === ''} value="DPObjectives" label="Objectives" />
            </Tabs>
          ) : (
            <Tabs
              style={{ marginLeft: 10, height: 20 }}
              value={drillingProgramCostTabValue}
              onChange={handleDrillingProgramCostTabChange}
            >
              <Tab disabled={drillingProgramSelectorID === ''} value="TaskChargeTypes" label="Task Charge Rates" />
              <Tab disabled={drillingProgramSelectorID === ''} value="MonthlyCharges" label="Monthly Charges" />
              <Tab disabled={drillingProgramSelectorID === ''} value="OneOffCharges" label="One Off Charges" />
              <Tab disabled={drillingProgramSelectorID === ''} value="ShiftCharges" label="Shift Charges" />
              <Tab disabled={drillingProgramSelectorID === ''} value="PerMRates" label="Per M Rates" />
            </Tabs>
          )}
        </Box>

        < DataGridPremium
          sx={{ zIndex: 0 }}
          columns={
            modeTabValue == "DrillingProgramCost"
              ? optionsTabValue == "PerMRates"
                ? PerMRateColumns
                : optionsTabValue == "TaskChargeTypes"
                  ? taskChargeRateColumns
                  : optionsTabValue == "ShiftCharges"
                    ? shiftChargeColumns
                    : contractCostColumns
              : optionsTabValue == "DPPersonnels"
                ? personnelColumns
                : optionsTabValue == "DPTasks"
                  ? taskColumns
                  : optionsTabValue == "DPConsumables"
                    ? consumableColumns
                    : contractSetupColumns
          }
          // rows={dataRows}
          rows={[...dataRows]} // this is getting the latest (via spreading which is interesting).
          pagination
          disableRowSelectionOnClick
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          // isCellEditable={(params) => params.row.isNew} //This makes it not editable unless its new.
          isCellEditable={(params) => {
            if (params.row.isNew) {
              return true;
            }
            else {
              switch (params.field) {
                case 'ID_EA_Rig':
                case 'ID_EA_Site':
                case 'ID_EA_Personnel':
                case 'ID_EA_BitSize':
                case 'ID_EA_Task':
                case 'ID_EA_Consumable':
                case 'ID_EA_Objective':
                  return false;
                default:
                  return true;
              }
            }
          }}
          getRowId={(row) => row.ID}
          editMode="row"
          loading={loading}
          slots={{
            toolbar: () => (
              <CustomToolBarWithDropDownToolbar
                setDataRows={setDataRows} // where setRigs updates the rig rows
                setDataRowModesModel={setDataRowModesModel} // for setting row modes
                rowTemplate={{}} // define initial fields for rigs
                fieldName="" // focus on RigName field
                itemName={renderTabContent()}
                disableItems={drillingProgramSelectorID === ''}
                displayDropDown={optionsTabValue === "PerMRates"}  // Show the dropdown
                dropDownOptions={bitSizeData}  // Provide the dropdown options
                dropDownSelectedID={bitSizeId}  // Current selected value
                handleDropDownChange={handleBitSizeSelectorChange}  // Correctly typed change handler
                dropdownDataName="Bit Size"  // Name to display in the label
              />),
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                message={dataGridError ? dataGridError : `No ${renderTabContent()} Data`}
                onRetry={dataGridError ? () => fetchData(optionsTabValue, bitSizeId) : undefined}
              />
            ),
          }}
        />
      </Box >
      <Dialog
        open={drillingProgramDialogOpen}
        onClose={() => setDrillingProgramDialogOpen(false)}
        maxWidth="sm" fullWidth>
        <DialogTitle>{drillingProgramDialogAdding ? "Add Drilling Program" : "Update Drilling Program"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="standard"
            margin="dense"
            autoFocus
            required
            fullWidth
            value={drillingProgramDialogName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDrillingProgramDialogName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDrillingProgramDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDrillingProgramDialogSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractsSetup;
