import { AlertService } from '../services/AlertService';
import axios from "axios";

// Define the APIErrorResponse interface
interface APIErrorResponse {
    Result?: string; // Indicates if the result was a success or failure
    Message?: string; // A general message returned from the API
    "APIException.RedOchre.Cloud"?: string; // A specific error message field
};
  
export const handleErrorResponse = async (
  error: unknown,
  errorTitle: string = "Error",
  fallbackErrorMessage: string = "An unknown error has occurred. Please try again."
  ): Promise<void> => {
    console.error("Error details:", error);
    let errorMessage = fallbackErrorMessage;
    if (axios.isAxiosError(error) && error.response) {
      const response = error.response.data as APIErrorResponse;
      if (response?.Result === "Failed" && response["APIException.RedOchre.Cloud"]) {
        errorMessage = response["APIException.RedOchre.Cloud"];
      }
    }
    await AlertService.showAlert(errorTitle, "criticalerror", errorMessage);
};
