import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import EditIcon from "@mui/icons-material/Edit";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Api } from "../api";
import { handleErrorResponse } from "../api";
import getInstanceID from "../functions/getInstanceID";
import {
  RichTreeView,
  TreeViewBaseItem,
  useTreeViewApiRef,
} from "@mui/x-tree-view";

const Teams: React.FunctionComponent = () => {
  let InstanceID: string = getInstanceID();

  const [selectedTab, setSelectedTab] = React.useState<string>("teams");
  const [selectedRole, setSeletedRole] = React.useState(null);
  const [roleMenu, setRoleMenu] = React.useState([]);
  const [miningList, setMiningList] = React.useState([]);
  const [miningCardShow, setMiningCardShow] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isloading, setIsLoading] = React.useState(false);

  const [miningCard, setMiningCard] = React.useState(false);
  const [miningInactiveCard, setMiningInactiveCard] = React.useState(true);
  const [contractList, setContractList] = React.useState([]);

  const [rowID, setRowID] = React.useState("");

  const [roles, setRoles] = React.useState<any>([]);
  const [dropDownRole, setDropDownRoles] = React.useState<any>([]);
  const [selectedRowDataTeams, setSelectedRowDataTeams] =
    React.useState<any>(null);
  const [addmenuitems, setMenuItems] = React.useState([]);
  const [selectedTeamData, setSelectedTeamData] = React.useState<any>({});
  const [dataRowModesModel, setDataRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [dataRows, setDataRows] = React.useState<any>([]);
  const [oneUserData, setOneUserData] = React.useState<any>(null);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel);
  };

  // code for card
  const [dataPrivileges, setDataPrivileges] = React.useState("");
  const [updateID, setUpdateID] = React.useState("");

  const [showAllMiningCompaniesCard, setShowAllMiningCompaniesCard] =
    React.useState(false);
  const [showNoMiningCompaniesCard, setShowNoMiningCompaniesCard] =
    React.useState(false);

  const [selectedMiningCompanies, setSelectedMiningCompanies] =
    React.useState("");
  const [selectedMiningContractCompanies, setSelectedMiningContractCompanies] =
    React.useState("");

  const handleRadioChange = (event: any) => {
    const selectedValue = event.target.value;

    const selectValue =
      selectedValue == "selectedMiningCompanies" ? "SELECTION" : selectedValue;
    setDataPrivileges(selectedValue);
    // Set visibility of cards and select box based on the selected radio value
    setShowAllMiningCompaniesCard(selectedValue == "ALL");
    setShowNoMiningCompaniesCard(selectedValue == "NONE");
    getCompanyUpdate(rowID, updateID, selectValue);
    getMiningCardShow(rowID, updateID);
  };

  const handleCloseCard = () => {
    // Close the relevant card based on the current dataPrivileges value
    if (dataPrivileges === "ALL" || dataPrivileges === "NONE") {
      setShowAllMiningCompaniesCard(false);
    }
  };

  const handleMiningCloseCard = () => {
    setMiningCard(false);
  };

  const handleMiningInactiveCloseCard = () => {
    setMiningInactiveCard(false);
  };

  const handleSelectChange = (event: any) => {
    setSelectedMiningCompanies(event.target.value);
  };

  const handleSelectContractChange = (event: any) => {
    setSelectedMiningContractCompanies(event.target.value);
  };

  const getMenuList = async (rowId: any) => {
    if (rowId == undefined) {
      return null;
    } else if (rowId !== undefined || rowId !== "undefined") {
      try {
        setLoading(true);
        const response = await Api.post(
          `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${rowId}/~MenuItemsAvailable`
        );
        const getAllMenu = response?.data;

        setRoleMenu(getAllMenu);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleErrorResponse(error);
      }
    }

    return null;
  };

  const getCompanyAccess = async (rowId: any) => {
    setRowID(rowId);
    if (rowId == undefined) {
      return null;
    } else if (rowId !== undefined || rowId !== "undefined") {
      try {
        const response = await Api.post(
          `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${rowId}/CompanyAccess`
        );
        const getAllMenu = response?.data;
        const radioData = getAllMenu[0]?.MiningCompanyAccess;
        const radioID = getAllMenu[0]?.ID;

        const radioButton =
          radioData == "SELECTION" ? "selectedMiningCompanies" : radioData;

        setDataPrivileges(radioButton);
        setUpdateID(radioID);
        setShowAllMiningCompaniesCard(radioButton == "ALL");
        setShowNoMiningCompaniesCard(radioButton == "NONE");
        await getMiningCardShow(rowId, radioID);
      } catch (error) {
        handleErrorResponse(error);
      }
    }
    return null;
  };

  const getCompanyUpdate = async (
    rowID: any,
    updateID: any,
    selectedValue: any
  ) => {
    const data = {
      MiningCompanyAccess: selectedValue,
      ID: updateID,
    };

    try {
      const response = await Api.post(
        `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${rowID}/CompanyAccess/~UpdateItem`,
        data
      );
      const getAllMenu = response?.data;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getMiningList = async () => {
    try {
      const response = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys` // TODO: This probably should be cached
      );
      const getAllMining = response?.data;

      setMiningList(getAllMining);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getAddMiningList = async () => {
    if (selectedMiningCompanies !== "") {
      setIsLoading(true);
      const data = {
        ID_EA_MiningCompany: selectedMiningCompanies,
      };
      try {
        const response = await Api.post(
          `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${rowID}/CompanyAccess/ID_${updateID}/MiningAccess/~AddItem`,
          data
        );
        const getADDMiningList = response?.data;
        setIsLoading(false);
        getMiningCardShow(rowID, updateID);
      } catch (error) {
        setIsLoading(false);
        handleErrorResponse(error);
      }
    }
  };

  const getMiningCardShow = async (rowID: any, updateId: any) => {
    try {
      const response = await Api.post(
        `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${rowID}/CompanyAccess/ID_${updateId}/MiningAccess`
      );
      const getAllMiningcard = response?.data;

      setMiningCardShow(getAllMiningcard);
      setMiningCard(true);
      setMiningInactiveCard(true);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getContractList = async (contractID: any) => {
    try {
      const response = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${contractID}/Contracts`
      );
      const getAllContract = response?.data;

      setContractList(getAllContract);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getTeamsList = async () => {
    const graphqlQuery = `
          {
            TeamMembers() {
              firstname
              lastname
              owner
              ID
              Roles() {
                ID
                RoleName
              }
            }
          }
        `;

    try {
      setLoading(true);
      setDataRows([]);
      const response = await Api.post(
        `/api/Plods/ID_${InstanceID}/TeamMembers/~GraphQL`,
        graphqlQuery
      );
      const getAllData = response.data.data.TeamMembers;
      const newRows = getAllData.map((i: any) => {
        return {
          ...i,
          RoleId: i.Roles?.[0]?.ID || "",
        };
      });
      setDataRows(newRows);
    } catch (error) {
      setDataRows([]);
      handleErrorResponse(error);
    }
    setLoading(false);
  };

  const addLinkToRole = async (selectedRoleId: any, rowIdSend: any) => {
    const data = {
      Link_Value: { ID: selectedRoleId },
    };
    try {
      const response = await Api.post(
        `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${rowIdSend}/Roles/~AddLinkTo`,
        data
      );
      if (response.status == 200) {
        getTeamsList();
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  // Call the function

  const getRoles = async () => {
    await Api.post(`/api/Plods/ID_${InstanceID}/Roles`)
      .then(({ data }) => {
        try {
          setLoading(true);
          setDataRows([]);
          setRoles(data);
        } catch {
          setRoles([]);
        }
      })
      .catch((e) => {
        setRoles([]);
        handleErrorResponse(e);
      });
    setLoading(false);
  };

  const getMenuItems = async () => {
    try {
      const response = await Api.post(
        `/api/Plods/ID_${InstanceID}/Roles/ID_${selectedRole}/MenuItem`
      );
      setMenuItems(response.data);
    } catch (error) {
      console.error("Error fetching menu items:", error);
      setMenuItems([]); // Set an empty array or handle error state accordingly
    }
  };

  useEffect(() => {
    if (selectedRole) {
      getMenuItems();
    }
  }, [selectedRole]);

  useEffect(() => {
    getTeamsList();
    getRoles();
  }, []);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params: any,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      if (!newRow.RoleName) {
        Swal.fire({
          icon: "warning",
          title: "Role name is required.",
        });
        return;
      }
      if (newRow.isNew) {
        const raw = JSON.stringify({
          RoleName: newRow.RoleName,
        });

        const { data } = await Api.post(
          `/api/Plods/ID_${InstanceID}/Roles/~AddItem`,
          raw
        );

        if (data.ID) {
          const updatedRow = { ...newRow, isNew: false, ID: data.ID };
          setRoles(
            roles.map((row: any) =>
              row.ID === newRow.ID ? { ...updatedRow } : row
            )
          );
          return updatedRow;
        } else {
          swal({
            title: "Error",
            text: `Failed adding data!`,
            icon: "error",
            dangerMode: true,
          });
          return {};
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const processTeamRowUpdate = async (newRow: GridRowModel) => {
    try {
      await addLinkToRole(newRow.RoleId, newRow.ID);
      const updatedRow = { ...newRow, isNew: false, ID: newRow.ID };
      setDataRows(
        dataRows.map((row: any) =>
          row.ID === newRow.ID ? { ...updatedRow } : row
        )
      );
      return updatedRow;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: "Do you want to Delete this row?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const raw = JSON.stringify({ ID: id });

        Api.post(`/api/Plods/ID_${InstanceID}/Roles/~DeleteItem`, raw)
          .then(({ data }: any) => {
            if (data.Result == "Deleted") {
              setRoles(roles.filter((row: any) => row.ID !== id));
            } else {
              Swal.fire(
                "Error deleting, make sure is not in use!",
                "",
                "error"
              );
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (selectedTab == "roles") {
      setDataRowModesModel({
        ...roles,
        [id]: { mode: GridRowModes.View },
      });
    } else {
      setDataRowModesModel({
        ...dataRows,
        [id]: { mode: GridRowModes.View },
      });
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    if (selectedTab == "roles") {
      setDataRowModesModel({
        ...roles,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow: any = roles.find((row: any) => row.ID === id);
      if (editedRow!.isNew) {
        setRoles(roles.filter((row: any) => row.ID !== id));
      }
    } else {
      setDataRowModesModel({
        ...dataRows,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow: any = dataRows.find((row: any) => row.ID === id);
      if (editedRow!.isNew) {
        setDataRows(dataRows.filter((row: any) => row.ID !== id));
      }
    }
  };
  interface EditToolbarProps {
    setData: (setData: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setDataRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  const handleEditClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  let TeamsColunms: GridColDef<any>[] = [
    {
      headerName: "First Name",
      field: "firstname",
      flex: 1,
      editable: false,
      type: "string",
    },
    {
      headerName: "Last Name",
      field: "lastname",
      flex: 1,
      editable: false,
      type: "string",
    },
    {
      headerName: "Role",
      field: "RoleId",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: roles.map((role: any) => ({
        value: role.ID,
        label: role.RoleName,
      })),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let RolesColunms: GridColDef<any>[] = [
    {
      headerName: "ID",
      field: "ID",
      flex: 1,
      editable: false,
      type: "string",
    },
    {
      headerName: "Role",
      field: "RoleName",
      flex: 1,
      editable: true,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let MenuColumns: GridColDef<any>[] = [
    {
      headerName: "ID",
      field: "ID",
      align: "left",
      headerAlign: "left",
      flex: 0.08,
      editable: false,
      type: "number",
    },
    {
      headerName: "Access",
      field: "Active",
      flex: 0.1,
      editable: false,
      type: "string",
      renderCell: (params) => {
        const [checked, setChecked] = React.useState(params.row.Active);
        const handleCheckboxChange = async (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          const newChecked = event.target.checked;
          setChecked(newChecked);

          try {
            // Make an API call to update the item status
            const response = await Api.post(
              `/api/Plods/ID_${InstanceID}/Roles/ID_${selectedRole}/MenuItem/~UpdateItem`,
              {
                Menu: params.row.Menu,
                ID: params.row.ID,
                Active: newChecked,
              }
            );
          } catch (error) {
            console.error("Error updating item status:", error);
            // Handle error
          }
        };
        useEffect(() => {
          setChecked(params.row.Active);
        }, [params.row.Active]);

        return (
          <Checkbox
            checked={checked}
            value={params.row.Active}
            onChange={handleCheckboxChange}
            inputProps={{ "aria-label": "select checkbox" }}
          />
        );
      },
    },
    {
      headerName: "Menu",
      field: "Menu",
      flex: 0.8,
      editable: false,
      type: "string",
    },
  ];

  function CustomToolbar({
    setDataRows,
    setDataRowModesModel,
  }: {
    setDataRows: React.Dispatch<React.SetStateAction<any[]>>;
    setDataRowModesModel: React.Dispatch<
      React.SetStateAction<GridRowModesModel>
    >;
  }) {
    const handleAddRoleClick = () => {
      const ID = randomId();
      setDataRows((oldRows: any) => [...oldRows, { ID, isNew: true }]);
      setDataRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: "RoleName" },
      }));
    };

    return (
      <>
        <GridToolbarContainer
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ borderBottom: 1, marginTop: 2, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={(e: any, value) => {
                setSelectedTab(value);
                setSelectedRowDataTeams(null);
                setSeletedRole(null);
              }}
            >
              <Tab value="teams" label="Team" />
              <Tab value="roles" label="Roles" />
            </Tabs>
          </Box>

          {selectedTab === "roles" && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddRoleClick}
            >
              Add Role
            </Button>
          )}
        </GridToolbarContainer>
        <hr />
      </>
    );
  }

  const UserRoleBox = ({
    oneUserData,
    dropDownRole,
    loading,
    roleMenu,
  }: any) => {
    return (
      oneUserData && (
        <Box
          sx={{
            border: 1,
            borderColor: "#DEDEDE",
            borderRadius: 2,
            padding: 2,
            marginTop: 2,
            width: "50%",
            marginLeft: 1.5,
          }}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Typography variant="body1">
              <strong>{oneUserData?.firstname}</strong> has the role of{" "}
              <strong>{oneUserData?.Roles?.[0]?.RoleName}</strong>{" "}
              {dropDownRole} <br />
              The <strong>{oneUserData?.Roles?.[0]?.RoleName}</strong> role
              grants the following menu access:
              <br />
              <br />
              {roleMenu
                ?.filter((item: any) => item?.Active)
                .map((item: any, index: number) => (
                  <span key={index}>
                    {item?.Menu}
                    {","}
                  </span>
                ))}
            </Typography>
          )}
        </Box>
      )
    );
  };

  const DataPrivilegesBox: React.FC<any> = ({
    dataPrivileges,
    handleRadioChange,
    showAllMiningCompaniesCard,
    showNoMiningCompaniesCard,
    handleCloseCard,
    oneUserData,
    selectedMiningCompanies,
    handleSelectChange,
    miningList,
    isLoading,
    getAddMiningList,
  }) => {
    return (
      oneUserData && (
        <Box
          sx={{
            borderRadius: 2,
            border: "none",
            padding: 2,
            marginTop: 2,
            width: "50%",
          }}
        >
          <FormControl>
            <FormLabel>Data Privileges</FormLabel>
            <RadioGroup
              aria-label="Data Privileges"
              name="dataPrivileges"
              value={dataPrivileges}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="ALL"
                control={<Radio />}
                label="All Mining Companies"
              />
              <FormControlLabel
                value="NONE"
                control={<Radio />}
                label="No Mining Companies"
              />
              <FormControlLabel
                value="selectedMiningCompanies"
                control={<Radio />}
                label="Selected Mining Companies"
              />
            </RadioGroup>
          </FormControl>

          <Card
            variant="outlined"
            sx={{
              display: showAllMiningCompaniesCard ? "block" : "none",
              mt: 2,
              width: "40%",
            }}
          >
            <CardContent sx={{ position: "relative" }}>
              <Typography variant="body1">
                <strong>Gold card</strong>
                <br />
                <br />
                All Mining Companies, All Contracts, All Data
                <br />
                <br />
                <strong>{oneUserData?.firstname}</strong> has access to all data
                in all Mining Companies and all Contracts, Drilling Programs,
                and Rigs.
              </Typography>
            </CardContent>
          </Card>

          <Card
            variant="outlined"
            sx={{
              display: showNoMiningCompaniesCard ? "block" : "none",
              mt: 2,
              width: "40%",
            }}
          >
            <CardContent sx={{ position: "relative" }}>
              <Typography variant="body1">
                <strong>Access Revoked</strong>
                <br />
                <br />
                No Mining Company Access
                <br />
                <br />
                <strong>{oneUserData?.firstname}</strong> cannot access
                <br /> any mining company information.
                <br />
                <br />
                This team member may have access to add or delete other data
                pending on the menu items they have access to.
              </Typography>
            </CardContent>
          </Card>

          {dataPrivileges === "selectedMiningCompanies" && (
            <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
              <FormControl sx={{ m: 1, minWidth: 220 }}>
                <InputLabel id="select-mining-company-label">
                  Select Mining Company
                </InputLabel>
                <Select
                  value={selectedMiningCompanies}
                  labelId="select-mining-company-label"
                  id="select-mining-company"
                  autoWidth
                  label="Choose one…"
                  onChange={handleSelectChange}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        minWidth: 220, // Ensure the dropdown width matches the select width
                      },
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    <em>None</em>
                  </MenuItem>
                  {miningList?.map((item: any) => (
                    <MenuItem
                      key={item.ID_EA_MiningCompany}
                      value={item.ID_EA_MiningCompany}
                      sx={{ minWidth: 220 }}
                    >
                      {item.MiningCompanyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputLabel sx={{ fontSize: 35 }} onClick={getAddMiningList}>
                {isLoading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  "+"
                )}
              </InputLabel>
            </Box>
          )}
        </Box>
      )
    );
  };

  const MiningCard: React.FC<any> = ({
    selectedTab,
    selectedRowDataTeams,
    dataPrivileges,
    miningCard,
    miningCardShow,
    selectedMiningContractCompanies,
    handleSelectContractChange,
    contractList,
    oneUserData,
    handleCloseCard,
    fetchContracts,
    isLoading,
  }) => {
    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
    const toggledItemRef = React.useRef<{ [itemId: string]: boolean }>({});
    const apiRef: any = useTreeViewApiRef();

    const updateMiningAccess = async (payload: any) => {
      try {
        const response = await Api.post(
          `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${oneUserData.ID}/CompanyAccess/ID_${updateID}/MiningAccess/~UpdateItem`,
          payload
        );
        console.log("API Response:", response.data);
        return response.data;
      } catch (error) { }
    };

    const handleItemSelectionToggle = (
      event: React.SyntheticEvent,
      itemId: string,
      isSelected: boolean,
      miniCompanyId: any
    ) => {
      toggledItemRef.current[itemId] = isSelected;
    };

    function getItemDescendantsIds(item: TreeViewBaseItem) {
      const ids: string[] = [];
      item.children?.forEach((child) => {
        ids.push(child.id);
        ids.push(...getItemDescendantsIds(child));
      });

      return ids;
    }

    const handleSelectedItemsChange = (
      event: React.SyntheticEvent,
      newSelectedItems: string[],
      miniCompanyId: any
    ) => {
      setSelectedItems(newSelectedItems);

      // Select / unselect the children of the toggled item
      const itemsToSelect: string[] = [];
      const itemsToUnSelect: { [itemId: string]: boolean } = {};
      Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
        const item = apiRef.current!.getItem(itemId);
        if (isSelected) {
          itemsToSelect.push(...getItemDescendantsIds(item));
          updateMiningAccess({ ID: miniCompanyId, AllContracts: true });
        } else {
          getItemDescendantsIds(item).forEach((descendantId) => {
            itemsToUnSelect[descendantId] = true;
          });
          updateMiningAccess({ ID: miniCompanyId, AllContracts: false });
        }
      });

      const newSelectedItemsWithChildren = Array.from(
        new Set(
          [...newSelectedItems, ...itemsToSelect].filter(
            (itemId) => !itemsToUnSelect[itemId]
          )
        )
      );

      setSelectedItems(newSelectedItemsWithChildren);

      toggledItemRef.current = {};
    };

    const handleMiningDeleteCard = async (
      deleteId: string,
      rowID: any,
      updateId: any
    ) => {
      try {
        const response = await Api.post(
          `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${rowID}/CompanyAccess/ID_${updateId}/MiningAccess/~DeleteItem`,
          { ID: deleteId }
        );
        setMiningCard(false);
        setMiningInactiveCard(false);
        getMiningCardShow(rowID, updateId);
      } catch (error) {
        handleErrorResponse(error);
      }
    };

    return (
      oneUserData && (
        <Paper sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          {selectedTab === "teams" &&
            selectedRowDataTeams &&
            dataPrivileges === "selectedMiningCompanies" &&
            miningCard &&
            miningCardShow &&
            miningCardShow.map((item: any, index: number) =>
              item.AllContracts ? (
                <Card
                  variant="outlined"
                  sx={{
                    mt: 2,
                    ml: 2,
                    mb: 2,
                    width: { xs: "100%", sm: "calc(33.33% - 16px)" },
                  }}
                  key={index}
                >
                  <CardContent sx={{ position: "relative" }}>
                    <Typography variant="body1">
                      <strong>{item.MiningCompanyName}</strong>
                      <br />
                      <Box
                        sx={{ mt: 2, display: "flex", alignItems: "center" }}
                      >
                        <FormControl
                          sx={{ m: 1, width: "100%", maxWidth: 250 }}
                        >
                          {isLoading ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress size={24} />
                            </Box>
                          ) : (
                            <RichTreeView
                              multiSelect
                              checkboxSelection={true}
                              apiRef={apiRef}
                              selectedItems={selectedItems}
                              onSelectedItemsChange={(e, itemIds) =>
                                handleSelectedItemsChange(e, itemIds, item.ID)
                              }
                              onItemSelectionToggle={(e, itemsId, isSelected) =>
                                handleItemSelectionToggle(
                                  e,
                                  itemsId,
                                  isSelected,
                                  item.ID
                                )
                              }
                              onClick={() =>
                                contractList?.length > 0
                                  ? null
                                  : fetchContracts(item.ID)
                              }
                              items={[
                                {
                                  id: "all",
                                  label: "All Contracts",
                                  children: contractList.map(
                                    (contract: any) => ({
                                      id: contract.ContractName,
                                      label: contract.ContractName,
                                    })
                                  ),
                                },
                              ]}
                            />
                          )}
                        </FormControl>
                      </Box>
                    </Typography>
                    <CloseIcon
                      onClick={() =>
                        handleMiningDeleteCard(item.ID, rowID, updateID)
                      }
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </CardContent>
                </Card>
              ) : null
            )}
        </Paper>
      )
    );
  };

  //#endregion

  return (
    <>
      {/* The Data Grid */}
      <Box
        sx={{
          height: "60vh",
          margin: "10px",
          border: 1,
          borderColor: "#DEDEDE",
          borderRadius: 2,
        }}
      >
        {selectedTab == "teams" ? (
          <DataGridPremium
            rows={dataRows}
            columns={TeamsColunms}
            getRowId={(row) => row.id || row.ID || row}
            editMode="row"
            rowModesModel={dataRowModesModel}
            onRowSelectionModelChange={async (row: any) => {
              const teamMemberId = row?.[0];
              const teamMember = dataRows?.find((r: any) => r.ID == row?.[0]);
              if (teamMember == oneUserData) {
                return;
              }
              setOneUserData(teamMember);
              setSelectedRowDataTeams([teamMember]);
              setMiningInactiveCard(false);
              await Promise.all([
                getMenuList(teamMemberId),
                getCompanyAccess(teamMemberId),
                getMiningList(),
              ]);
            }}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processTeamRowUpdate}
            loading={loading}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  setDataRows={setDataRows}
                  setDataRowModesModel={setDataRowModesModel}
                />
              ),
            }}
          />
        ) : (
          <DataGridPremium
            sx={{ zIndex: 0 }}
            columns={RolesColunms}
            rows={roles}
            onRowSelectionModelChange={(row: any) => {
              setSeletedRole(row?.[0]);
            }}
            rowModesModel={dataRowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            isCellEditable={(params) => params.row.isNew} //This makes it not editable unless its new.
            getRowId={(row) => row.id || row.ID || row}
            editMode="cell"
            loading={loading}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  setDataRows={selectedTab == "teams" ? setDataRows : setRoles}
                  setDataRowModesModel={setDataRowModesModel}
                />
              ),
            }}
            columnVisibilityModel={{
              ID: false,
            }}
          />
        )}
      </Box>

      {selectedTab === "teams" && selectedRowDataTeams && (
        <>
          <UserRoleBox
            oneUserData={oneUserData}
            dropDownRole={dropDownRole}
            loading={loading}
            roleMenu={roleMenu}
          />

          <DataPrivilegesBox
            dataPrivileges={dataPrivileges}
            handleRadioChange={handleRadioChange}
            showAllMiningCompaniesCard={showAllMiningCompaniesCard}
            showNoMiningCompaniesCard={showNoMiningCompaniesCard}
            handleCloseCard={handleCloseCard}
            oneUserData={oneUserData}
            selectedMiningCompanies={selectedMiningCompanies}
            handleSelectChange={handleSelectChange}
            miningList={miningList}
            isLoading={isloading}
            getAddMiningList={getAddMiningList}
          />
        </>
      )}

      {/* Menu Item Tables */}
      {selectedRole && (
        <Box
          sx={{
            height: "60vh",
            margin: "10px",
          }}
        >
          <DataGridPremium
            sx={{ zIndex: 0 }}
            columns={MenuColumns}
            rows={[...addmenuitems]} // Use the menuItems state for rows
            getRowId={(row) => row.ID}
            loading={loading}
            columnVisibilityModel={{
              ID: false,
            }}
          />
        </Box>
      )}

      {/*Mining Company Card for Active True */}
      <MiningCard
        selectedTab={selectedTab}
        selectedRowDataTeams={selectedRowDataTeams}
        dataPrivileges={dataPrivileges}
        miningCard={miningCard}
        miningCardShow={miningCardShow}
        selectedMiningContractCompanies={selectedMiningContractCompanies}
        handleSelectContractChange={handleSelectContractChange}
        contractList={contractList}
        oneUserData={oneUserData}
        handleMiningCloseCard={handleMiningCloseCard}
        fetchContracts={getContractList}
        isLoading={isloading}
      />

      {/*Mining Company Card for Active False */}

      <Paper sx={{ display: "flex" }}>
        {selectedTab === "teams" &&
          selectedRowDataTeams &&
          dataPrivileges === "selectedMiningCompanies" &&
          miningInactiveCard
          ? miningCardShow.map(
            (item: any, index: any) =>
              item?.AllContracts == false && (
                <Card
                  variant="outlined"
                  sx={{
                    mt: 2,
                    ml: 2,
                    mb: 2,
                    width: "20%",
                  }}
                  key={index}
                >
                  <CardContent sx={{ position: "relative" }}>
                    <Typography variant="body1">
                      <strong>Slumberger</strong>
                      <br />
                      <Box
                        sx={{ mt: 2, display: "flex", alignItems: "center" }}
                      >
                        <FormControl sx={{ m: 1, minWidth: 270 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            Contract ABC
                          </InputLabel>
                          <Select
                            value={selectedMiningContractCompanies}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            autoWidth
                            label="Choose one…"
                            onChange={handleSelectContractChange}
                          >
                            {" "}
                            <MenuItem
                              value="contract1"
                              sx={{ minWidth: 270 }}
                              disabled
                            >
                              Contract ABC
                            </MenuItem>
                          </Select>

                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Contract ABC"
                              />
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ ml: 2 }}
                                  control={<Checkbox />}
                                  label="All DP, All rigs"
                                />
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ ml: 4 }}
                                    control={<Checkbox />}
                                    label="Drilling Program 1"
                                  />
                                  <FormGroup>
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="All Rigs"
                                    />
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="Rig 1"
                                    />
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="Rig 2"
                                    />
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="Rig 3"
                                    />
                                  </FormGroup>
                                </FormGroup>
                              </FormGroup>
                            </FormGroup>
                          </FormControl>
                        </FormControl>
                      </Box>
                    </Typography>
                    <CloseIcon
                      onClick={handleMiningInactiveCloseCard}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </CardContent>
                </Card>
              )
          )
          : ""}
      </Paper>
    </>
  );
};

export default Teams;
