import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormHelperText, Stack, SelectChangeEvent } from "@mui/material";
import { Api, handleErrorResponse } from "../api";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";

interface DrillingProgramSelectProps {
  plodID: string;
  miningCompanySelectorID: string;
  contractSelectorID: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string | null;
  disabled?: boolean;
  width?: string;
  showAllField?: boolean;
  drillingProgramOptions: IDrillingProgram[];
  setDrillingProgramOptions: React.Dispatch<React.SetStateAction<IDrillingProgram[]>>;
}

const DrillingProgramSelect: React.FC<DrillingProgramSelectProps> = ({
  plodID,
  miningCompanySelectorID,
  contractSelectorID,
  value,
  onChange,
  errorMessage,
  disabled,
  width = "250px",
  showAllField = false,
  drillingProgramOptions,
  setDrillingProgramOptions
}) => {

  const fetchDrillingProgramData = async () => {
    if (contractSelectorID === "-1") {
      setDrillingProgramOptions([]);
      return;
    }
    Api.post(
      `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms`
    )
      .then(({ data }) => {
        setDrillingProgramOptions(data);
      })
      .catch((e) => {
        console.error("Error fetching Drilling Programs", e);
      });
  };

  useEffect(() => {
    if (contractSelectorID) {
      fetchDrillingProgramData();
    }
  }, [contractSelectorID, plodID, miningCompanySelectorID]);

  return (
    <Stack spacing={0} padding="5px" paddingRight='10px'>
      <FormHelperText>Drilling Program</FormHelperText>
      <Select
        value={value}
        onChange={(event: SelectChangeEvent<string>) => onChange(event.target.value)}
        disabled={disabled || (drillingProgramOptions.length === 0 && !contractSelectorID) || errorMessage != null}
        style={{ width }}
        displayEmpty
      >
        <MenuItem value="" key="Unselected" disabled>
          {drillingProgramOptions.length === 0 && !contractSelectorID
            ? "No Drilling Programs"
            : "Select a Drilling Program"}
        </MenuItem>
        {showAllField && (
          <MenuItem key="All" value="-1">
            {"All"}
          </MenuItem>
        )}
        {drillingProgramOptions.map((data: any) => (
          <MenuItem key={data["ID_EDP_DrillingProgram"]} value={data["ID_EDP_DrillingProgram"]}>
            {data["DrillingProgramName"]}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default DrillingProgramSelect;
