import { Box, Stack } from "@mui/material";
import React from "react";

export interface NewPlodBoxProps extends React.HTMLProps<HTMLDivElement> {
  color: string;
  image?: string;
  text: string;
  height?: string;
}

const NewPlodBox: React.FC<NewPlodBoxProps> = (props: NewPlodBoxProps) => {
  return (
    <>
      <Box
        sx={{
          height: "auto",
          margin: "10px",
        }}
      >
        {/* This is the top bar. (Houses the name and image.) */}
        <div
          style={{
            backgroundColor: `${props.color}`,
            height: 34,
            color: "rgb(255, 255, 255)",
            borderRadius: 5,
            display: "flex",
            lineHeight: "34px",
            marginBottom: 10,
            margin: 2,
          }}
        >
          {/* <img
            style={{ marginLeft: 2, height: 34, verticalAlign: "center" }}
            src={props.image}
          /> */}
          <b style={{ marginLeft: 10 }}>{props.text}</b>
        </div>

        {props.children}
      </Box>
    </>
  );
};

export default NewPlodBox;
