import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface plodData {
  InstanceID: number,
  InstanceName: string,
}
const initialState: plodData = {
  InstanceID: 0,
  InstanceName: ""
};

export const plodData = createSlice({
  name: 'plodData',
  initialState,
  reducers: {
    stateData: (state, action) => {
      state.InstanceID = action.payload.ID;
      state.InstanceName = action.payload.Plod_App_DrillingCompanyName;
    }
  },
});

// Action creators are generated for each case reducer function
export const { stateData } = plodData.actions;

export default plodData.reducer;