//#region Imports!
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import PlodDetails from "./../components/PlodDetailsComponent";
import getInstanceID from "../functions/getInstanceID";
import MiningCompanySelect from "../components/MiningCompanySelect";
import ContractSelect from "../components/ContractSelect";
import DrillingProgramSelect from "../components/DrillingProgramSelect";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";
import { IContract } from "../Interfaces/Contract.interface";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";

//#endregion



const NewPlod: React.FunctionComponent = () => {
  const plodID = getInstanceID();

  //#region Filters.
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");
  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID(""); // Reset drilling program selection
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    setDrillingProgramSelectorID(""); // Reset drilling program selection
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row', // Arrange items horizontally
        alignItems: 'center', // Align items vertically centered
        gap: 2,               // Space between items
        marginTop: 2,
        marginLeft: '15px'
      }}>

        <MiningCompanySelect
          plodID={plodID}
          value={miningCompanySelectorID}
          onChange={handleMiningCompanyChange}
          errorMessage={errorMessage}
          miningCompanyOptions={miningCompanyOptions}
          setMiningCompanyOptions={setMiningCompanyOptions}
        />

        <ContractSelect
          plodID={plodID}
          miningCompanySelectorID={miningCompanySelectorID}
          value={contractSelectorID}
          onChange={handleContractChange}
          errorMessage={errorMessage}
          contractOptions={contractOptions}
          setContractOptions={setContractOptions}
        />
        <DrillingProgramSelect
          plodID={plodID}
          miningCompanySelectorID={miningCompanySelectorID}
          contractSelectorID={contractSelectorID}
          value={drillingProgramSelectorID}
          onChange={handleDrillingProgramChange}
          errorMessage={errorMessage}
          drillingProgramOptions={drillingProgramOptions}
          setDrillingProgramOptions={setDrillingProgramOptions}
        />

      </Box>


      {errorMessage != null ? (
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          <Typography variant="body1" color={"error"}>
            An error occured, Please reload the page or try again later.
          </Typography>
          <Typography variant="body1" color={"error"}>
            {errorMessage}
          </Typography>
        </Box>
      ) : (
        <PlodDetails
          selectorData={{
            save: true,
            plodID: plodID,
            drillingProgramID: drillingProgramSelectorID,
            contractID: contractSelectorID,
            miningCompanyID: miningCompanySelectorID,
            shiftID: null,
          }}
        />
      )
      }
    </>
  );
};

export default NewPlod;