import { IInstanceData } from "../Interfaces/InstanceData.interface";
import getInstanceData from "./getInstanceData";

function getInstanceID(): string {
  let InstanceInfo: IInstanceData = getInstanceData();
  if (InstanceInfo.InstanceInfo.ID == null) {
    window.location.replace(
      //?Should this have some functionality on the server ?? 
      "https://login.redochre.cloud/?reason=failedtogetinstanceid" // TODO: Look more into this 
    );
    throw new Error("Redirecting to login due to missing instance id.");
  }
  return InstanceInfo.InstanceInfo.ID.toString();
}

export default getInstanceID;

