
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Popover, SelectChangeEvent, Slider, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from '../components/TabPanel';
import getInstanceID from '../functions/getInstanceID';
import { DataGridPremium, GridActionsCellItem, GridRowModesModel, GridRowModes, GridRowModel, GridColDef, GridRowId, GridRenderCellParams } from '@mui/x-data-grid-premium';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';
import useStandardEditableRowActions from '../components/useStandardEditableRowActions';
import CustomToolBarWithDropDownToolbar from '../components/CustomToolbarWithDropdown';
import { AlertService } from '../services/AlertService';
import { Api, handleErrorResponse } from "../api";
import { ChromePicker, ColorResult, HuePicker, RGBColor, TwitterPicker } from 'react-color';
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { ColourPickerCell, ColourPickerEditCell } from "../components/ColourPickerCell";
const Configuration: React.FC = () => {
  let plodID: string = getInstanceID();
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});
  const [statusOptions, setStatusOptions] = useState([]);

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });
  const fetchOptionsData = async () => {
    setDropDownOptions([]);
    setStatusOptions([]);
    try {
      const body = {
        query: `{
                    Plods(ID_AP_Plod: ${plodID}) {
                        Workflows {
                            label:WorkflowName
                            value:ID_WF_Workflow
                        }
                        Statuses {
                            label:StatusName
                            value:ID_WF_Status
                        }
                    }
                }`
      };
      const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
      const { Workflows, Statuses } = data.data.Plods[0];
      setDropDownOptions(Workflows);
      setStatusOptions(Statuses);
    } catch (e) {
      setDropDownOptions([]);
      setStatusOptions([]);
      setDataGridError(`An error occured while loading Selector options, Please try again later.`);
    }
  };
  const getRequestURL = (TabValue: string): string => {
    let URLend = TabValue != "WorkflowConfig" ? TabValue : `Workflows/ID_${dropDownSelectedID.current}/WorkflowStatuses`;
    return `/api/Plods/ID_${plodID}/${URLend}/`;
  };
  useEffect(() => {
    fetchData(secondaryTabValue); //TODO: Surely there has to be a better way to do this
  }, []); // just fetches first data

  const fetchData = async (TabValue: string) => {
    setDataRows([]);
    setLoading(true);
    setDataGridError(null);
    if (TabValue == "WorkflowConfig" && dropDownSelectedID.current == null) {
      fetchOptionsData();
    }
    else {
      try {
        const { data } = await Api.post((getRequestURL(TabValue)));
        setDataRows(data);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading ${renderTabContent()}, Please try again later.`);
      }
    }
    setLoading(false);
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentItemName: string = await dataRows.filter((row) => row.ID === ID)[0][getTabNameField()];
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the ${renderTabContent()} "${currentItemName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `${getRequestURL(secondaryTabValue)}~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted ${renderTabContent()} "${currentItemName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting ${renderTabContent()} "${currentItemName}".`);
      }
    }
  };

  const validateFields = (newRow: GridRowModel) => {
    const validationErrors = [];
    if (secondaryTabValue === "Workflows") {
      if (!newRow.WorkflowName?.trim()) validationErrors.push("Workflow Name cannot be empty.");
    }
    if (secondaryTabValue === "Statuses") {
      if (!newRow.StatusName?.trim()) validationErrors.push("Status Name cannot be empty.");
    }
    if (secondaryTabValue === "WorkflowConfig") {
      if (newRow.StatusOrder == null) validationErrors.push("Status Order Number cannot be empty.");
      if (newRow.ID_WF_Status == null) validationErrors.push("Status cannot be empty.");
    }
    return validationErrors;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`${renderTabContent()} row has empty required fields.`, 'criticalerror', errors.join("\n"));
    }
    else {
      try {
        const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
        const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
        const { data } = await Api.post(
          `${getRequestURL(secondaryTabValue)}${endpoint}`,
          rawData
        );
        const updatedRow = {
          ...newRow,
          ID: data.ID ?? newRow.ID,
          isNew: false,
        };
        setDataRows((prev: any[]) =>
          prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
        );
        await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} ${renderTabContent()} "${newRow[getTabNameField()]}".`, "success");
        return updatedRow;
      } catch (e) {
        handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} ${renderTabContent()} "${newRow[getTabNameField()]}".`);
      }
    }
    return;
  };

  // State to manage the selected dropdown value
  const dropDownSelectedID = useRef<string | null>(null);

  // State for dropdown options
  const [dropDownOptions, setDropDownOptions] = useState([]);

  // Handle dropdown change with correct typing for SelectChangeEvent
  const handleDropDownChange = (event: SelectChangeEvent<string>) => {
    dropDownSelectedID.current = event.target.value;
    fetchData(secondaryTabValue);
  };

  const [secondaryTabValue, setSecondaryTabValue] = useState("Workflows");
  const handleSecondaryTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSecondaryTabValue(newValue);
    dropDownSelectedID.current = null;
    fetchData(newValue);
  };
  const renderTabContent = () => {
    switch (secondaryTabValue) {
      case 'Workflows':
        return 'Workflow';
      case 'Statuses':
      case 'WorkflowConfig':
        return 'Status';
      default:
        return 'Error'; // Default fallback if no tab matches
    }
  };
  const getTabNameField = () => {
    switch (secondaryTabValue) {
      case 'Workflows':
        return 'WorkflowName';
      case 'Statuses':
        return 'StatusName';
      default:
        return 'StatusOrder';
    }
  };

  //#region Columns Junk

  const workflowColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: 'WorkflowName',
      editable: true,
      minWidth: 200,
      flex: 4,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];


  const statusColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: 'StatusName',
      editable: true,
      minWidth: 200,
      flex: 4,
    },
    {
      field: 'Colour',
      headerName: 'Colour',
      renderCell: (params) => <ColourPickerCell {...params} />,
      renderEditCell: (params) => <ColourPickerEditCell {...params} />,
      width: 100,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];
  const workflowSetupColumns: GridColDef<any>[] = [
    {
      field: "ID_WF_Status",
      headerName: "Status",
      editable: true,
      flex: 0.3,
      type: "singleSelect",
      valueOptions: statusOptions,
    },
    {
      field: "StatusOrder",
      headerName: "Status Order Number",
      editable: true,
      flex: 0.3,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        return isInEditMode ? [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(ID)} color="inherit" />,
        ] : [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(ID)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
        ];
      },
    },
  ];

  //#endregion
  return (
    <>
      <Tabs
        style={{ marginLeft: 20, marginRight: 20 }}
        variant="fullWidth"
        value={0}
        // onChange={handleMainTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Config To Edit Selector"
      >
        <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value={0} label="WorkFlow Setup" />
        {/* <Tab sx={{ maxWidth: 175, height: 60 }}  disabled={loading} value={1} label="Instance Config" /> */}
      </Tabs>
      <TabPanel value={0} index={0}>
        <Box sx={{ height: "90vh", margin: "10px" }}>
          <Tabs
            style={{ marginLeft: 10, height: 20 }}
            variant="fullWidth"
            value={secondaryTabValue}
            onChange={handleSecondaryTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Config To Edit Selector"
          >
            <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value="Workflows" label="Workflows" />
            <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value="Statuses" label="Statuses" />
            <Tab sx={{ maxWidth: 175, height: 60 }} disabled={loading} value="WorkflowConfig" label="Workflow Config" />
          </Tabs>
          <DataGridPremium
            sx={{ borderTop: null }}
            columns={secondaryTabValue == "WorkflowConfig" ? workflowSetupColumns : secondaryTabValue == "Workflows" ? workflowColumns : statusColumns}
            rows={dataRows}
            rowModesModel={dataRowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            processRowUpdate={processRowUpdate}
            editMode="row"
            disableRowSelectionOnClick
            loading={loading}
            getRowId={(row) => row.ID} // Gets the id from database, not the local style one
            slots={{
              toolbar: () => (<CustomToolBarWithDropDownToolbar
                setDataRows={setDataRows} // where setRigs updates the rig rows
                setDataRowModesModel={setDataRowModesModel} // for setting row modes
                rowTemplate={{}} // define initial fields for rigs
                fieldName={getTabNameField()} // focus on RigName field
                itemName={renderTabContent()}
                disableItems={dataGridError != null || loading}
                displayDropDown={secondaryTabValue == "WorkflowConfig"}  // Show the dropdown
                dropDownOptions={dropDownOptions}  // Provide the dropdown options
                dropDownSelectedID={dropDownSelectedID.current}  // Current selected value
                handleDropDownChange={handleDropDownChange}  // Correctly typed change handler
                dropdownDataName="Workflows"  // Name to display in the label
              />),
              noRowsOverlay: () => (
                <CustomNoRowsOverlay
                  message={dataGridError ? dataGridError : `No ${renderTabContent()} Data`}
                  onRetry={undefined}
                />
              ),
            }}
          />
        </Box>
      </TabPanel>
    </>
  );
};

export default Configuration;

