import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IGenericDropDownOption } from '../Interfaces/GenericDropDownOption.interface';

export interface ICustomDialogBaseCellComponent {
  headerName: string;
  field: string;
  editable?: boolean;
  required?: boolean;
  type: 'string' | 'number' | 'date' | 'singleSelect' | 'actions';
}

export interface ICustomDialogSingleSelectComponent extends ICustomDialogBaseCellComponent {
  valueOptions: string[] | IGenericDropDownOption[];
}

export type ICustomDialogComponent =
  | ICustomDialogBaseCellComponent
  | ICustomDialogSingleSelectComponent;


const CustomDialog = ({
  open,
  onClose,
  options,
  data,
  onFieldChange,
  handleSubmit
}: {
  open: boolean;
  onClose: () => void;
  options: ICustomDialogComponent[];
  data: Record<string, any>; // The current state of the form data
  onFieldChange: (field: string, value: any) => void; // Change handler
  handleSubmit: () => void; // Submit handler
}) => {
  const [errors, setErrors] = React.useState<Record<string, boolean>>({});

  const handleChange = (field: string, value: any) => {
    onFieldChange(field, value); // Notify parent about field change
    setErrors((prev) => ({ ...prev, [field]: false })); // Clear error on change
  };

  const validate = (): boolean => {
    const newErrors: Record<string, boolean> = {};
    options.forEach((option) => {
      if (option.required && !data[option.field]) {
        newErrors[option.field] = true;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    if (validate()) {
      handleSubmit();
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Fields</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {options.map((option) => {
            const hasError = errors[option.field];
            const value = data[option.field] || '';
            switch (option.type) {
              case 'string':
              case 'number':
                return (
                  <TextField
                    key={option.field}
                    label={option.headerName}
                    type={option.type === 'number' ? 'number' : 'text'}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required={option.required}
                    error={hasError}
                    helperText={hasError ? `${option.headerName} is required` : ''}
                    value={value}
                    onChange={(e) =>
                      handleChange(option.field, e.target.value)
                    }
                  />
                );

              case 'date':
                return (
                  <LocalizationProvider
                    key={option.field}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      label={option.headerName}
                      value={value || null}
                      onChange={(newValue) =>
                        handleChange(option.field, newValue)
                      }
                      slotProps={{
                        textField: {
                          required: option.required,
                          error: hasError,
                          helperText: hasError ? `${option.headerName} is required` : ''
                        }
                      }}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                );

              case 'singleSelect': {
                const singleSelectOption = option as ICustomDialogSingleSelectComponent;
                const isGenericOption =
                  singleSelectOption.valueOptions.length > 0 &&
                  typeof singleSelectOption.valueOptions[0] === 'object'; // Check if it's an array of objects

                return (
                  <div key={option.field}>
                    <FormHelperText error={hasError}>
                      {option.headerName}
                      {option.required ? ' *' : ''}
                    </FormHelperText>
                    <Select
                      fullWidth
                      value={value}
                      onChange={(e) => handleChange(option.field, e.target.value)}
                      error={hasError}
                      required={option.required}
                    >
                      {singleSelectOption.valueOptions.map((opt) => {
                        const optionValue = isGenericOption
                          ? (opt as IGenericDropDownOption).value
                          : (opt as string);
                        const optionLabel = isGenericOption
                          ? (opt as IGenericDropDownOption).label
                          : (opt as string);

                        return (
                          <MenuItem key={optionValue} value={optionValue}>
                            {optionLabel}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {hasError && (
                      <FormHelperText error>{`${option.headerName} is required`}</FormHelperText>
                    )}
                  </div>
                );
              }

              default:
                return null;
            }
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default CustomDialog;
