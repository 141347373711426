import { GridOverlay } from '@mui/x-data-grid-premium';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

interface CustomNoRowsOverlayProps {
  message: string;
  onRetry?: () => void; // Ensure this is optional
}



function CustomNoRowsOverlay({ message, onRetry }: CustomNoRowsOverlayProps) {
  return (
    <GridOverlay>
      <Box sx={{ mt: 1, textAlign: 'center' }}>
        <Typography variant="body1" color={onRetry ? "error" : "text.secondary"}>
          {message}
        </Typography>
        {onRetry && (
          <Button variant="outlined" color="primary" onClick={onRetry} sx={{ mt: 2 }}>
            Retry
          </Button>
        )}
      </Box>
    </GridOverlay>
  );
}

export default CustomNoRowsOverlay;