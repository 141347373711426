import { GridRowId, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-premium';
import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';

interface UseEditableRowActionsProps {
  dataRows: any[]; // The data rows (passed in from the parent component)
  dataRowModesModel: GridRowModesModel; // The row modes model (passed in from the parent component)
  setDataRowModesModel: (model: GridRowModesModel) => void; // A setter function for updating row modes (passed in from parent)
  setDataRows: (rows: any[]) => void; // A setter function for updating rows (passed in from parent)
}

const useStandardEditableRowActions = ({
  dataRows,
  dataRowModesModel,
  setDataRowModesModel,
  setDataRows,
}: UseEditableRowActionsProps) => {
  // Handle the "Save" action
  const handleSaveClick = (ID: GridRowId) => () => {
    const newModel: GridRowModesModel = {
      ...dataRowModesModel,
      [ID]: { mode: GridRowModes.View },
    };
    setDataRowModesModel(newModel); // Directly set the new model
  };

  // Handle the "Cancel" action
  const handleCancelClick = (ID: GridRowId) => () => {
    const newModel: GridRowModesModel = {
      ...dataRowModesModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    };
    setDataRowModesModel(newModel); // Directly set the new model
    const editedRow = dataRows.find((row) => row.ID === ID);
    if (editedRow?.isNew) {
      setDataRows(dataRows.filter((row) => row.ID !== ID)); // Remove the new row if it's canceled
    }
  };

  // Handle the "Edit" action
  const handleEditClick = (ID: GridRowId) => () => {
    const newModel: GridRowModesModel = {
      ...dataRowModesModel,
      [ID]: { mode: GridRowModes.Edit },
    };
    setDataRowModesModel(newModel); // Directly set the new model
  };

  // Update row modes model when any mode changes
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel); // Directly set the new model
  };

  return {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  };
};

export default useStandardEditableRowActions;
