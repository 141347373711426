
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Popover, SelectChangeEvent, Slider, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from '../components/TabPanel';
import getInstanceID from '../functions/getInstanceID';
import { DataGrid, GridActionsCellItem, GridRowModesModel, GridRowModes, GridRowModel, GridColDef, GridRowId, GridRenderCellParams } from '@mui/x-data-grid-premium';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';
import useStandardEditableRowActions from '../components/useStandardEditableRowActions';
import CustomToolBarWithDropDownToolbar from '../components/CustomToolbarWithDropdown';
import { AlertService } from '../services/AlertService';
import { Api, handleErrorResponse } from "../api";
import { ChromePicker, ColorResult, HuePicker, RGBColor, TwitterPicker } from 'react-color';
import ColorLensIcon from "@mui/icons-material/ColorLens";


export const ColourPickerCell = React.memo((props: GridRenderCellParams) => {
  const colour = props.value || 'hsl(0, 0%, 100%)'; // Default to white if no color is set
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center',      // Centers vertically
        width: '100%',
        height: '100%',                // Add a height for the cell to display color
        backgroundColor: colour,    // Use the color passed from the cell data
      }}
    />
  );
});

export const ColourPickerEditCell = React.memo((props: GridRenderCellParams) => {
  const initialColour = props.value || 'hsl(0, 0%, 100%)'; // Default to red (hue 0) if no color is set
  const [colour, setColor] = useState<string>(initialColour);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Function to open color picker popover
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle color change from the TwitterPicker
  const handleColorChange = useCallback((color: { hex: string }) => {
    setColor(color.hex);
    // Update grid cell value with the new color
    props.api.setEditCellValue({
      id: props.id,
      field: props.field,
      value: color.hex,
    });
    handleClose(); // Close the popover after selecting the color
  }, [props]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center',      // Centers vertically
      width: '100%',
      backgroundColor: colour
    }}>
      {/* Icon button to open the color picker */}
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: 'white', // White background for the circle
          borderRadius: '100%',      // Makes it a circle
          padding: 0,               // Adds padding for the circle size
          boxShadow: 3,
          // Adds some shadow to make the button pop
          '&:hover': {
            backgroundColor: '#f1f1f1', // Optional: lighter background on hover
          }
        }}
      >
        <ColorLensIcon />
      </IconButton>
      {/* Popover for color picker */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TwitterPicker
          color={colour} // Current color value
          onChangeComplete={handleColorChange} // Function to handle color change
        />
      </Popover>
    </Box>
  );
});
