import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Api } from "../api";
import secureLocalStorage from "react-secure-storage";
import getInstanceData from "../functions/getInstanceData";
import { IInstanceData } from "../Interfaces/InstanceData.interface";
import { CircularProgress, AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Divider, useMediaQuery, useTheme, Box } from "@mui/material";
import { Menu as MenuIcon, DarkMode as DarkModeIcon, LightMode as LightModeIcon } from "@mui/icons-material";
import * as FaIcons from "react-icons/fa";
import styled from "styled-components";
import { SidebarData } from "../components/SidebarData";
import getInstanceID from "../functions/getInstanceID";

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

interface AppLayoutProps {
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppLayout: React.FC<AppLayoutProps> = ({ darkMode, setDarkMode }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [sideBarLoading, setSideBarLoading] = useState(false);
  const [access, setAccess] = React.useState<any[]>([]);
  const navigate = useNavigate(); // Initialize navigate

  // Sync drawer state with screen size
  React.useEffect(() => {
    if (isLargeScreen) {
      setDrawerOpen(true); // Automatically open the drawer on larger screens
    } else {
      setDrawerOpen(false); // Collapse the drawer on smaller screens
    }
  }, [isLargeScreen]);



  // Handle authentication and loading spinner
  useEffect(() => {
    const handleAuth = async () => {
      setLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get("LoginToken");
      const localToken = secureLocalStorage.getItem("LoginToken");
      const token = urlToken || localToken;

      // Store the token from URL in local storage if provided
      if (urlToken) {
        setSideBarLoading(true);
        secureLocalStorage.clear(); // Remove all old data
        secureLocalStorage.setItem("LoginToken", urlToken);
      }

      if (!token) {
        // If no token, redirect to external login
        window.location.href = "https://login.redochre.cloud/?reason=nologintoken";
        return;
      }

      try {
        if (urlToken || secureLocalStorage.getItem("InstanceData") == null) {
          let RequestURL = `/api/Plods/~StartWithInfo`;
          const { data } = await Api.post(RequestURL);
          secureLocalStorage.setItem("InstanceData", data);
        }
        else {
          // Validate the token with the API
          let RequestURL = `/api/Plods/ID_${getInstanceID()}`; // TODO: Put in an api that is quicker just to valid a token. and that will check page perms?
          const { data } = await Api.post(RequestURL);
        }

        let InstanceInfo: IInstanceData = secureLocalStorage.getItem("InstanceData") as IInstanceData;
        setAccess(InstanceInfo.RoleAccess);
      } catch (error) {
        secureLocalStorage.clear(); // Clear invalid token
        window.location.replace(`https://login.redochre.cloud/?reason=failedwith${urlToken ? "launchvalidation" : "tokenvalidation"}`);
        return;
      } finally {
        setSideBarLoading(false);
        setLoading(false);
      }
    };

    handleAuth();
  }, [location]);

  const drawerWidth = 240;

  const hasRoles = access?.length > 0;

  const hasAccess = (path: string) => {
    if (!hasRoles) return false;
    const isAccess = access.find(
      (i: any) => i.Menu.toLowerCase() === path.toLowerCase() && (i.Active === "True" || i.Active === true)
    );
    return !!isAccess;
  };

  return (
    <div style={{ display: "flex" }}>
      {/* AppBar */}
      <AppBar color="default" position="fixed" sx={{ zIndex: 1300, boxShadow: "none" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!isLargeScreen && (
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(!drawerOpen)} sx={{ mr: 2 }}>
                <MenuIcon />
              </IconButton>
            )}
            {/* Logo */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" noWrap>
                Drill Down - {location.pathname == "/" ? "Home" : SidebarData.find((item) => item.path === location.pathname)?.title}
              </Typography>
            </div>
          </div>
          {/* Dark/Light Mode Toggle Button */}
          {/* <IconButton edge="end" color="inherit" aria-label="toggle dark mode" onClick={() => {
            setDarkMode(!darkMode);
            localStorage.setItem("DarkMode", (!darkMode).toString());
          }}>
            {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton> */}
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant={isLargeScreen ? "persistent" : "temporary"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        ModalProps={{ keepMounted: true }}
      >
        <Toolbar />
        <List>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon sx={{ color: "#ffffff" }}>
              <FaIcons.FaHome />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <Divider sx={{ backgroundColor: "#ffffff" }} />
          {sideBarLoading ? (
            <LoadingSpinner>
              <CircularProgress />
            </LoadingSpinner>
          ) : (
            SidebarData.map((item, index) => {

              if (item.title === "Spacer") {
                return <Divider sx={{ backgroundColor: "#ffffff" }} />;
              }

              return hasAccess(item.title) ? (
                <ListItemButton key={index} onClick={() => navigate(item.path)}>
                  <ListItemIcon sx={{ color: "#ffffff" }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title === "Teams" ? "Team" : item.title} />
                </ListItemButton>
              ) : null;
            })
          )}

        </List>
      </Drawer>

      {/* Main Content */}
      <main
        style={{
          flexGrow: 1,
          padding: "24px",
          transition: "margin 0.3s",
        }}
      >
        <Toolbar />
        {/* If fullPageLoading is true, show loading across the entire page */}
        {loading || sideBarLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={60} thickness={4} />
          </Box>
        ) : (
          <Outlet />
        )}

      </main>
    </div>
  );
};

export default AppLayout;
