//#region Imports!
import React, { useState } from "react";
import { getActionsForRow, useEditableRowHandlers } from './EditableRowHandlers'; // Import reusable handlers

//The images.
import Consumables from "./../assets/Consumables.gif";
import Tasks from "./../assets/Tasks.gif";
import DrillingDetails from "./../assets/DrillingDetails.gif";
import Operator from "./../assets/Operator.png";
import ShiftComments from "./../assets/ShiftComments.gif";
import ShiftInformation from "./../assets/ShiftInformation.gif";

//Icons.
import CustomPlodSheetDataGrid from './CustomPlodSheetDataGrid';
//For the grid.
import {
  GridColDef,
  GridEditSingleSelectCell,
  GridEditSingleSelectCellProps,
  GridRenderEditCellParams,
  GridRowModesModel,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import { Box, Button, FormHelperText, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, styled, TextareaAutosize, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
//Types.
import { IGenericDropDownOption } from '../Interfaces/GenericDropDownOption.interface';
import { IActivityOverview } from '../Interfaces/IActivityOverview.interface';

//Components.
import NewPlodBox from "../components/newPlodBox";
import dayjs, { Dayjs } from "dayjs";
import { Api, handleErrorResponse } from "../api";
import { AlertService } from "../services/AlertService";
import HoursDifferenceCalculator from "../functions/HoursDifferenceCalculator";
import CustomTimePickerItem from "./CustomTimePickerItem";


import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GenericSelect from "./GenericSelect";
import validateAndSet from "../functions/ValidAndSetData";

import { ConsumableRow, IDrillingDetailsRow, IEntireShiftData, IShift, OneOffChargeRow, OperatorRow, ITaskRow } from "../Interfaces/Shift.interface";
import { randomId } from "@mui/x-data-grid-generator";
import ActivityOverviewTable from "../components/ActivityOverviewTable";
//#endregion

//#region TYPES





interface ITaskChargeTypeOption extends IGenericDropDownOption {
  ID_EA_UnitOfMeasurement: number;
  UnitOfMeasurement_Name: string;
  UnitOfMeasurement_ChargeByHours: boolean;
};

interface IDPPersonnelOption extends IGenericDropDownOption {
  ID_EA_PersonnelType: number;
};
interface IStatusOption extends IGenericDropDownOption {
  StatusOrder: number;
}

interface IDPTaskOption extends IGenericDropDownOption {
  ChargeType_Locked: boolean;
  ID_EDP_Task_ChargeType: number;
  LongDescription: string;
}

type BigFiltersRequest = {
  DPConsumables: IGenericDropDownOption[];
  DPTasks: IDPTaskOption[];
  DPPersonnels: IDPPersonnelOption[];
  DPSites: IGenericDropDownOption[];
  DPRigs: IGenericDropDownOption[];
  DPObjectives: IGenericDropDownOption[]
  OneOffCharges: IGenericDropDownOption[];
  TaskChargeTypes: ITaskChargeTypeOption[];
  Statuses: IStatusOption[];
  PersonnelTypes: IGenericDropDownOption[];
  BitSizes: IGenericDropDownOption[];
}


//#endregion

const FromToTime: boolean = false;

interface PlodDetailsProps {
  existingRowsData?: IEntireShiftData | null
  onStatusValueChange?: (newDateValue: string, newDayNightValue: string, ID_WF_Status: string) => void;
  selectorData: {
    save: boolean;
    plodID: string;
    drillingProgramID: string;
    contractID: string;
    miningCompanyID: string;
    shiftID: number | null;
  }
  disabled?: boolean;
}


const PlodDetails: React.FC<PlodDetailsProps> = ({ selectorData, existingRowsData, onStatusValueChange, disabled = false }) => {
  const { save, plodID, drillingProgramID, contractID, miningCompanyID, shiftID } = selectorData; // just makes it neater and then expands when we use.

  // const { shiftOptions, consumableOptions } = optionsData;
  //This so dont have to do props. all the time (will look into better ways)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);


  //#region Rows setup
  const [personnelRowModesModel, setPersonnelRowModesModel] = React.useState<GridRowModesModel>({});
  const [personnelRows, setPersonnelRows] = useState<OperatorRow[]>([]); // Assuming operatorRows is of type any[]
  const [drillingDetailsRowModesModel, setDrillingDetailsRowModesModel] = React.useState<GridRowModesModel>({});
  const [drillingDetailsRows, setDrillingDetailsRows] = React.useState<IDrillingDetailsRow[]>([]);
  const [taskRowModesModel, setTaskRowModesModel] = React.useState<GridRowModesModel>({});
  const [taskRows, setTaskRows] = React.useState<ITaskRow[]>([]);
  const [consumableRowModesModel, setConsumableRowModesModel] = React.useState<GridRowModesModel>({});
  const [consumableRows, setConsumableRows] = React.useState<ConsumableRow[]>([]);
  const [oneOffChargeRowModesModel, setOneOffChargeRowModesModel] = React.useState<GridRowModesModel>({});
  const [oneOffChargeRows, setOneOffChargeRows] = React.useState<OneOffChargeRow[]>([]);
  const [shiftComment, setShiftComments] = React.useState<string>("");
  //#endregion

  //#region Selector Setup
  const shiftOptions: IGenericDropDownOption[] = [{ label: "Day", value: "Day" }, { label: "Night", value: "Night" }];
  const [consumableOptions, setConsumableOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [taskOptions, setTaskOptions] = React.useState<IDPTaskOption[]>([]);
  const [personnelOptions, setPersonnelOptions] = React.useState<IDPPersonnelOption[]>([]);
  const [siteOptions, setSiteOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [rigOptions, setRigOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [objectiveOptions, setObjectiveOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [oneOffChargeOptions, setOneOffChargeOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [taskChargeTypeOptions, setTaskChargeTypeOptions] = React.useState<ITaskChargeTypeOption[]>([]);
  const [statusOptions, setStatusOptions] = React.useState<IStatusOption[]>([]);
  const [personnelTypeOptions, setPersonnelTypeOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [bitSizeOptions, setBitSizeOptions] = React.useState<IGenericDropDownOption[]>([]);
  //#endregion

  //#region Populating Shift Options Data. (Look at moving this out.)
  const ClearAllData = () => {
    //The rows and stuff
    setPersonnelRows([]);
    setDrillingDetailsRows([]);
    setTaskRows([]);
    setConsumableRows([]);
    setOneOffChargeRows([]);
    setShiftComments("");

    //The filters
    setConsumableOptions([]);
    setTaskOptions([]);
    setPersonnelOptions([]);
    setSiteOptions([]);
    setRigOptions([]);
    setObjectiveOptions([]);
    setOneOffChargeOptions([]);
    setTaskChargeTypeOptions([]);
    setStatusOptions([]);


    setSiteFilter('');
    setRigFilter('');
    setObjectiveFilter('');
    setStatusFilter('');
    setDayNightFilter('');
  };

  React.useEffect(() => {
    if (drillingProgramID) {
      fetchDropdownData();
    };
  }, [drillingProgramID]);


  const fetchDropdownData = async () => {
    if (!drillingProgramID) return;
    setLoading(true);
    ClearAllData();
    try {
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/~GetAllValuesForShift` //?Look into potentially making this come off of shift, then can have it point status correctly.
      );

      validateAndSet(data.DPConsumables, setConsumableOptions, "Consumables are missing");
      validateAndSet(data.DPTasks, setTaskOptions, "Tasks are missing");
      validateAndSet(data.DPPersonnels, setPersonnelOptions, "Personnels are missing");
      validateAndSet(data.DPSites, setSiteOptions, "Sites are missing");
      validateAndSet(data.DPRigs, setRigOptions, "Rigs are missing");
      validateAndSet(data.DPObjectives, setObjectiveOptions, "Objectives are missing");
      validateAndSet(data.OneOffCharges, setOneOffChargeOptions, "One Off Charges are missing");
      validateAndSet(data.TaskChargeTypes, setTaskChargeTypeOptions, "Task Charge Types are missing");
      validateAndSet(data.Statuses, setStatusOptions, "Statuses are missing");
      validateAndSet(data.PersonnelTypes, setPersonnelTypeOptions, "PersonnelTypes are missing");
      validateAndSet(data.BitSizes, setBitSizeOptions, "Bit sizes are missing");
      if (data.Statuses.length > 0 && save) {
        setStatusFilter(data.Statuses[0].value);
      }
    } catch (e) {
      ClearAllData();
      handleErrorResponse(e, "Error fetching filter options");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setExistingRows();
  }, [existingRowsData]);

  const setExistingRows = () => {
    if (shiftID && existingRowsData) {
      setPersonnelRows(existingRowsData.Operators);
      setDrillingDetailsRows(existingRowsData.DrillingDetails);
      setTaskRows(existingRowsData.Tasks);
      setConsumableRows(existingRowsData.Consumables);
      setOneOffChargeRows(existingRowsData.OneOffCharges);
      setShiftComments(existingRowsData.ShiftComment);
      setSiteFilter(existingRowsData.ID_EA_Site);
      setRigFilter(existingRowsData.ID_EA_Rig);
      setObjectiveFilter(existingRowsData.ID_EA_Objective ?? ''); // probs better way to do this, but once they required will be easier.
      setStatusFilter(existingRowsData.ID_WF_Status);
      setDayNightFilter(existingRowsData.DayNight);
      if (existingRowsData.Date != null) {
        setDateValue(dayjs(existingRowsData.Date));
      }
    }
  };

  //#endregion 

  //#region Operators
  const operatorHandlers = useEditableRowHandlers({
    rowModesModel: personnelRowModesModel,
    setRowModesModel: setPersonnelRowModesModel,
    rows: personnelRows,
    setRows: setPersonnelRows,
  });

  //This so it auto selects a personnel type by default
  function CustomOperatorEditComponent(props: GridEditSingleSelectCellProps) {
    const apiRef = useGridApiContext();
    const handleValueChange = async (e: any) => {
      const currentPersonnel: IDPPersonnelOption | undefined = personnelOptions.find(
        (row: IDPPersonnelOption) => row.value === e.target.value
      );
      const personnelType: number = currentPersonnel!.ID_EA_PersonnelType;
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "ID_EA_PersonnelType",
        value: personnelType,
      });
    };
    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  const personnelColumns: GridColDef<any>[] = [
    {
      field: "ID_EA_Personnel",
      headerName: "Personnel",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => (
        <CustomOperatorEditComponent
          {...params}
        />
      ),
    },
    {
      field: "ID_EA_PersonnelType",
      headerName: "Type Override",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelTypeOptions,
    },
    {
      field: "Hours",
      headerName: "Hours",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Comment",
      headerName: "Comments",
      flex: 5,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: personnelRowModesModel,
          handlers: operatorHandlers,
          actions: { cancel: true, edit: true, save: true, delete: true }, // Customize here too
        }),
    },
  ];


  //#region Drilling Details
  const drillingDetailsHandlers = useEditableRowHandlers({
    rowModesModel: drillingDetailsRowModesModel,
    setRowModesModel: setDrillingDetailsRowModesModel,
    rows: drillingDetailsRows,
    setRows: setDrillingDetailsRows,
  });

  const drillingDetailsColumns: GridColDef<any>[] = [
    {
      field: "Hole",
      headerName: "Hole",
      flex: 2,
      editable: true,
    },
    {
      field: "FromDepth",
      headerName: "From",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "ToDepth",
      headerName: "To",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      editable: false,
      type: "number",
      valueGetter: (value, row) => {
        let total = row.ToDepth - row.FromDepth;
        return total;
      }
    },
    {
      field: "TotalRecovered",
      headerName: "Total Recovered",
      flex: 1,
      editable: true,
      type: "number",
    },
    ...(FromToTime
      ? [
        {
          field: 'FromTime',
          headerName: 'From Time',
          width: 180,
          editable: true,
          valueFormatter: (params: any) => {
            if (!params) {
              return ''; // Return an empty string if params or value is undefined
            }
            try {
              return dayjs(params).format('HH:mm');
            } catch {
              return '';
            }
          },
          renderEditCell: (params: GridRenderEditCellParams) => (
            <CustomTimePickerItem {...params} />
          ),
        },
        {
          field: 'ToTime',
          headerName: 'To Time',
          width: 180,
          editable: true,
          valueFormatter: (params: any) => {
            if (!params) {
              return ''; // Return an empty string if params or value is undefined
            }
            try {
              return dayjs(params).format('HH:mm');
            } catch {
              return '';
            }
          },
          renderEditCell: (params: GridRenderEditCellParams) => (
            <CustomTimePickerItem {...params} />
          ),
        },
        {
          field: "HoursDifference",
          headerName: "Hours",
          flex: 1,
          renderCell: (params: { row: { FromTime: any; ToTime: any; }; }) => {
            const fromTime = params.row.FromTime;
            const toTime = params.row.ToTime;

            return <HoursDifferenceCalculator fromTime={fromTime} toTime={toTime} />;
          },
        },
      ]
      : []),
    {
      field: "BitSerial",
      headerName: "Bit #",
      flex: 1,
      editable: true,
    },
    {
      field: "ID_EA_BitSize",
      headerName: "Bit Size",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: bitSizeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: drillingDetailsRowModesModel,
          handlers: drillingDetailsHandlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
        }),
    },
  ];
  //#endregion

  //#region Tasks
  const taskHandlers = useEditableRowHandlers({
    rowModesModel: taskRowModesModel,
    setRowModesModel: setTaskRowModesModel,
    rows: taskRows,
    setRows: setTaskRows,
  });

  //TODO: Move this into like a style sheet or smthing.
  // Styled InputLabel to customize its styles
  const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    color: 'black',
    marginLeft: '10px', // Space between label and text field
    whiteSpace: 'nowrap', // Prevent label from wrapping
    fontSize: '14px',
    '&.Mui-disabled': {
      color: 'black', // Set disabled label color to black
    },
    flexShrink: 0
  }));

  // Styled TextField to customize its styles
  const CustomTextField = styled(TextField)(({ theme }) => ({
    marginLeft: '10px', // Space between label and text field
    '& .MuiInputBase-root': {
      color: 'black', // Change text color
      border: 'none', // Remove borders
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove bottom border
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none', // Remove bottom border on focus
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Remove border for outlined variant
      },
    },
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none', // Remove bottom border for filled variant
      },
      '&:after': {
        borderBottom: 'none', // Remove bottom border on focus for filled variant
      },
    },
  }));
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  function CustomTaskChargeEditComponent(props: GridEditSingleSelectCellProps) {
    // const apiRef = useGridApiContext();
    const { id, value, field, api, colDef, row } = props;

    const handleValueChange = async (e: any) => {
      const currentTask: IDPTaskOption | undefined = taskOptions.find(
        (row: IDPTaskOption) => row.value === e.target.value
      );
      if (currentTask) {
        const chargeType: number = currentTask.ID_EDP_Task_ChargeType;
        await api.setEditCellValue({
          id,
          field,
          value: e.target.value
        }, e);
        await api.setEditCellValue({
          id: props.id,
          field: "ID_EDP_Task_ChargeType",
          value: chargeType,
        }, e);
        await api.setEditCellValue({
          id: props.id,
          field: "AltChargeQuantity",
          value: null,
        }, e); // just makes it retrigger itself so it works right.
      }
    };
    return (
      <Select
        value={value || ''}
        onChange={handleValueChange}
        fullWidth
      >
        {taskOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <HtmlTooltip
              key={option.value}
              title={
                <React.Fragment>
                  <Typography color="inherit">{option.label}</Typography>
                  <div>{option.LongDescription}</div>
                </React.Fragment>
              }
              arrow
              placement="right"
            >
              <ListItemText primary={option.label} />
            </HtmlTooltip>
          </MenuItem>
        ))}
      </Select>
    );
  }

  const taskColumns: GridColDef<any>[] = [
    {
      field: 'FromTime',
      headerName: 'From Time',
      width: 180,
      editable: true,
      valueFormatter: (params: any) => {
        if (!params) {
          return ''; // Return an empty string if params or value is undefined
        }
        try {
          return dayjs(params).format('HH:mm');
        } catch {
          return '';
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomTimePickerItem {...params} />
      ),
    },
    {
      field: 'ToTime',
      headerName: 'To Time',
      width: 180,
      editable: true,
      valueFormatter: (params: any) => {
        if (!params) {
          return ''; // Return an empty string if params or value is undefined
        }
        try {
          return dayjs(params).format('HH:mm');
        } catch {
          return '';
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomTimePickerItem {...params} />
      ),
    },
    {
      field: "HoursDifference",
      headerName: "Hours",
      flex: 1,
      renderCell: (params) => {
        const fromTime = params.row.FromTime;
        const toTime = params.row.ToTime;

        return <HoursDifferenceCalculator fromTime={fromTime} toTime={toTime} />;
      },
    },
    {
      field: "ID_EA_Task",
      headerName: "Task",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: taskOptions.sort((a, b) => a.label.localeCompare(b.label)),
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return (
          <CustomTaskChargeEditComponent
            {...params}
          />
        );
      },

      renderCell: (params) => {
        const currentTask: IDPTaskOption | undefined = taskOptions.find((option: IDPTaskOption) => option.value === params.value);

        if (!currentTask) return null;

        return (
          <HtmlTooltip
            key={currentTask.value}
            title={
              <React.Fragment>
                <Typography color="inherit">{currentTask.label}</Typography>
                <div>{currentTask.LongDescription}</div>
              </React.Fragment>
            }
            arrow
            placement="right"
          >
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              <strong >{currentTask.label}</strong>
              <Box
                sx={{
                  fontSize: '12px',
                  color: 'gray',
                  marginTop: '4px',
                  whiteSpace: 'nowrap',
                }}
              >
                {currentTask.LongDescription}
              </Box>
            </Box>
          </HtmlTooltip>
        );
      },
    },

    {
      field: "ID_EDP_Task_ChargeType",
      headerName: "Charge",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: taskChargeTypeOptions,
      renderEditCell: (params) => {
        const currentTask = taskOptions.find(x => x.value === params.row?.ID_EA_Task); //TODO: Look into this causing bugs

        return (
          <GridEditSingleSelectCell
            sx={{ width: "100%" }}
            {...params}
            onValueChange={(e) => {
              const newValue = e.target.value;
              params.api.setEditCellValue({ id: params.id, field: 'ID_EDP_Task_ChargeType', value: newValue });
              params.api.setEditCellValue({ id: params.id, field: 'AltChargeQuantity', value: '' });
            }}
            disabled={currentTask?.ChargeType_Locked} // Disable if ChargeType_Locked is true
          />
        );
      },
    },
    {
      field: "AltChargeQuantity",
      headerName: "Alt Charge",
      flex: 2,
      editable: true,
      renderCell: (params) => {
        const chargeItem = taskChargeTypeOptions.find(x => x.value === params.row.ID_EDP_Task_ChargeType);
        if (!chargeItem || chargeItem.UnitOfMeasurement_ChargeByHours == true) {
          return <CustomInputLabel disabled />; // Display value without editing
        }
        // Render TextField for editing if AltChargeType has a value
        const label = chargeItem.UnitOfMeasurement_Name + " QTY: "; // Variable for the label
        return (
          <Box display="flex" alignItems="center">
            <CustomInputLabel disabled htmlFor="custom-text-field" sx={{ marginLeft: '0px' }}>{label}</CustomInputLabel>
            <CustomInputLabel disabled htmlFor="custom-text-field">{params.value} </CustomInputLabel>
          </Box>
        );
      },
      renderEditCell: (params) => {
        // const altChargeTypeValue = params.row.charge;
        const chargeItem = taskChargeTypeOptions.find(x => x.value === params.row.ID_EDP_Task_ChargeType);
        if (!chargeItem || chargeItem.UnitOfMeasurement_ChargeByHours == true) {
          return <CustomInputLabel disabled />;// Display value without editing
        }
        // Render TextField for editing if AltChargeType has a value
        const label = chargeItem.UnitOfMeasurement_Name + " QTY: "; // Variable for the label
        return (
          <Box display="flex" alignItems="center">
            <CustomInputLabel sx={{ fontSize: '16px' }} disabled>{label}</CustomInputLabel>
            <CustomTextField
              sx={{ fontSize: '16px' }}
              variant="standard"
              value={params.value ?? ''}
              type="number"
              onChange={(event) => {
                params.api.setEditCellValue({ id: params.id, field: 'AltChargeQuantity', value: event.target.value });
              }} />
          </Box>
        );
      },
    },
    {
      field: "Comment",
      headerName: "Comment",
      flex: 4,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: taskRowModesModel,
          handlers: taskHandlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
        }),
    },
  ];
  //#endregion

  const consumableHandlers = useEditableRowHandlers({
    rowModesModel: consumableRowModesModel,
    setRowModesModel: setConsumableRowModesModel,
    rows: consumableRows,
    setRows: setConsumableRows,
  });

  const consumableColumns: GridColDef<any>[] = [
    {
      field: "ID_EA_Consumable",
      headerName: "Consumable",
      flex: 4,
      editable: true,
      type: "singleSelect",
      valueOptions: consumableOptions,
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Charge",
      headerName: "Charge?",
      flex: 1,
      editable: true,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: consumableRowModesModel,
          handlers: consumableHandlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
        }),
    },
  ];

  const oneOffChargeHandlers = useEditableRowHandlers({
    rowModesModel: oneOffChargeRowModesModel,
    setRowModesModel: setOneOffChargeRowModesModel,
    rows: oneOffChargeRows,
    setRows: setOneOffChargeRows,
  });

  const oneOffChargeColumns: GridColDef<any>[] = [
    {
      field: "ID_EDP_OneOffCharge",
      headerName: "One Off Charge",
      flex: 4,
      editable: true,
      type: "singleSelect",
      valueOptions: oneOffChargeOptions,
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Comment",
      headerName: "Comments",
      flex: 2.5,
      editable: true,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: oneOffChargeRowModesModel,
          handlers: oneOffChargeHandlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
        }),
    },
  ];
  //#endregion


  //#region Submitting Data
  //TODO: make sure this is actually working not some glitchy mess :)
  const validateFields = (): string[] => {
    const validationErrors: string[] = [];
    if (personnelRows.length == 0) validationErrors.push("Personnel cannot be empty.");
    if (drillingDetailsRows.length == 0) validationErrors.push("Drilling Details cannot be empty.");
    // if ((drillingDetailsRows.reduce((total: any, obj: any) => (obj.Hours) + total, 0) + taskRows.reduce((total: any, obj: any) => (obj.Hours) + total, 0)) != 12) {
    //   validationErrors.push("Total hours must add up to 12.");
    // };
    if (rigFilter == '') validationErrors.push("You must select a Rig.");
    if (siteFilter == '') validationErrors.push("You must select a Site.");
    if (dayNightFilter == '') validationErrors.push("You must select a Shift.");
    if (statusFilter == '') validationErrors.push("You must select a Status.");
    if (objectiveFilter == '') validationErrors.push("You must select an Objective.");
    if (!dayjs(dateValue).isValid()) validationErrors.push("You must select a valid Date.");
    if (validationErrors.length < 1) {
      const validations = [
        //Operators
        { check: personnelRows.some((element: OperatorRow) => element.ID_EA_Personnel < 0), message: "Personnel cannot be empty." },
        { check: personnelRows.some((element: OperatorRow) => element.ID_EA_PersonnelType < 0), message: "Personnel Type cannot be empty." },
        { check: personnelRows.some((element: OperatorRow) => element.Hours < 0), message: "Hours cannot be negative." },
        //Drilling Details
        { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => !element.Hole.trim()), message: "Hole cannot be empty." },
        { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => element.FromDepth < 0), message: "FromDepth cannot be negative." },
        { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => element.ToDepth < 0), message: "ToDepth cannot be negative." },
        // { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => element.FromTime == null || !dayjs(element.FromTime).isValid()), message: "From Time cannot be empty." },
        // { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => element.ToTime == null || !dayjs(element.ToTime).isValid()), message: "To Time cannot be empty." },
        { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => element.TotalRecovered < 0), message: "Total recovered cannot be negative." },
        { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => !element.BitSerial.trim()), message: "Bit # cannot be empty." },
        { check: drillingDetailsRows.some((element: IDrillingDetailsRow) => element.ID_EA_BitSize < 0), message: "Bit Size cannot be empty." },
        //Task
        { check: taskRows.some((element: ITaskRow) => element.ID_EA_Task < 0), message: "Task cannot be empty." },
        { check: taskRows.some((element: ITaskRow) => element.FromTime == null || !dayjs(element.FromTime).isValid()), message: "From Time cannot be empty." },
        { check: taskRows.some((element: ITaskRow) => element.ToTime == null || !dayjs(element.ToTime).isValid()), message: "To Time cannot be empty." },
        { check: taskRows.some((element: ITaskRow) => element.ChargeType < 0), message: "Charge cannot be empty." },
        //TODO: Make it also check if there is a needed alt charge quantity one.
        //Consumables
        { check: consumableRows.some((element: ConsumableRow) => element.ID_EA_Consumable < 0), message: "Consumable cannot be empty." },
        { check: consumableRows.some((element: ConsumableRow) => element.Quantity < 0), message: "Quantity cannot be negative." },
        //One Off Charges
        { check: oneOffChargeRows.some((element: OneOffChargeRow) => element.ID_EDP_OneOffCharge < 0), message: "One Off Charge cannot be empty." },
        { check: oneOffChargeRows.some((element: OneOffChargeRow) => element.Quantity < 0), message: "Quantity cannot be negative." },
      ];

      validations.forEach(({ check, message }) => {
        if (check) {
          validationErrors.push(message);
        }
      });
      //Loop here and make sure data is correct.
    }
    return validationErrors;
  };

  const handleSubmitButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const createDataJSON = (): IEntireShiftData => {
      const updateTimeFields = (list: any[]) => {
        return list.map(item => {
          return {
            ...item, // Preserve other fields
            FromTime: item.FromTime && dayjs(item.FromTime).isValid() ? dayjs(item.FromTime).format("YYYY/MM/DDTHH:mm:ss") : null,//? idk why its this format
            ToTime: item.ToTime && dayjs(item.ToTime).isValid() ? dayjs(item.ToTime).format("YYYY/MM/DDTHH:mm:ss") : null, //? idk why its this format
          };
        });
      };

      return {
        Date: dateValue?.format("MM/DD/YYYY").toString() || "",
        DayNight: dayNightFilter,
        ID_EA_Site: siteFilter,
        ID_EA_Objective: objectiveFilter,
        ID_EA_Rig: rigFilter,
        ID_WF_Status: statusFilter,
        Operators: personnelRows,
        DrillingDetails: updateTimeFields(drillingDetailsRows), // Returns new array
        Tasks: updateTimeFields(taskRows), // Returns new array
        Consumables: consumableRows,
        OneOffCharges: oneOffChargeRows,
        ShiftComment: shiftComment,
      };
    };

    const dataJSON = createDataJSON();

    const errors: string[] = validateFields();
    if (errors.length) {
      await AlertService.showAlert(`Error with data.`, 'criticalerror', errors.join("\n"));
    }

    else {
      try {
        const url = save
          ? `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/~AddEntireShift`
          : `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/Shifts/ID_${shiftID}/~UpdateEntireShift`;
        const response = await Api.post(url, dataJSON);
        if (onStatusValueChange) {
          onStatusValueChange(dataJSON.Date, dataJSON.DayNight, dataJSON.ID_WF_Status);
        }
        await AlertService.showAlert(`Successfully ${save ? "Added" : "Updated"} Shift.`, "success");
      } catch (e) {
        handleErrorResponse(e, save ? "failed to save data." : "failed to update data.");
      }
    };
  };
  //#endregion

  //#region Shift Information
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null);
  const [objectiveFilter, setObjectiveFilter] = React.useState<string>('');
  const handleObjectiveFilterChange = (event: SelectChangeEvent) => {
    setObjectiveFilter(event.target.value as string);
  };
  const [dayNightFilter, setDayNightFilter] = React.useState<string>('');
  const handleDayNightFilterChange = (event: SelectChangeEvent) => {
    setDayNightFilter(event.target.value as string);
  };
  const [siteFilter, setSiteFilter] = React.useState<string>('');
  const handleSiteFilterChange = (event: SelectChangeEvent) => {
    setSiteFilter(event.target.value as string);
  };
  const [statusFilter, setStatusFilter] = React.useState<string>('');
  const handleStatusFilterChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value as string);
  };
  const [rigFilter, setRigFilter] = React.useState<string>('');
  const handleRigFilterChange = (event: SelectChangeEvent) => {
    setRigFilter(event.target.value as string);
  };
  //#endregion


  //#region For The time difference slider thing
  const [activityOverviewRows, setActivityOverviewRows] = React.useState<IActivityOverview[]>([]);
  const [activityOverviewLoading, setActivityOverviewLoading] = React.useState<boolean>(false);
  const convertTimeToMinutes = (time: Dayjs | string | null): number | null => {
    return time && dayjs(time).isValid() ? dayjs(time).hour() * 60 + dayjs(time).minute() : null;
  };

  const mapTimeSlots = (items: any[], type: 'DrillingDetail' | 'Task'): IActivityOverview[] => {
    return items.map(item => ({
      ID: randomId(),
      Type: type,
      refrenceID: item.ID,
      Name: type === "DrillingDetail" ? item.Hole : taskOptions.find(task => task.value == item.ID_EA_Task)?.label, // Dynamically set Name based on type
      FromTime: convertTimeToMinutes(item.FromTime),
      ToTime: convertTimeToMinutes(item.ToTime),
    } as IActivityOverview)).filter(line => line.FromTime != null && line.ToTime != null); // Filter out invalid times
  };

  React.useEffect(() => {
    if (drillingDetailsRows && taskRows) {
      setActivityOverviewLoading(true);
      // Combine the lists
      let CombinedList: IActivityOverview[] = [
        ...mapTimeSlots(drillingDetailsRows, "DrillingDetail"),
        ...mapTimeSlots(taskRows, "Task"),
      ];
      //?Here i need to see if any are not correct and remove
      CombinedList.sort((a, b) => { return a.FromTime! - b.FromTime!; });
      setActivityOverviewRows(CombinedList);
      setActivityOverviewLoading(false);
    };
  }, [drillingDetailsRows, taskRows]);

  const handleActivitySliderCommit = (newRange: number[], row: IActivityOverview) => {
    const newFrom = newRange[0];
    const newTo = newRange[1];
    const newFromDate = dayjs().startOf('day').add(newFrom, 'minute');
    const newToDate = dayjs().startOf('day').add(newTo, 'minute');
    if (row.Type === 'DrillingDetail') {
      setDrillingDetailsRows((prevRows) =>
        prevRows.map((item) =>
          item.ID === row.refrenceID
            ? {
              ...item,
              FromTime: newFromDate,
              ToTime: newToDate,
            }
            : item
        )
      );
    } else {
      setTaskRows((prevRows) =>
        prevRows.map((item) =>
          item.ID === row.refrenceID
            ? {
              ...item,
              FromTime: newFromDate,
              ToTime: newToDate,
            }
            : item
        )
      );
    }
  };

  const handleActivitySliderChange = (newRange: number[], ID: string | number) => {
    setActivityOverviewRows(activityOverviewRows.map(row =>
      row.ID === ID ? { ...row, FromTime: newRange[0], ToTime: newRange[1] } : row
    ));
  };

  //#endregion

  return (
    <>
      <Stack style={{ marginLeft: 10, marginRight: 10 }}>
        <NewPlodBox
          color="rgb(145, 149, 154)"
          text="Shift Information"
          height="100%"
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}>
            <Stack spacing={0} padding={"5px"}>
              <FormHelperText>Date</FormHelperText>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dateValue}
                  disabled={errorMessage != null || disabled}
                  onChange={(newValue) => setDateValue(newValue)}
                  format="DD/MM/YYYY"
                  sx={{ width: 250 }}
                />
              </LocalizationProvider>
            </Stack>
            <GenericSelect<IGenericDropDownOption>
              label="Shift"
              value={dayNightFilter}
              onChange={handleDayNightFilterChange}
              options={shiftOptions}
              disabled={errorMessage != null || disabled}
              valueField="value"
              labelField="label"
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}>
            <GenericSelect<IGenericDropDownOption>
              label="Rig"
              value={rigFilter}
              onChange={handleRigFilterChange}
              options={rigOptions}
              disabled={!save || errorMessage != null || disabled}
              valueField="value"
              labelField="label"
            />
            <GenericSelect<IGenericDropDownOption>
              label="Site"
              value={siteFilter}
              onChange={handleSiteFilterChange}
              options={siteOptions}
              disabled={!save || errorMessage != null || disabled}
              valueField="value"
              labelField="label"
            />
            <GenericSelect<IGenericDropDownOption>
              label="Objective"
              value={objectiveFilter}
              onChange={handleObjectiveFilterChange}
              options={objectiveOptions}
              disabled={errorMessage != null || disabled}
              valueField="value"
              labelField="label"
            />
            <GenericSelect<IGenericDropDownOption>
              label="Status"
              plurificationText="es"
              value={statusFilter}
              onChange={handleStatusFilterChange}
              options={statusOptions}
              disabled={save || errorMessage != null || disabled} // locks it to the first item
              valueField="value"
              labelField="label"
            />
          </Box>
        </NewPlodBox >

        <NewPlodBox color="#5f2d87" text="Personnel" key="PersonnelDataGrid">
          <CustomPlodSheetDataGrid
            columns={personnelColumns}
            rows={personnelRows}
            rowModesModel={personnelRowModesModel}
            setRows={setPersonnelRows}
            setRowModesModel={setPersonnelRowModesModel}
            fieldToFocus={"ID_EA_Personnel"}
            buttonText={"Add Personnel"}
            initialRow={{ ID_EA_Personnel: '', ID_EA_PersonnelType: '', Hours: 0, Comment: '' }}
            disabled={!(drillingProgramID)}
            loading={loading || disabled}
          />
        </NewPlodBox>

        <NewPlodBox color="#f26b31" text="Drilling Details" key="DrillingDetailsDataGrid">
          <CustomPlodSheetDataGrid
            columns={drillingDetailsColumns}
            rows={drillingDetailsRows}
            rowModesModel={drillingDetailsRowModesModel}
            setRows={setDrillingDetailsRows}
            setRowModesModel={setDrillingDetailsRowModesModel}
            fieldToFocus={"Hole"}
            buttonText={"Add Drilling Detail"}
            initialRow={{ Hole: '', FromDepth: 0, ToDepth: 0, Total: 0, TotalRecovered: 0, Hours: 0, BitSerial: '', ID_EA_BitSize: '' }}
            disabled={!(drillingProgramID)}
            loading={loading || disabled}
          />
        </NewPlodBox>

        <NewPlodBox color="#3c7c3b" text="Task" key="TaskDataGrid">
          <CustomPlodSheetDataGrid
            columns={taskColumns}
            rows={taskRows}
            rowModesModel={taskRowModesModel}
            setRows={setTaskRows}
            setRowModesModel={setTaskRowModesModel}
            fieldToFocus={"ID_EA_Task"}
            buttonText={"Add Task"}
            initialRow={{ ID_EA_Task: '', Hours: 0, ID_EDP_Task_ChargeType: '', Comment: '' }}
            disabled={!(drillingProgramID)}
            loading={loading || disabled}
          />
        </NewPlodBox>

        <NewPlodBox color="#8c0808" text="Activity Overview" height="400px">
          <ActivityOverviewTable
            activityOverviewRows={activityOverviewRows}
            handleSliderChange={handleActivitySliderChange}
            handleSliderCommit={handleActivitySliderCommit}
            loading={activityOverviewLoading}
          />
        </NewPlodBox>

        <NewPlodBox color="#0055a2" text="Consumables" key="ConsumableDataGrid">
          <CustomPlodSheetDataGrid
            columns={consumableColumns}
            rows={consumableRows}
            rowModesModel={consumableRowModesModel}
            setRows={setConsumableRows}
            setRowModesModel={setConsumableRowModesModel}
            fieldToFocus={"ID_EA_Consumable"}
            buttonText={"Add Consumable"}
            initialRow={{ ID_EA_Consumable: '', Quantity: 0, Charge: false }}
            disabled={!(drillingProgramID)}
            loading={loading || disabled}
          />
        </NewPlodBox>

        <NewPlodBox color="#17b964" text="One Off Charges" key="OneOffChargeDataGrid">
          <CustomPlodSheetDataGrid
            columns={oneOffChargeColumns}
            rows={oneOffChargeRows}
            rowModesModel={oneOffChargeRowModesModel}
            setRows={setOneOffChargeRows}
            setRowModesModel={setOneOffChargeRowModesModel}
            fieldToFocus={"ID_EDP_OneOffCharge"}
            buttonText={"Add One Off Charge"}
            initialRow={{ ID_EDP_OneOffCharge: '', Quantity: 1, Comment: "" }}
            disabled={!(drillingProgramID)}
            loading={loading || disabled}
          />
        </NewPlodBox>

        <NewPlodBox color="#0a0808" text="Comments about the shift" height="150px">
          <TextareaAutosize
            style={{
              width: "100%", // Full width of the container
              maxHeight: "200px", // Fixed height
              resize: "none", // Disable resizing
              padding: "5px", // Add some padding for better readability
              borderRadius: "4px", // Rounded corners
              border: "1px solid #ccc", // Subtle border
              backgroundColor: "#f9f9f9", // Light background for better contrast
              fontSize: "16px", // Comfortable font size
              lineHeight: "1.5", // Better line spacing for readability
              boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Inner shadow for depth
            }}
            value={shiftComment}
            onChange={(e) => setShiftComments(e.target.value)}
            minRows={15}
            disabled={errorMessage != null || disabled}
          />
        </NewPlodBox>

        <Button variant="contained" onClick={handleSubmitButtonClick} style={{ marginLeft: 10, marginBottom: 50 }} disabled={errorMessage != null || disabled || !drillingProgramID}>
          Submit
        </Button>
      </Stack >
    </>
  );
};

export default PlodDetails;
