import React from 'react';
import './index.css';
import { GlobalStyle } from './styles/global';
import App from './App';
import { createRoot } from 'react-dom/client';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { LoadScript } from '@react-google-maps/api';
import { LicenseInfo } from '@mui/x-license';


//Need this to be a .env thing
LicenseInfo.setLicenseKey('47a65e4932dbf1f789c07c2ccb19d0c3Tz0xMDQxNjcsRT0xNzY1NTk1NDAwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');
// LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE!);
const root = createRoot(document.getElementById("root")!);



root.render(<React.StrictMode>
  <Provider store={store}>
    <LoadScript googleMapsApiKey="AIzaSyAlt6un-ql8JKO2DeEKYMpLVmmlyLFKEVQ">
      <GlobalStyle />
      <App />
    </LoadScript>
  </Provider>
</React.StrictMode>,
);