import React, { useState } from 'react';
import { Box, Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Tab, Tabs, Tooltip } from '@mui/material';
import { DataGridPremium, DataGridPremiumProps, GridActionsCellItem, GridColDef, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { Api, handleErrorResponse } from "../api";
import getInstanceID from '../functions/getInstanceID';
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import MiningCompanySelect from '../components/MiningCompanySelect';
import ContractSelect from '../components/ContractSelect';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ArchiveIcon from '@mui/icons-material/Archive';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IContract } from '../Interfaces/Contract.interface';
import { IMiningCompany } from '../Interfaces/MiningCompany.interface';
import CustomDialog, { ICustomDialogComponent } from '../components/CustomDialogComponent';
import { AlertService } from '../services/AlertService';
import dayjs from 'dayjs';

import { IDrillingProgram } from '../Interfaces/DrillingProgram.interface';
import { IGenericDropDownOption } from '../Interfaces/GenericDropDownOption.interface';
import validateAndSet from '../functions/ValidAndSetData';
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";
import DrillingProgramSelect from '../components/DrillingProgramSelect';
import { randomId } from '@mui/x-data-grid-generator';
import { TabPanel } from '../components/TabPanel';




// Define data types
interface InvoiceLine {
  ID_IN_Invoice_Line: number;
  Description: string;
  Quantity: number;
  UnitOfMeasurement: string;
  Total: number;
  ID_AP_Plod: number;
  ID_EC_Contract: number;
  ID_IN_Invoice: number;
  ID_IN_Invoice_Header: number;
  LineType: string;
  PoNumber: null;
  SubTotal: number;
  IsTaxable: boolean;

}

interface InvoiceHeader {
  ID_AP_Plod: number;
  ID_EC_Contract: number;
  ID_IN_Invoice: number;
  ID_IN_Invoice_Header: number;
  Name: string;
  ID_IN_Parent_Header: number | null;
  InvoiceLines: InvoiceLine[];
  InvoiceHeaders: InvoiceHeader[];
}

interface InvoiceEditing {
  miningCompanySelectorID: string;
  contractSelectorID: string;
  invoiceDropDownOptions: any[];
}

const TreeviewThing: React.FC<InvoiceEditing> = ({ miningCompanySelectorID, contractSelectorID, invoiceDropDownOptions }) => {

  let instanceID: string = getInstanceID();
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [invoiceSelectorID, setInvoiceSelectorID] = React.useState('');

  const columns: GridColDef<any>[] = [
    { field: 'UnitOfMeasurement', headerName: 'Unit Of Measurement', flex: 1 },
    { field: 'Quantity', headerName: 'Quantity', flex: 1 },
    { field: 'SubTotal', headerName: 'Sub Total', flex: 1 },
    { field: 'Total', headerName: 'Total', flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      align: 'right',
      getActions: ({ id: ID, row }) => {

        const actionItems: JSX.Element[] = [];
        if (row.RowType == "Header") {
          actionItems.push(
            <React.Fragment>
              <Tooltip title="Add Line" key={`add-${ID}`}>
                <GridActionsCellItem
                  icon={<AddIcon />}
                  label="Add"
                  className="textPrimary"
                  color="inherit"
                  key={`add-${ID}`}
                />
              </Tooltip>
            </React.Fragment>
          );
          actionItems.push(
            <React.Fragment>
              <Tooltip title="Edit Header" key={`edit-${ID}`}>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  color="inherit"
                  key={`edit-${ID}`}
                />
              </Tooltip>
            </React.Fragment>
          );
          actionItems.push(
            <React.Fragment>
              <Tooltip title="Delete Header" key={`delete-${ID}`}>
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  className="textPrimary"
                  color="inherit"
                  key={`delete-${ID}`}
                  onClick={handleDeleteClick(ID)}
                />
              </Tooltip>
            </React.Fragment>
          );
        }
        else {
          actionItems.push(
            <React.Fragment>
              <Tooltip title="Edit Line" key={`edit-${ID}`}>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  color="inherit"
                  key={`edit-${ID}`}
                />
              </Tooltip>
            </React.Fragment>
          );
          actionItems.push(
            <React.Fragment>
              <Tooltip title="Delete Line" key={`delete-${ID}`}>
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  className="textPrimary"
                  color="inherit"
                  key={`delete-${ID}`}
                  onClick={handleDeleteClick(ID)}
                />
              </Tooltip>
            </React.Fragment>
          );
        }

        return actionItems;
      },
    }
  ];

  const fetchData = async () => {
    setDataRows([]);
    setLoading(true);
    setDataGridError(null);
    if (miningCompanySelectorID && contractSelectorID && invoiceSelectorID) {
      try {
        const { data } = await Api.post(`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/ID_${invoiceSelectorID}/~GetInvoiceHeaderFieldsAndChildren`);
        let ProcessedRows: any[] = [];
        data.forEach((header: InvoiceHeader) => {
          ProcessedRows.push({
            Hierarchy: [header.ID_IN_Invoice_Header],
            RowType: 'Header',
            ID: 'Header-' + header.ID_IN_Invoice_Header,
            Name: header.Name
          });
          header.InvoiceLines.forEach((line) => {
            ProcessedRows.push({
              Hierarchy: [header.ID_IN_Invoice_Header, line.ID_IN_Invoice_Line],
              RowType: 'Line',
              ID: 'Line-' + line.ID_IN_Invoice_Line,
              ...line
            });
          });
          header.InvoiceHeaders.forEach((secondHeader) => {
            ProcessedRows.push({
              Hierarchy: [header.ID_IN_Invoice_Header, secondHeader.ID_IN_Invoice_Header],
              RowType: 'Header',
              ID: 'Header-' + secondHeader.ID_IN_Invoice_Header,
              Name: secondHeader.Name
            });
            secondHeader.InvoiceLines.forEach((line) => {
              ProcessedRows.push({
                Hierarchy: [header.ID_IN_Invoice_Header, secondHeader.ID_IN_Invoice_Header, line.ID_IN_Invoice_Line],
                RowType: 'Line',
                ID: 'Line-' + line.ID_IN_Invoice_Line,
                ...line
              });
            });
          });
        });
        setDataRows(ProcessedRows);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading Contracts, Please try again later.`);
      }
    };
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();

  }, [miningCompanySelectorID, contractSelectorID, invoiceSelectorID]);

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentRow = await dataRows.filter((row) => row.ID === ID)[0];
    const currentName = currentRow.RowType == "Header" ? currentRow.Name : currentRow.Description;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Invoice ${currentRow.RowType} "${currentName}".`, "question");
    if (confirmed) {
      try {
        const currentRowID = currentRow.ID.toString().replace("Header-", "").replace("Line-", "");
        const currentRowHierarchyExcludingSelf = currentRow.Hierarchy.filter((row: number) => row.toString() != currentRowID);
        let URLEnd = currentRowHierarchyExcludingSelf.reduce((accumulator: string, currentValue: number) => accumulator + '/InvoiceHeaders/ID_' + currentValue.toString(), '');
        URLEnd += currentRow.RowType == "Header" ? "/InvoiceHeaders" : "/InvoiceLines";
        URLEnd += `/~DeleteItem`;
        console.log(`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices${URLEnd}`);
        const { data } = await Api.post(
          `/api/Plods/ID_${instanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/ID_${invoiceSelectorID}/${URLEnd}`, // this is gonna be trick as it has to loop through the refrence ID's...
          JSON.stringify({ ID: currentRowID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== ID));
        await AlertService.showAlert(`Successfully Deleted Invoice ${currentRow.RowType} "${currentName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Invoice ${currentRow.RowType} "${currentName}".`);
      }
    }
  };


  // Handle dropdown change with correct typing for SelectChangeEvent
  const handleDropDownChange = (event: SelectChangeEvent<any>) => {
    setInvoiceSelectorID(event.target.value);
  };


  function EditInvoiceToolbar() {
    const handleAddClick = () => {
      const ID = randomId();
    };
    return (
      <GridToolbarContainer sx={{ height: "50px" }}>
        <FormControl variant="standard" sx={{ marginLeft: 1 }}>
          <InputLabel id={`header-selector-label`} sx={{ fontSize: 15 }}>
            {`${invoiceDropDownOptions.length === 0 ? "No Invoices" : "Select an Invoice"}`}
          </InputLabel>
          <Select
            labelId={`invoice-selector-label`}
            id={`$InvoiceSelector`}
            value={invoiceSelectorID}
            onChange={handleDropDownChange}
            disabled={invoiceDropDownOptions.length === 0}
            sx={{ width: 300, fontSize: 15 }}
          >
            {invoiceDropDownOptions.map((data: any) => (
              <MenuItem value={data.ID_IN_Invoice} key={data.ID_IN_Invoice}>
                {data.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          sx={{ ml: "auto" }}
          disabled={dataGridError != null || loading || invoiceSelectorID == null}

        >
          Add Invoice Header
        </Button>
      </GridToolbarContainer>
    );

  }

  return (
    <DataGridPremium
      treeData
      rows={dataRows}
      columns={columns}
      getTreeDataPath={(row) => row.Hierarchy}
      isCellEditable={(params) => params.row.RowType != "Header"}
      groupingColDef={{
        headerName: "Name", // Only one grouping column
        valueGetter: (value, row) => {
          return row.RowType == 'Header' ? row.Name : row.Description;
        },
        flex: 2,
      }}
      loading={loading}
      getRowId={(row) => row.ID}
      disableRowSelectionOnClick
      slots={{
        toolbar: () => (<EditInvoiceToolbar />),
        noRowsOverlay: () => (
          <CustomNoRowsOverlay
            message={dataGridError ? dataGridError : "No Invoice Lines"}
            onRetry={dataGridError ? () => fetchData() : undefined}
          />
        ),
      }}
    />
  );
};





const Invoicing: React.FunctionComponent = () => {
  let InstanceID: string = getInstanceID();

  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [tabValue, setTabValue] = useState<string>("Invoices");

  //#region Filters/selector things:
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");

  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID("");
    setDataRows([]);
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    setDrillingProgramSelectorID("");
    setDataRows([]);
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
    setDataRows([]);
  };
  //#endregion

  const [statusOptions, setStatusOptions] = useState<IGenericDropDownOption[]>([]);


  const fetchData = async () => {
    setLoading(true);
    setDataRows([]);
    setStatusOptions([]);
    setDataGridError(null);
    try {
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/~GetInvoicesWithStatuses`,
        JSON.stringify({ ID_EDP_DrillingProgram: drillingProgramSelectorID })
      );
      console.log(data);
      validateAndSet(data.Invoices, setDataRows, "Invoices are missing");
      validateAndSet(data.Statuses, setStatusOptions, "Statuses are missing");

    } catch (e) {
      setDataRows([]);
      setStatusOptions([]);
      setDataGridError("Error fetching Invoices.");
    };
    setLoading(false);
  };


  React.useEffect(() => {
    if (miningCompanySelectorID && contractSelectorID && drillingProgramSelectorID) {
      fetchData();
    }
  }, [miningCompanySelectorID, contractSelectorID, drillingProgramSelectorID]);


  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentInvoiceName: string = await dataRows.filter((row) => row.ID === ID)[0].Name;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Invoice "${currentInvoiceName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Invoice "${currentInvoiceName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Invoice "${currentInvoiceName}".`);
      }
    }
  };

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });



  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/~UpdateItem`,
        rawData
      );
      const updatedRow = {
        ...newRow,
        isNew: false,
      };
      console.log(dataRows);
      console.log(updatedRow);
      setDataRows((prev: any[]) =>
        prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
      );
      console.log(dataRows);
      await AlertService.showAlert(`Successfully Updated Invoice "${newRow.Name}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error Updating Invoice "${newRow.Name}".`);
    }
    return newRow;
  };

  function EditToolbar() {
    const handleClick = () => {
      setInvoiceDialogOpen(true);
    };
    return (
      <GridToolbarContainer sx={{ height: "50px" }}>
        <Button style={{ marginLeft: "auto", marginRight: 0 }} color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={!miningCompanySelectorID || !contractSelectorID || !drillingProgramSelectorID}>
          Add Invoice
        </Button>
      </GridToolbarContainer>
    );
  };

  //#region Dialog
  const options: ICustomDialogComponent[] = [

    {
      headerName: 'Invoice Type',
      field: 'Type',
      type: 'singleSelect',
      valueOptions: ['Rosond Standard'],
      required: true,
    },
    {
      headerName: 'Name',
      field: 'Name',
      type: 'string',
      editable: true,
      required: true,
    },
    {
      headerName: 'Document Number',
      field: 'DocumentNumber',
      type: 'string',
      required: true,
    },
    {
      headerName: 'Start Date',
      field: 'StartDate',
      type: 'date',
      required: true,
    },
    {
      headerName: 'End Date',
      field: 'EndDate',
      type: 'date',
      required: true,
    },
    {
      headerName: 'Remarks',
      field: 'Remarks',
      type: 'string',
      editable: true,
      required: false,
    },
  ];


  const [invoiceDialogOpen, setInvoiceDialogOpen] = React.useState(false);

  const [formData, setFormData] = React.useState({});
  const handleInvoiceDialogSubmit = async () => {
    setInvoiceDialogOpen(false);
    console.log('Form Data:', formData);

    try {
      const rawData = JSON.stringify({ ID_EDP_DrillingProgram: drillingProgramSelectorID, ...formData });
      const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/~GenerateInvoice`,
        rawData
      );
      setDataRows((prev: any) => [...prev, { ID: data.ID_IN_Invoice, ...data }]);

      //Need to make it appear in list if it works.
      await AlertService.showAlert(`Successfully Added Invoice.`, "success");
    } catch (e) {
      handleErrorResponse(e, `Error Adding Invoice.`); // need to put the message in here
    };


    setFormData({});
  };

  //#endregion

  const handleDownloadClick = (ID: GridRowId) => async () => {
    try {

      // Make the API request to get the file data as binary (arraybuffer)
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/ID_${ID}/~DownloadInvoice`,
        "",
        {
          responseType: 'blob', // Specify that we're expecting binary data
        }
      );

      // Create a blob object from the response data
      const blob = new Blob([data]);

      // Create a link element
      const downloadLink = document.createElement("a");

      // Set the href attribute of the link to the blob URL
      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      downloadLink.download = `Invoice_${ID}.xlsx`;

      // Append the link to the document body
      document.body.appendChild(downloadLink);

      // Trigger the click event to initiate the download
      downloadLink.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(downloadLink);
      // Optionally, show a success alert or do any other operations
      await AlertService.showAlert('Invoice download started!', 'success');
    } catch (e) {
      // Handle errors (both the API call and file download)
      handleErrorResponse(e, 'Error downloading invoice.');
    }
  };


  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTabValue(newTab);
  };

  return (
    <>
      <div style={{ display: "flex", marginLeft: "20px" }}>
        <MiningCompanySelect
          plodID={InstanceID}
          value={miningCompanySelectorID}
          onChange={handleMiningCompanyChange}
          miningCompanyOptions={miningCompanyOptions}
          setMiningCompanyOptions={setMiningCompanyOptions}
        />

        <ContractSelect
          plodID={InstanceID}
          miningCompanySelectorID={miningCompanySelectorID}
          value={contractSelectorID}
          onChange={handleContractChange}
          contractOptions={contractOptions}
          setContractOptions={setContractOptions}
        />

        <DrillingProgramSelect
          plodID={InstanceID}
          miningCompanySelectorID={miningCompanySelectorID}
          contractSelectorID={contractSelectorID}
          value={drillingProgramSelectorID}
          onChange={handleDrillingProgramChange}
          drillingProgramOptions={drillingProgramOptions}
          setDrillingProgramOptions={setDrillingProgramOptions}
        />
      </div>

      <Tabs
        variant="fullWidth"
        value={tabValue}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Invoice Action select"
        style={{ marginLeft: 20 }}
      >
        <Tab sx={{ maxWidth: 175, height: 60 }} value="Invoices" label="Invoices" />
        {/* <Tab sx={{ maxWidth: 175, height: 60 }} value="EditInvoice" label="Edit Invoice" /> */}
      </Tabs>
      {/* 

      {tabValue == "EditInvoice" && (
        <Box
          sx={{
            height: "60vh",
            margin: "20px",
          }}
        >
          <TreeviewThing
            miningCompanySelectorID={miningCompanySelectorID}
            contractSelectorID={contractSelectorID}
            invoiceDropDownOptions={dataRows}
          />
        </Box>
      )}
      {tabValue === "Invoices" && ( */}
      <Box
        sx={{
          height: "60vh",
          margin: "20px",
        }}
      >
        <DataGridPremium
          columns={[
            {
              field: 'Name',
              headerName: "Name",
              flex: 1,
              editable: true
            },
            {
              field: 'Type',
              headerName: 'Invoice Type',
              flex: 1
            },
            {
              field: 'InvoiceDate',
              headerName: 'Invoice Date',
              flex: 1,
              valueGetter: (value, row) => value && dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : "" // should never not be null, but just being safe
            },
            {
              field: 'ID_WF_Status',
              headerName: "Status",
              flex: 1,
              editable: true,
              type: 'singleSelect',
              valueOptions: statusOptions
            },
            {
              field: 'DocumentNumber',
              headerName: "Document Number",
              flex: 1,
            },
            {
              field: 'Remarks',
              headerName: "Remarks",
              flex: 3,
              editable: true
            },
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id: ID, row }) => {
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

                const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget);
                };

                const handleMenuClose = () => {
                  setAnchorEl(null);
                };

                const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;


                return isInEditMode ? [
                  <React.Fragment>
                    <Tooltip title="Save" key={`save-${ID}`}>
                      <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        className="textPrimary"
                        onClick={handleSaveClick(ID)}
                        color="inherit"
                        key={`save-${ID}`}
                      />
                    </Tooltip>
                  </React.Fragment>,
                  <React.Fragment>
                    <Tooltip title="Cancel" key={`cancel-${ID}`}>
                      <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(ID)}
                        color="inherit"
                        key={`cancel-${ID}`}
                      />
                    </Tooltip>
                  </React.Fragment>
                ] : [
                  <GridActionsCellItem
                    icon={<DownloadIcon />}
                    label="Download"
                    color="inherit"
                    onClick={handleDownloadClick(ID)}
                    disabled={row.ProcessingStatus != "Processed"}
                  />,
                  <IconButton onClick={handleMenuOpen} color="inherit">
                    <MoreVertIcon />
                  </IconButton>,
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    onClick={() => handleMenuClose()}
                  >
                    <GridActionsCellItem
                      icon={<EditIcon />}
                      label="Archive"
                      className="textPrimary"
                      // onClick={handleEditClick(ID)}
                      color="inherit"
                      key={`edit-${ID}`}
                      showInMenu={true}
                    />
                    <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label="Delete"
                      className="textPrimary"
                      onClick={handleDeleteClick(ID)}
                      color="inherit"
                      key={`delete-${ID}`}
                      showInMenu={true}
                    />
                  </Menu>,
                ];
              },
            }
          ]}
          rows={dataRows}
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          editMode="row"
          disableRowSelectionOnClick
          loading={loading}
          getRowId={(row) => row.ID} // Gets the id from database, not the local style one
          // sx={{ minHeight: 300 }}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                message={dataGridError ? dataGridError : "No Invoices"}
                onRetry={dataGridError ? () => fetchData() : undefined}
              />
            ),
          }}
        />
      </Box>
      {/* )} */}


      <CustomDialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        options={options}
        data={formData}
        onFieldChange={(field, value) =>
          setFormData((prev) => ({ ...prev, [field]: value }))
        }
        handleSubmit={handleInvoiceDialogSubmit}
      />;

    </>
  );
};

export default Invoicing;
