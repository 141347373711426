
import React, { useEffect } from "react";
import { Api } from "../../api";
import getInstanceID from "../../functions/getInstanceID";
import { Box } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { IRig } from "../../Interfaces/Rig.interface";
import CustomCardWrapper from "../../components/widgets/CustomCardWrapper";

const RigLocationWidget: React.FC = () => {
  let instanceID = getInstanceID();
  const [loading, setLoading] = React.useState(false);
  const [rigs, setRigs] = React.useState<IRig[]>([]);
  const fetchData = async () => {
    setRigs([]);
    setLoading(true);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${instanceID}/Rigs`);
      setRigs(data);
    } catch (e) {
      setRigs([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let center = {
    lat: -26.03573581615954, // Default center (you can adjust this as needed) (Might set in a config thing)
    lng: 28.157734327218307,  // Rosonds HQ atm
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  return (
    <>

      <CustomCardWrapper title={"Rig Locations"} sx={{ height: 600 }} loading={loading}>
        <Box sx={{ height: 525 }} >


          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            mapTypeId="satellite" // Set the map to show it as satellite view :)
          >
            {rigs.map((item: IRig) => {
              // Ensure the item has both latitude and longitude before rendering the marker
              if (item.Latitude != null && item.Longitude != null) {
                return (
                  <MarkerF
                    key={item.ID_EA_Rig}
                    position={{ lat: item.Latitude!, lng: item.Longitude! }}
                    label={item.RigName}
                  />
                );
              }
              // Return null if the item does not have valid latitude and longitude
              return null;
            })}

          </GoogleMap>
        </Box>
      </CustomCardWrapper>
    </>
  );
};
export default RigLocationWidget;