import React from "react";
import * as FaIcons from "react-icons/fa";

export const SidebarData = [
  {
    title: "Rigs",
    path: "/rigs",
    icon: <FaIcons.FaList />,
  },
  {
    title: "Bits",
    path: "/bits",
    icon: <FaIcons.FaList />,
  },
  {
    title: "Lists",
    path: "/lists",
    icon: <FaIcons.FaList />,
  },
  {
    title: "Spacer",
    path: "",
    icon: <FaIcons.FaSpaceShuttle />,
  },
  {
    title: "Clients",
    path: "/clients",
    icon: <FaIcons.FaAddressBook />,
  },
  {
    title: "Contracts",
    path: "/contracts",
    icon: <FaIcons.FaFileAlt />,
  },
  {
    title: "Contract Setup",
    path: "/contract-setup",
    icon: <FaIcons.FaDollarSign />,
  },
  {
    title: "Spacer",
    path: "",
    icon: <FaIcons.FaSpaceShuttle />,
  },
  {
    title: "New PLOD",
    path: "/new-plod",
    icon: <FaIcons.FaPlusCircle />,
  },
  {
    title: "Review & Signoff",
    path: "/review-and-signoff",
    icon: <FaIcons.FaPenSquare />,
  },
  {
    title: "Spacer",
    path: "",
    icon: <FaIcons.FaSpaceShuttle />,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: <FaIcons.FaRegChartBar />,
  },
  {
    title: "Invoicing",
    path: "/invoicing",
    icon: <FaIcons.FaRegChartBar />,
  },
  {
    title: "Spacer",
    path: "",
    icon: <FaIcons.FaSpaceShuttle />,
  },
  {
    title: "Teams",
    path: "/Teams",
    icon: <FaIcons.FaUsers />,
  },
  {
    title: "Spacer",
    path: "",
    icon: <FaIcons.FaSpaceShuttle />,
  },
  {
    title: "Configuration",
    path: "/configuration",
    icon: <FaIcons.FaCog />,
  },
];
