import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import styled from "styled-components";

interface ICustomCardWrapperProps {
  title: string;
  children: React.ReactNode;
  loading?: boolean;
  sx?: object;
  errorMessage?: string | null;
}
const LoadingSpinner = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100%;
`;

const CustomCardWrapper: React.FC<ICustomCardWrapperProps> = ({ title, loading, errorMessage, children, sx }) => (
  <Card variant="outlined" sx={{ height: '100%', flexGrow: 1, position: 'relative', ...sx }}>

    <Typography
      component="h2"
      variant="subtitle2"
      gutterBottom
      sx={{
        position: 'absolute',
        top: 16, // Adjust as needed
        left: 16, // Adjust as needed
        right: 16, // Optionally, adjust to make it align with card borders
        zIndex: 1, // Ensure it's above other content
        mb: 2
      }}
    >
      {title}
    </Typography>

    <CardContent sx={{ paddingTop: '48px' }}>
      {loading ? (
        <LoadingSpinner>
          <CircularProgress />
        </LoadingSpinner>
      ) : errorMessage ? (
        <LoadingSpinner>
          <Typography variant="body1" color={"error"}>
            {errorMessage}
          </Typography>
        </LoadingSpinner>
      ) : (
        <>{children}</>
      )}
    </CardContent>
  </Card>
);

export default CustomCardWrapper;