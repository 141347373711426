import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormHelperText, Stack, SelectChangeEvent } from "@mui/material";
import { Api, handleErrorResponse } from "../api";
import { IContract } from "../Interfaces/Contract.interface";

interface ContractSelectProps {
  plodID: string;
  miningCompanySelectorID: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string | null;
  disabled?: boolean;
  width?: string;
  showAllOption?: boolean;
  contractOptions: IContract[];
  setContractOptions: React.Dispatch<React.SetStateAction<IContract[]>>;
}

const ContractSelect: React.FC<ContractSelectProps> = ({
  plodID,
  miningCompanySelectorID,
  value,
  onChange,
  errorMessage,
  disabled,
  width = "250px",
  showAllOption = false,
  contractOptions,
  setContractOptions,
}) => {

  const fetchContractData = async () => {
    if (miningCompanySelectorID === "-1") {
      setContractOptions([]);
      return;
    }
    Api.post(
      `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts`
    )
      .then(({ data }) => {
        setContractOptions(data);
      })
      .catch((e) => {
        console.error("Error fetching Contracts", e);
      });
  };

  useEffect(() => {
    if (miningCompanySelectorID) {
      fetchContractData();
    }
  }, [plodID, miningCompanySelectorID]);

  return (
    <Stack spacing={0} padding="5px" paddingRight='10px'>
      <FormHelperText>Contract</FormHelperText>
      <Select
        value={value}
        onChange={(event: SelectChangeEvent<string>) => onChange(event.target.value)}
        disabled={disabled || (contractOptions.length === 0 && !miningCompanySelectorID) || errorMessage != null}
        style={{ width }}
        displayEmpty
      >
        <MenuItem value="" key="Unselected" disabled>
          {contractOptions.length === 0 && !miningCompanySelectorID ? "No Contracts" : "Select a Contract"}
        </MenuItem>
        {showAllOption && miningCompanySelectorID && (
          <MenuItem key="All" value="-1">
            All
          </MenuItem>
        )}
        {contractOptions.map((data: any) => (
          <MenuItem key={data["ID_EC_Contract"]} value={data["ID_EC_Contract"]}>
            {data["ContractName"]}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default ContractSelect;
