import React from 'react';
import { Select, MenuItem, FormHelperText, Stack, Box, SelectChangeEvent } from '@mui/material';




interface GenericSelectProps<T> {
  label: string;
  value: T[keyof T];  // The value should match the value type from the options array.
  // onChange: (event: SelectChangeEvent<T[keyof T]>) => void;  // Ensure event type is correctly inferred 
  onChange: (event: SelectChangeEvent) => void;
  options: T[];  // Options is an array of the generic type T
  disabled: boolean;
  valueField: keyof T;  // The field from T that should be used as the value of the select options
  labelField: keyof T;  // The field from T that should be used as the label of the select options
  plurificationText?: string;
  showAllOption?: boolean;
}

function GenericSelect<T>({
  label,
  plurificationText = "s",
  value,
  onChange,
  options,
  disabled,
  valueField,
  labelField,
  showAllOption = false,
}: GenericSelectProps<T>) {
  return (
    <Stack spacing={0} padding="5px" paddingRight='10px'>
      <FormHelperText>{label}</FormHelperText>
      <Select
        id={label + "Selector"}
        value={value as string | null ?? ''}
        onChange={onChange}
        disabled={disabled || options.length === 0}
        style={{ width: 250 }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          {options.length === 0 ? `No ${label}${plurificationText}` : `Select a ${label}`}
        </MenuItem>
        {showAllOption && (
          <MenuItem key="All" value="-1">
            All
          </MenuItem>
        )}
        {options.map((data, index) => (
          <MenuItem key={index} value={data[valueField] as string}> {/* Casting value here */}
            {data[labelField] as string} {/* Casting label here */}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
export default GenericSelect;
