import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Slider, Paper, IconButton, styled, Box, CircularProgress, TableFooter } from '@mui/material';
import { IActivityOverview } from '../Interfaces/IActivityOverview.interface';

// Utility function to convert minutes into hour:minute format
const formatTime = (minutes: number) => `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`;

const CustomSlider = styled(Slider)({
  color: '#ffffff',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    color: '#4ffffff'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    opacity: 6,
    height: 3,
  },
});

// Custom Time Vision Item Component with Slider
function CustomTimeVisionItem({ row, handleSliderChange, handleSliderCommit }: any) {
  return (
    <CustomSlider
      value={[row.FromTime, row.ToTime]}
      min={0}
      max={1440}
      step={5}
      valueLabelDisplay="auto"
      onChangeCommitted={(event: any, newValue: any) => handleSliderCommit(newValue as number[], row)}
      onChange={(event: any, newValue: any) => handleSliderChange(newValue as number[], row.ID)}
      valueLabelFormat={formatTime}
      sx={{ width: "90%", height: "3px" }}
      disableSwap
      color={"info"}
    />
  );
}

// TimeVisionTable Component that accepts props for rows and change handler
interface ActivityOverviewTableProps {
  activityOverviewRows: any[];
  handleSliderChange: (newRange: number[], ID: string | number) => void;
  handleSliderCommit: (newRange: number[], row: IActivityOverview) => void;
  loading: boolean;
}


export default function ActivityOverviewTable({ activityOverviewRows, handleSliderChange, handleSliderCommit, loading }: ActivityOverviewTableProps) {
  return (
    <TableContainer component={Paper} sx={{ height: 400, overflow: 'auto' }}> {/* Scrollable Table */}
      <Table aria-label="time vision table" >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Time Range</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={100} sx={{ textAlign: 'center', height: '343px' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : activityOverviewRows.length > 0 ? (
            activityOverviewRows.map((row) => (
              <TableRow key={row.ID} sx={{ backgroundColor: (row.Type == "Task" ? "rgb(60, 124, 59)" : "rgb(242, 107, 49)") }}>
                <TableCell style={{
                  minWidth: "100px",
                  maxWidth: "100px",
                  color: "rgb(255, 255, 255)",
                  fontSize: '1rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                  {row.Name}
                </TableCell>
                <TableCell style={{
                  minWidth: "100px",
                  maxWidth: "100px",
                  color: "rgb(255, 255, 255)",
                  fontSize: '1rem',
                }}>
                  {formatTime(row.FromTime) + " - " + formatTime(row.ToTime)}
                </TableCell>
                <TableCell style={{ minWidth: "500px" }} component="th" colSpan={2}>
                  <CustomTimeVisionItem row={row} handleSliderChange={handleSliderChange} handleSliderCommit={handleSliderCommit} />
                </TableCell>
              </TableRow>
            ))) : (
            <TableRow >
              <TableCell colSpan={100} sx={{ textAlign: 'center', height: '343px', }}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}