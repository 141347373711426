import secureLocalStorage from "react-secure-storage";
import { IInstanceData } from "../Interfaces/InstanceData.interface";
function getInstanceData(): IInstanceData {
    let InstanceInfo: IInstanceData | null = secureLocalStorage.getItem("InstanceData") as IInstanceData | null;
    if (InstanceInfo == null) {
        window.location.replace(
            //?Should this have some functionality on the server ?? 
            "https://login.redochre.cloud/?reason=failedtogetinstanceinfo" // TODO: Look more into this 
        );
        throw new Error("Redirecting to login due to missing instance data.");
    }
    return InstanceInfo;
}

export default getInstanceData;

