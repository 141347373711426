

import React, { useEffect } from "react";
import { Api } from "../../api";
import getInstanceID from "../../functions/getInstanceID";
import CustomBarChart from "./CustomBarChart";

const DrillingDepthAndHoursWidget: React.FC = () => {
  let instanceID = getInstanceID();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [data, setData] = React.useState<any[]>([]);
  const [labels, setLabels] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState<string>('0');
  const fetchData = async () => {
    setData([]);
    setLabels([]);
    setTotal("0");
    setLoading(true);
    setErrorMessage(null);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${instanceID}/~GetAllDrillingTaskHoursPastThirtyDays`);
      const allLabels: string[] = data.DrillingTaskHours.map((x: { Label: string; }) => x.Label);
      let depthData: number[] = data.DrillingTaskHours.map((x: { DrillingDepth: number; }) => x.DrillingDepth);;
      let hoursData: number[] = data.DrillingTaskHours.map((x: { DrillingHours: number; }) => x.DrillingHours / 60);;
      setLabels(allLabels);
      setData([{
        id: "Depth",
        label: "Depth",
        data: depthData,
        stack: "A",
        color: 'red',
        yAxisId: 'depthAxis',
      }, {
        id: "Hours",
        label: "Hours",
        data: hoursData,
        stack: "B",
        color: 'green',
        yAxisId: 'hourAxis',
      }]);
      setTotal(data.TotalShifts.toString());
    } catch (e) {
      setErrorMessage("Failed to fetch data");
      setTotal("0");
      setData([]);
      setLabels([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CustomBarChart
      loading={loading}
      title="Drilling Hours And Depths"
      description="Drilling Hours and Depths for the past 30 days."
      totalField={total + " Shifts"} // total shifts to go here.
      data={data}
      yAxis={
        [
          {
            id: 'depthAxis',
            scaleType: 'linear',
            valueFormatter(value, context) {
              return value + 'm';
            },
          },
          {
            id: 'hourAxis',
            scaleType: 'linear',
            valueFormatter(value, context) {
              return value + 'h';
            },
          },
        ]
      }
      xAxis={[
        {
          scaleType: 'band',
          id: 'labels',
          data: labels,
        },
      ]}
      rightAxis='hourAxis'
      errorMessage={errorMessage}
    />
  );
};
export default DrillingDepthAndHoursWidget;