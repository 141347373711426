import React from 'react';
import { Typography, Stack, Chip } from '@mui/material';
import { IChipLabel } from './ChipLabel.interface';
interface ICustomCardHeaderProps extends IChipLabel {
  description: string;
  totalField?: string | number;
}

const CustomCardHeader: React.FC<ICustomCardHeaderProps> = ({ description, totalField, chip }) => (
  <>
    <Stack sx={{ justifyContent: 'space-between' }}>
      <Stack
        direction="row"
        sx={{
          justifyContent: chip?.chipAlignment === 'right' ? 'space-between' : 'flex-start', // Adjust alignment
          alignItems: 'center',
          gap: chip?.chipAlignment === 'inline' ? 1 : 0,
        }}
      >
        {totalField && (
          <Typography variant="h4" component="p">
            {totalField}
          </Typography>
        )}
        {chip && (
          <Chip
            size="small"
            color={chip.colour}
            label={chip.label}
          />
        )}
      </Stack>
      <Typography
        variant="caption"
        sx={{
          lineHeight: '1.5em', // Line height for spacing
          minHeight: '3em',    // Ensures 2 lines of height (2 x 1.5em)
          color: 'text.secondary'
        }}
      >
        {description}
      </Typography>
    </Stack>
  </>
);

export default CustomCardHeader;
