import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import React from 'react';

function CustomTimePickerItem(props: GridRenderEditCellParams) {
  const { id, value, field, api, row } = props;

  // Ensure value is always Dayjs or null
  const convertedValue: Dayjs | null =
    value && dayjs(value).isValid() ? dayjs(value) : null;

  return (
    <div style={{ width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={convertedValue} // Ensure it's Dayjs or null
          onChange={(newTime) => {
            if (newTime) {
              api.setEditCellValue({ id, field, value: newTime.toISOString() });
            }
          }}
          minTime={
            field === 'ToTime' && row.FromTime
              ? dayjs(row.FromTime) // Convert FromTime to Dayjs
              : undefined
          }
          maxTime={
            field === 'FromTime' && row.ToTime
              ? dayjs(row.ToTime) // Convert ToTime to Dayjs
              : undefined
          }
          ampm={false}
          slotProps={{
            popper: {
              sx: {
                '& .MuiList-root': {
                  scrollbarWidth: 'none',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

export default CustomTimePickerItem;
