// App.tsx
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AlertProvider } from "./context/AlertContext";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import AppLayout from "./layout/AppLayout";
import Home from "./pages/Home";
import Lists from "./pages/Lists";
import Rigs from "./pages/Rigs";
import Bits from "./pages/Bits";
import Clients from "./pages/Clients";
import Contracts from "./pages/Contracts";
import ContractsSetup from "./pages/Contracts-Setup";
import NewPlod from "./pages/New-Plod";
import ReviewAndSignoff from "./pages/Review-And-Signoff";
import Reports from "./pages/Reports";
import Invoicing from "./pages/Invoicing";
import Teams from "./pages/Teams";
import Configuration from "./pages/Configuration";
import { createTheme, CssBaseline, ThemeProvider, Box, Paper, MenuItem, IconButton } from '@mui/material';
const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState((localStorage.getItem("DarkMode") ?? 'false') === 'true');

  const appTheme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#1565c0", // Blue for primary elements
      },
      // background: {
      //   paper: darkMode ? "#111518" : "#ffffff",
      // },

      secondary: {
        main: "#ff4081", // Pink accent color
      },
      text: {
        primary: darkMode ? "#ffffff" : "#000000",
      },
    },
    components: {

      // MuiAppBar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: darkMode ? "#003788" : "#a6cde7",
      //       boxShadow: "none", // Optional: Remove shadow for cleaner look
      //     },
      //   },
      // },

      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: darkMode ? "#003788" : "#003788",
            color: darkMode ? "#ffffff" : "#ffffff",
          },

        },
      },


    },
    // mixins: {

    //   MuiDataGrid: {
    //     // Pinned columns sections
    //     pinnedBackground: '#340606',
    //     // Headers, and top & bottom fixed rows
    //     containerBackground: '#343434',
    //   },

    // }
  });

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <AlertProvider> {/* Single provider for all alerts */}
        <Router>
          <Routes>
            {/* Define top-level NotFound route */}
            <Route path="*" element={<NotFound />} />
            {/* Main App Layout with authentication */}
            <Route
              path="/"
              element={
                <AppLayout darkMode={darkMode} setDarkMode={setDarkMode} />
              }
            >
              {/* Nested routes */}
              <Route index element={<Home />} />
              <Route path="lists" element={<Lists />} />
              <Route path="rigs" element={<Rigs />} />
              <Route path="bits" element={<Bits />} />
              <Route path="clients" element={<Clients />} />
              <Route path="contracts" element={<Contracts />} />
              <Route path="contract-setup" element={<ContractsSetup />} />
              <Route path="new-plod" element={<NewPlod />} />
              <Route path="review-and-signoff" element={<ReviewAndSignoff />} />
              <Route path="reports" element={<Reports />} />
              <Route path="invoicing" element={<Invoicing />} />
              <Route path="teams" element={<Teams />} />
              <Route path="configuration" element={<Configuration />} />
            </Route>
          </Routes>
        </Router>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default App;
