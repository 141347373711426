// AlertDialog.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Box, DialogContent, DialogContentText } from '@mui/material';

interface AlertDialogProps {
  title: string;
  message?: string | null;
  onClose: () => void;
  onConfirm?: () => void;
  isConfirmation?: boolean;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ title: title, message, onClose, onConfirm, isConfirmation }) => (
  <Dialog
    open={true}
    onClose={onClose}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1);', // Adjust alpha value to make shading lighter
      zIndex: 1300,
    }}
  >
    <Box
      sx={{
        backgroundColor: 'white',
        padding: 2,
        borderRadius: 1,
        textAlign: 'center',
        maxWidth: '100%',
        minHeight: "20%"
      }}
    >
      <DialogTitle sx={{
        justifyContent: 'center', color: isConfirmation ? "black" : "red"
      }}>{title}</DialogTitle>
      <DialogContentText sx={{
        justifyContent: 'center',
        whiteSpace: "pre-line", // Allows line breaks on '\n'
        overflowWrap: "break-word", // Wraps long words if they overflow
        textAlign: "center", // Center-aligns the text
      }}>
        {message}
      </DialogContentText>
      < DialogActions sx={{ justifyContent: 'center' }}>
        {isConfirmation ? (
          <>
            <Button onClick={onConfirm} color="primary" variant="outlined">
              Confirm
            </Button>
            <Button onClick={onClose} color="primary" variant="outlined">
              Cancel
            </Button>
          </>
        ) : (
          <Button onClick={onClose} color="primary" variant="outlined">
            Close
          </Button>
        )}
      </DialogActions>
    </Box>
  </Dialog>
);

export default AlertDialog;
